import React, { Fragment, useEffect, useState } from 'react';
import { Box, Slide, Dialog, DialogContent, DialogContentText, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { getUserDetails, getSelectedTeamDetails } from '../../config/utils';
import { useSnackbar } from 'notistack';
// import _ from 'lodash';
import { CancelIcon } from '../../config/svg/Action';
import { OKRButton } from '../Common/OKRButton';
import GuidedTour from '../Common/GuidedTour';
import { PeopleHeader } from '../PageHeader/PeopleHeader';
import { Enums } from '../../config/enums';
import { OkrListing } from '../Goals/OkrListing';
import '../Goals/style.scss';
import clone from 'clone';
import { HOME } from '../../config/app-url';
import Header from '../../containers/Header';
import { currentDashboardTabForSO } from '../../action/common';
import { useDispatch } from 'react-redux';

export const TeamView: React.FC<any> = (props) => {
	const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [listTeamResult, setListTeamResult] = useState<any>({});

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const year = getLocalStorageItem('currentYear') || null;
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const selectedYear = getLocalStorageItem('year') || year;
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const userDetail: any = getUserDetails();
	const currentOrgData: any = getSelectedTeamDetails();
	const [myGoalList, setMyGoalList] = useState<any>([]);
	const [teamDetails, setTeamDetails] = useState<any>({});
	const [listLoading, setListLoading] = useState<boolean>(true);
	useEffect(() => {
		removeLocalStorageItem('currentUser');
		props.getOkrStatusList(`organisationId=${userDetail?.organisationId}`);
		if (currentOrgData) {
			getTeamView();
			setTeamDetails({
				backGroundColorCode: currentOrgData.backGroundColorCode,
				colorCode: currentOrgData.colorcode,
				fullName: currentOrgData.teamName,
				firstName: currentOrgData.teamName,
				imagePath: currentOrgData.logoImagePath,
			});
		} else {
			props.history.push(HOME);
			props.updateRoute(HOME);
		}
	}, [props.location]);

	const handleDialogClose = () => {
		setOpenDialog(false);
	};
	const getTeamView = async () => {
		props.dispatchLoader(true);
		setListLoading(true);
		let response = await props.getMyTeamGoalDetails(
			'teamId=' + currentOrgData.teamId + '&cycle=' + cycleId + '&year=' + selectedYear
		);

		if (response.data.status === Enums.STATUS_SUCCESS) {
			props.dispatchLoader(false);
			const allListData =
				(response.data.entity.myGoalOkrResponses && clone(response.data.entity.myGoalOkrResponses)) || [];
			setMyGoalList(allListData);
			setListTeamResult({
				...response.data.entity,
				avgScore: response.data.entity.teamAvgScore,
				notStarted: response.data.entity.notStarted,
				atRisk: response.data.entity.atRisk,
				lagging: response.data.entity.lagging,
				onTrack: response.data.entity.onTrack,
				designation: t('leaderLabelText') + ' - ' + response.data.entity.leaderName,
				department: response.data.entity.teamEmployeeCount + ' ' + t('membersLabel'),
				isScoreLocked: true,
			});
			setListLoading(false);
		} else {
			props.dispatchLoader(false);
			enqueueSnackbar(response?.data?.messageList.message, {
				variant: 'error',
				autoHideDuration: 5000,
			});
			setListLoading(false);
		}
	};
	const scrollTeamHeader = (e: any) => {
		const peopleElement = document.getElementById('people-info-header');
		if (window.pageYOffset >= 88) {
			peopleElement?.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			peopleElement?.classList.remove('sticky-header');
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', scrollTeamHeader);
		return () => {
			window.removeEventListener('scroll', scrollTeamHeader);
		};
	});

	useEffect(() => {
		dispatch(currentDashboardTabForSO(false));
		props.currentDashboardTab(0);
		if (!Boolean(props?.okrMasterData)) {
			props.getOkrMasterData();
		}
	}, []);

	useEffect(() => {
		window.addEventListener('scroll', scrollPeopleHeader);
		return () => {
			window.removeEventListener('scroll', scrollPeopleHeader);
		};
	});
	const scrollPeopleHeader = (e: any) => {
		const peopleElement = document.getElementById('people-header');

		if (window.pageYOffset >= 88) {
			peopleElement?.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			peopleElement?.classList.remove('sticky-header');
		}
	};

	return (
		<Fragment>
			{currentOrgData && (
				<Dialog
					className='search-dialog'
					open={openDialog}
					onClose={handleDialogClose}
					aria-labelledby='responsive-dialog-title'
					TransitionComponent={Slide}
					transitionDuration={{ enter: 500, exit: 800 }}
				>
					<DialogContent>
						<Typography variant='h4'>
							{currentOrgData
								? t('watchingObjective', { name: `${currentOrgData.firstName} ${currentOrgData.lastName}` })
								: ''}
						</Typography>
						<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleDialogClose} />
					</DialogContent>
				</Dialog>
			)}

			{!listLoading && (
				<Fragment>
					{teamDetails && (
						<>
							<Box className='page-subheader' id='people-header'>
								<Box className='page-subheader-inner'>
									<Box className='page-subheader-left'>
										<Box className='page-subheader-title'>
											<Typography variant='h2'>{t('alignLabel')}</Typography>
										</Box>
									</Box>
									<Box className='page-subheader-right'>
										<Header {...props} fromTab={true} />
									</Box>
								</Box>
							</Box>
							<PeopleHeader {...props} isTeamHeader={true} currentUser={teamDetails} listOkrResult={listTeamResult} />
						</>
					)}
				</Fragment>
			)}

			{!listLoading && (
				<Box className={`wrapper-container`} id='wrap-container'>
					{
						<Box className='my-goals-area' id='myObjective'>
							<OkrListing
								{...props}
								showAddGoal={false}
								currentCycleId={currentCycleId}
								fetchOkr={getTeamView}
								t={t}
								goalDetail={{}}
								provided={{}}
								snapshot={{}}
								isLocked={true}
								getItemStyle={() => {}}
								//currentUser={currentUser}
								showEditLoader={{ status: '', id: 0 }}
								listOkrResult={listTeamResult}
								setMyGoalList={setMyGoalList}
								listOkrSuccess={'success'}
								myGoalList={myGoalList}
								showArchiveList={false}
								readOnlyView={true}
								isFutureCycle={false}
								isTeamView={true}
								currentOrgData={currentOrgData}
							/>
						</Box>
					}
				</Box>
			)}

			{isGuidedTourShow === 'true' && <GuidedTour {...props} />}
		</Fragment>
	);
};
