import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Typography,
	FormControl,
	InputLabel,
	Avatar,
	TextField,
	Tabs,
	Tab,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GlobalSearch from '../../Common/GlobalSearch';
// import { getLocalStorageItem } from '../../../services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchAPIWithTeam, searchEmployee } from '../../../action/search';
import { getUserById, updatePage } from '../../../action/common';
import {
	generateDefaultTimeOptions,
	generateTimeOptions,
	getUserName,
	urlPatternValidation,
	calculateDateDifference,
	addTimeInMinutes,
	calculateTimeDifferenceInMinutes,
} from '../../../config/utils';
import { CalendarIcon, DocumentIcon } from '../../../config/svg/CommonSvgIcon';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { roleRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';
import { ErrorIcon, ReportingToIcon, TitleIcon } from '../../../config/svg/formElementIcons';
import { RequestFeedbackAgain } from '../../../config/svg/PersonalFeedbackSvg';
import { MultiSelect } from '../../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { AddLinkIcon, DesignationIcon } from '../../../config/svg/CommonSvg';
import { NatureMeetingOptions } from '../../../config/constant';
import { getSearchForFeedbackOneToOne } from '../../../action/personalFeedback';
import { getFeedbackOneToOneSetting } from '../../../action/adminPerformanceDevelopment';
import { Enums } from '../../../config/enums';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { Autocomplete } from '@material-ui/lab';

export const RequestOneOnOneForm = (props: any) => {
	const {
		formData,
		setFormData,
		setIsFormEdited,
		isEdit,
		editableDetails,
		oneToOneTitleError,
		oneToOneUserError,
		isDirect,
		directEmpDetails,
		setOneToOneTitleError,
		setOneToOneUserError,
		staticTitleText,
		userDetail,
		oneToOneMeetingLinkError,
		setOneToOneMeetingLinkError,
		oneToOneSettings,
		oneToOneAgendaError,
		setOneToOneAgendaError,
		oneToOneMeetingDateError,
		setOneToOneMeetingDateError,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [natureOfMeetingSelected, setNatureOfMeetingSelected] = useState<any>([{ ...NatureMeetingOptions[0] }]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});
	const minDateValue =
		isEdit && editableDetails
			? new Date(editableDetails.meetingStartDate) > new Date()
				? new Date()
				: new Date(editableDetails.meetingStartDate)
			: new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 12);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);

	const timeOptions = generateTimeOptions();
	const defaultTimeOptions = generateDefaultTimeOptions();

	const [meetingStartDateRange, setMeetingStartDateRange] = useState<any>(
		isEdit && editableDetails ? editableDetails?.meetingStartDate : formData?.meetingStartDate || new Date()
	);
	const [meetingEndDateRange, setMeetingEndDateRange] = useState<any>(
		isEdit && editableDetails ? editableDetails?.meetingEndDate : formData?.meetingEndDate || new Date()
	);
	const [meetingStartTime, setMeetingStartTime] = useState<any>(
		isEdit && editableDetails ? editableDetails?.meetingStartTime : formData?.meetingStartTime || defaultTimeOptions[0]
	);
	const [meetingEndTime, setMeetingEndTime] = useState<any>(
		isEdit && editableDetails ? editableDetails?.meetingEndTime : formData?.meetingEndTime || defaultTimeOptions[1]
	);
	const [dateDifference, setDateDifference] = useState<any>();
	const [startEndHourDifference, setStartEndHourDifference] = useState<any>('00:30');

	useEffect(() => {
		calculateDateAndTimeDifference(meetingStartDateRange, meetingStartTime, meetingEndDateRange, meetingEndTime);
		if (!oneToOneSettings) {
			dispatch(getFeedbackOneToOneSetting());
		}
	}, []);

	useEffect(() => {
		if (isEdit && formData && formData.natureOfMeeting !== null && formData.natureOfMeeting !== undefined) {
			setNatureOfMeetingSelected(NatureMeetingOptions.filter((item: any) => item.id === formData.natureOfMeeting));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);
	// const currentUser = getLocalStorageItem('currentUser') ? JSON.parse(getLocalStorageItem('currentUser') || '') : null;
	// console.log('Detail===>', formData, isEdit, editableDetails);

	useEffect(() => {
		if (isEdit && editableDetails) {
			setFormData({
				requestId: editableDetails?.oneToOneDetailId || 0,
				oneToOneTitle: editableDetails?.title || '',
				onetoOneRemark: editableDetails?.oneToOneRemark || null,
				meetingLink: editableDetails?.meetingLink || '',
				natureOfMeeting: editableDetails.natureOfMeeting,
				meetingStartDate: editableDetails.meetingStartDate || new Date(),
				meetingEndDate: editableDetails.meetingEndDate || new Date(),
				meetingStartTime: editableDetails.meetingStartTime || defaultTimeOptions[0],
				meetingEndTime: editableDetails.meetingEndTime || defaultTimeOptions[1],
				// selectedUser: [editableDetails],
				// requestedTo: editableDetails?.employeeId || 0,
			});
		} else if (isDirect && directEmpDetails) {
			setFormData({
				requestId: 0,
				oneToOneTitle: staticTitleText,
				onetoOneRemark: null,
				selectedUser: [directEmpDetails],
				requestedTo: directEmpDetails?.employeeId || 0,
				natureOfMeeting: directEmpDetails?.natureOfMeeting,
				meetingLink: directEmpDetails?.meetingLink || '',
				meetingStartDate: directEmpDetails.meetingStartDate || new Date(),
				meetingEndDate: directEmpDetails.meetingEndDate || new Date(),
				meetingStartTime: directEmpDetails.meetingStartTime || defaultTimeOptions[0],
				meetingEndTime: directEmpDetails.meetingEndTime || defaultTimeOptions[1],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEdit, editableDetails, isDirect, directEmpDetails]);

	const calculateDateAndTimeDifference = async (startDate: any, startTime: any, endDate: any, endTime: any) => {
		let difference = calculateDateDifference(
			new Date(startDate || meetingStartDateRange),
			startTime || meetingStartTime,
			new Date(endDate || meetingEndDateRange),
			endTime || meetingEndTime
		);
		setDateDifference(difference);
	};

	const handleStartTimeChange = async (value: any) => {
		let newMeetingEndTime = value;
		if (value) {
			newMeetingEndTime = addTimeInMinutes(value, startEndHourDifference);
			setOneToOneMeetingDateError({
				error: '',
				helperText: '',
				type: '',
			});
			calculateDateAndTimeDifference(meetingStartDateRange, value, meetingEndDateRange, newMeetingEndTime);
		}
		setMeetingStartTime(value);
		setMeetingEndTime(newMeetingEndTime);
		setFormData({ ...formData, meetingStartTime: value, meetingEndTime: newMeetingEndTime });
	};

	const handleEndTimeChange = async (value: any) => {
		setMeetingEndTime(value);
		setUserFieldData('meetingEndTime', value);

		var difference = calculateTimeDifferenceInMinutes(meetingStartTime, value);
		setStartEndHourDifference(difference);

		if (value) {
			setOneToOneMeetingDateError({
				error: '',
				helperText: '',
				type: '',
			});
			calculateDateAndTimeDifference(meetingStartDateRange, meetingStartTime, meetingEndDateRange, value);
		}
	};

	const handleStartDateChange = async (range: any) => {
		if (range) {
			setMeetingStartDateRange(range);
			setUserFieldData('meetingStartDate', range);
			setOneToOneMeetingDateError({
				error: '',
				helperText: '',
				type: '',
			});
			calculateDateAndTimeDifference(range, meetingStartTime, meetingEndDateRange, meetingEndTime);
		}
	};

	const handleEndDateChange = async (range: any) => {
		if (range) {
			setIsFormEdited(true);
			setMeetingEndDateRange(range);
			setUserFieldData('meetingEndDate', range);
			setOneToOneMeetingDateError({
				error: '',
				helperText: '',
				type: '',
			});
			calculateDateAndTimeDifference(meetingStartDateRange, meetingStartTime, range, meetingEndTime);
		}
	};

	const onSelectedUser = async (selected: any) => {
		const isExist =
			formData.selectedUser &&
			formData.selectedUser.find(
				(item: any) => item.employeeId === Boolean(selected) && selected.length && selected[0].employeeId
			);
		if (selected.length > 0 && !isExist) {
			const allUser = [...formData.selectedUser, { ...formData.selectedUser, ...selected[0] }];
			const allUserIds = Boolean(allUser) && allUser.length && allUser[0].employeeId ? allUser[0].employeeId : 0;
			setFormData(() => ({
				...formData,
				selectedUser: allUser,
				requestedTo: allUserIds,
				isError: false,
			}));
			setIsFormEdited(true);
			setOneToOneUserError({
				error: '',
				helperText: '',
				type: '',
			});
		} else {
			setFormData(() => ({
				...formData,
				selectedUser: [],
				raisedForId: 0,
			}));
			// setFormDataUpdated(false);
		}
	};
	const searchCustomEmployee = (searchStr: any, page: any, pageSize: any) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page: any) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr: any, page: any, pageSize: any, type: any) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data: any) => {
		return dispatch(getUserById(data));
	};
	const handleTitleChange = (e: any) => {
		e.preventDefault();
		const { name, value } = e?.target || {};
		setIsFormEdited(true);
		setFormData({ ...formData, [name]: value });
		setOneToOneTitleError({
			error: '',
			helperText: '',
			type: '',
		});
	};
	const handleAgendaChange = (value: any) => {
		if (
			(value && isEdit && value !== editableDetails?.oneToOneRemark) ||
			(value && value !== formData?.onetoOneRemark)
		) {
			setIsFormEdited(true);
		}
		setFormData({ ...formData, onetoOneRemark: value });
	};
	const handleMeetingLinkChange = (event: any) => {
		const { name, value } = event.target;
		const isTrueVal = !value || urlPatternValidation(value);
		setIsFormEdited(true);
		setFormData({ ...formData, [name]: value });
		isTrueVal &&
			setOneToOneMeetingLinkError({
				error: '',
				helperText: '',
				type: '',
			});
	};
	const [select1on1Tab, setSelect1on1Tab] = useState<any>(0);
	const handleTabChange = (event: any, newValue: Number) => {
		setSelect1on1Tab(newValue);
	};
	function a11yProps(index: number) {
		return {
			id: `1-on-1-tab-${index}`,
			'aria-controls': `1-on-1-tabpanel-${index}`,
		};
	}

	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			setOneToOneUserError({
				error: '',
				helperText: '',
				type: '',
			});
			// const resp: any = await dispatch(searchEmployee(value, 1, 6));
			let queryString = `finder=${value}&pageIndex=${1}&pageSize=${10}`;
			const resp: any = await dispatch(getSearchForFeedbackOneToOne(queryString));
			let list = resp.data.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: userData.firstName + ' ' + userData.lastName,
							value: userData.employeeId,
							secondaryText: userData.designation,
						};
					})
				);
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
				setReportingToOptions([]);
			}
		}
	};
	const getUserFieldData = (field: string) => {
		return formData[field];
	};
	const setUserFieldData = (field: string, value: any) => {
		const empId = Boolean(value) && value.length && value[0].employeeId ? value[0].employeeId : 0;
		setFormData({ ...formData, [field]: value, requestedTo: empId || 0 });
		setIsFormEdited(true);
		setOneToOneUserError({
			error: '',
			helperText: '',
			type: '',
		});
	};

	return (
		<>
			<Tabs className='okr-tabs drawer-tabs' value={select1on1Tab} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab label={t('Details')} value={0} {...a11yProps(0)} />
			</Tabs>
			<Box className='drawer-tabs-panel drawer-request-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<Box id='box-oneToOneUser'>
						{!isEdit && !isDirect ? (
							<>
								<Box
									// className={`field-col2 ${
									// 	oneToOneUserError && oneToOneUserError.error ? 'select-error helperText-bottom ' : ''
									// }`}
									className={`drawer-input-field`}
								>
									<Box
										className={`helperText-bottom one-on-one-field ${
											oneToOneUserError && oneToOneUserError?.error ? 'select-error' : ''
										} `}
									>
										<FormLabel>
											<RequestFeedbackAgain /> {t('oneOnOneWith')} <i style={{ color: 'red' }}>*</i>
										</FormLabel>
										<MultiSelect
											key={'selectedUser'}
											id='team-id-dropdown'
											selectedOptions={getUserFieldData('selectedUser') || 0}
											optionsList={reportingToOptions}
											onSelectOption={(value: any) => {
												setUserFieldData('selectedUser', value);
												setReportingToOptions([]);
												setEmptyRecords({ ...emptyRecords, reportingTo: false });
											}}
											isMulti={true}
											customFilter={() => {}}
											placeHolder={'Search'}
											noOptionsMessage={<NoSearchRecord />}
											showNoOptions={emptyRecords.reportingTo === true}
											fetchAsyncData={true}
											isSingleSelection={true}
											performSearch={searchReportingTo}
											closeMenuOnSelect={true}
											labelTemplate={'avatarLabel'}
											selectClassName={'select-search-dropdown select-search-icon'}
											selectClassNamePrefix={'react-select'}
											error={(oneToOneUserError && oneToOneUserError.error) || ''}
											helperText={
												oneToOneUserError?.helperText && (
													<>
														<ErrorIcon />
														{oneToOneUserError?.helperText || ''}
													</>
												)
											}
										/>
									</Box>
								</Box>
							</>
						) : isEdit && editableDetails ? (
							<Box className='drawer-input-field user-info '>
								<Box className='user-img'>
									{Boolean(editableDetails?.imagePath) ? (
										<Avatar
											alt={`${editableDetails?.firstName} ${editableDetails?.lastName}`}
											src={editableDetails?.imagePath}
										/>
									) : (
										<Avatar>{getUserName(editableDetails)}</Avatar>
									)}
								</Box>
								<Box className='user-details'>
									<Typography variant='h4'>
										{editableDetails?.firstName || ''} {editableDetails?.lastName || ''}
									</Typography>
									<Typography variant='subtitle2'>{editableDetails?.emailId || ''}</Typography>
								</Box>
							</Box>
						) : isDirect && directEmpDetails ? (
							<Box className='drawer-input-field user-info '>
								<Box className='user-img'>
									{Boolean(directEmpDetails?.imagePath) ? (
										<Avatar
											alt={`${directEmpDetails?.firstName} ${directEmpDetails?.lastName}`}
											src={directEmpDetails?.imagePath}
										/>
									) : (
										<Avatar>{getUserName(directEmpDetails)}</Avatar>
									)}
								</Box>
								<Box className='user-details'>
									<Typography variant='h4'>
										{directEmpDetails?.firstName || ''} {directEmpDetails?.lastName || ''}
									</Typography>
									<Typography variant='subtitle2'>{directEmpDetails?.emailId || ''}</Typography>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
					<Box className='drawer-blue-bg-full'>
						<Box className='drawer-input-field' id='box-oneToOneTitle'>
							<TextField
								fullWidth
								className='helperText-bottom'
								label={
									<>
										<TitleIcon />
										{t('titleLabel')}
										<i style={{ color: 'red' }}>*</i>
									</>
								}
								placeholder={t('addTitle')}
								value={formData?.oneToOneTitle || ''}
								onChange={handleTitleChange}
								name={`oneToOneTitle`}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('addTitle'), disableUnderline: true }}
								error={oneToOneTitleError?.error || false}
								helperText={
									(oneToOneTitleError?.error && (
										<>
											<ErrorIcon />
											{oneToOneTitleError?.helperText || ''}
										</>
									)) ||
									''
								}
							/>
						</Box>
						<Box
							className={`drawer-input-field meeting-schedule-details ${
								oneToOneMeetingDateError?.error ? 'helperText-bottom' : ''
							}`}
							id='box-oneToOneMeetingDate'
						>
							<FormLabel component='legend'>
								<CalendarIcon />
								{t('dateAndTime')}
								<i style={{ color: 'red' }}>*</i>
							</FormLabel>
							<Box className='meeting-date-and-time '>
								<Box className='meeting-date-from'>
									<ReactDateRangePicker
										type={'ContributorList'}
										name='startDate'
										selectedCycleDetails={{}}
										startDateValue={meetingStartDateRange}
										minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
										maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
										handleDateSelection={(range: any, start: any, end: any) => {
											handleStartDateChange(range);
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={meetingStartDateRange || null}
										setSelectedDateRange={(range: any) => {
											if (range) {
												setMeetingStartDateRange(range);
											}
										}}
										calendarTitle={t('singleDateStartDateTitle')}
										placeholderText={t('selectStartDateLabel')}
										isDatePickerDisable={false}
									/>
									<Autocomplete
										freeSolo
										className='meeting-time-field'
										classes={{ popper: 'time-field-popper' }}
										id='autocomplete-meetingStartTime'
										//disableClearable
										options={timeOptions}
										value={meetingStartTime}
										onChange={(event: any, newValue: any) => {
											handleStartTimeChange(newValue);
										}}
										renderInput={(params: any) => (
											<TextField
												{...params}
												id='txt-meetingStartTime'
												step='60'
												type='Time'
												pattern='[0-9]{2}:[0-9]{2}'
												onChange={(event: any) => {
													handleStartTimeChange(event.target.value);
												}}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)}
									/>
									{/* <Select
										value={meetingStartTime}
										onChange={(event: any) => {
											if (event.target.value) {
												const value = event.target.value;
												setIsFormEdited(true);
												setMeetingStartTime(value);
												setUserFieldData('meetingStartTime', value);
												setOneToOneMeetingDateError({
													error: '',
													helperText: '',
													type: '',
												});
											}
										}}
										className='meeting-time-dropdown'
										id='start-time'
									>
										{timeOptions.map((time) => (
											<MenuItem value={time} id={'view-feedback-link'}>
												{time}
											</MenuItem>
										))}
									</Select> */}
								</Box>
								<Typography> to </Typography>
								<Box className='meeting-date-to'>
									<ReactDateRangePicker
										type={'ContributorList'}
										name='endDate'
										selectedCycleDetails={{}}
										startDateValue={meetingEndDateRange}
										minimumDate={meetingStartDateRange ? new Date(meetingStartDateRange) : new Date()}
										maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
										handleDateSelection={(range: any, start: any, end: any) => {
											handleEndDateChange(range);
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={meetingEndDateRange || null}
										setSelectedDateRange={(range: any) => {
											if (range) {
												setMeetingEndDateRange(range);
											}
										}}
										calendarTitle={t('singleDateTitle')}
										placeholderText={t('selectEndDateLabel')}
										isDatePickerDisable={false}
									/>

									<Autocomplete
										freeSolo
										className='meeting-time-field'
										classes={{ popper: 'time-field-popper' }}
										id='autocomplete-meetingEndTime'
										//disableClearable
										options={timeOptions}
										value={meetingEndTime}
										onChange={(event: any, newValue: any) => {
											handleEndTimeChange(newValue);
										}}
										renderInput={(params: any) => (
											<TextField
												{...params}
												id='txt-meetingEndTime'
												step='60'
												type='Time'
												pattern='[0-9]{2}:[0-9]{2}'
												onChange={(event: any) => {
													handleEndTimeChange(event.target.value);
												}}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)}
									/>

									{/* <Select
										value={meetingEndTime}
										onChange={(event: any) => {
											if (event.target.value) {
												const value = event.target.value;
												setIsFormEdited(true);
												setMeetingEndTime(value);
												setUserFieldData('meetingEndTime', value);
												setOneToOneMeetingDateError({
													error: '',
													helperText: '',
													type: '',
												});
											}
										}}
										className='meeting-time-dropdown'
										id='end-time'
									>
										{timeOptions.map((time) => (
											<MenuItem value={time} id={'view-feedback-link'}>
												{time}
											</MenuItem>
										))}
									</Select> */}
								</Box>
								{(oneToOneMeetingDateError?.error && (
									<Typography className='error-field'>
										<ErrorIcon />
										{oneToOneMeetingDateError?.helperText || ''}
									</Typography>
								)) ||
									''}
								<Box className='meeting-duration'>
									<Typography variant='body2'>{dateDifference}</Typography>
								</Box>
							</Box>
						</Box>
						<Box className='drawer-input-field'>
							<FormLabel>{t('natureOfMeeting')}</FormLabel>
							<RadioGroup
								row
								aria-label='cycleDuration'
								name='cycleDuration'
								onChange={(event: any) => {
									const selectedvalue = parseInt(event.target.value);
									let selectedRadio = [...NatureMeetingOptions].filter((item: any) => item?.id === selectedvalue);
									if (selectedRadio.length > 0) {
										setNatureOfMeetingSelected(selectedRadio);
										setFormData({
											...formData,
											natureOfMeeting: selectedRadio[0].id,
										});
										setIsFormEdited(true);
									}
								}}
							>
								{NatureMeetingOptions.length > 0 &&
									NatureMeetingOptions.map((meetingOption: any) => (
										<FormControlLabel
											value={meetingOption?.id}
											control={<Radio />}
											label={meetingOption.label}
											checked={
												natureOfMeetingSelected.length > 0 ? natureOfMeetingSelected[0].id === meetingOption?.id : false
											}
										/>
									))}
							</RadioGroup>
						</Box>
						{natureOfMeetingSelected && natureOfMeetingSelected.length > 0 && natureOfMeetingSelected[0].id === 1 && (
							<Box className='drawer-input-field' id='box-oneToOneMeetingLink'>
								<TextField
									fullWidth
									className='helperText-bottom'
									label={
										<>
											<AddLinkIcon />
											{t('meetingLink')}
										</>
									}
									placeholder={t('addMeetingLink')}
									value={formData?.meetingLink || ''}
									onChange={handleMeetingLinkChange}
									name={'meetingLink'}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t('addMeetingLink'), disableUnderline: true }}
									error={oneToOneMeetingLinkError?.error || false}
									helperText={
										(oneToOneMeetingLinkError?.error && (
											<>
												<ErrorIcon />
												{oneToOneMeetingLinkError?.helperText || ''}
											</>
										)) ||
										''
									}
								/>
							</Box>
						)}
						<Box className='drawer-input-field helperText-bottom' id='box-requestOneOnOneAgenda'>
							<FormLabel>
								<DocumentIcon /> {t('agendaLabel')}
								{oneToOneSettings?.isOneToOneAgendaRequired ? <i style={{ color: 'red' }}>*</i> : ''}
							</FormLabel>
							<CkEditor
								{...props}
								className={'inactive' || ''}
								value={formData?.onetoOneRemark || ''}
								skipBlankCheck={true}
								handleEditorChange={(value: string) => {
									handleAgendaChange(value);
									setOneToOneAgendaError({
										error: '',
										helperText: '',
										type: '',
									});
								}}
								editorId={`requestOneOnOneAgenda`}
								removedPlugins={roleRemovePlugin}
								placeHolderText={t('writeAgendaLabel')}
								showEmoticons={true}
							/>
							{(oneToOneAgendaError?.error && (
								<Typography className='error-field'>
									<ErrorIcon />
									{oneToOneAgendaError?.helperText || ''}
								</Typography>
							)) ||
								''}
						</Box>
					</Box>
				</form>
			</Box>
		</>
	);
};
