import React, { useEffect, useState } from 'react';
import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CkEditor from '../Common/CkEditor/CkEditor';
import { AddIconSvg, CalendarIcon, TimeIcon, TrainingIcon } from '../../config/svg/CommonSvgIcon';
import DummyImage from '../../images/dummy-training-image.jpg';
import { GreyLogo } from '../../config/svg/GlobalSvg';
import { Skeleton } from '@material-ui/lab';
import { OKRButton } from '../Common/OKRButton';
import { MultiSelect } from '../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../Admin/CommonComponent/NoSearchRecord';

export const TeamReviewTrainings: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const formDisabled = false;
	const htmlContent = '<b>Approved</b> with <i>minor feedback</i>';

	return (
		<>
			<Box className='add-new-program-area full-rectangular-card'></Box>
			<Box className='personalized-growth-card full-rectangular-card'>
				<Box className='personalized-growth-head'>
					<Box className='personalized-growth-head-left'>
						<Typography variant='h4' className='font-weight-normal'>
							{t('personalizedGrowthPathways')}
						</Typography>
						<Typography variant='subtitle2' className='subtitle3'>
							{t('personalizedGrowthPathwaysText')}
						</Typography>
					</Box>
					<Box className='personalized-growth-head-right'>
						<OKRButton
							className='add-btn'
							icon={<AddIconSvg />}
							title={t('Add Trainings')}
							//handleClick={handleCreateFeedbackClick}
						/>
					</Box>
				</Box>
				<Box className='course-card-panel-list'>
					<Box className='course-card-panel'>
						<Box className='courses-img'>
							<img src={DummyImage} alt='course-img' />
						</Box>
						<Box className='courses-content-section'>
							<Box className='course-content-header'>
								<Typography variant='subtitle2'>Leadership</Typography>
								<Tooltip title='Tooltip Text' arrow>
									<Typography variant='h4' className='font-weight-normal'>
										A World of Hurt: Central Nervous System Pain A World of Hurt: Central Nervous System Pain
									</Typography>
								</Tooltip>
							</Box>
							<Box className='course-session'>
								<Box className='course-published'>
									<CalendarIcon />
									<Typography variant='h6'>Apr 24, 2025</Typography>
								</Box>
								<Box className='courses-duration'>
									<TimeIcon />
									<Typography variant='h6'>06:00</Typography>
								</Box>
							</Box>
							<Box className='course-description'>
								<Tooltip title='Tooltip Text' arrow>
									<Typography variant='body2' className='font-weight-normal'>
										This course delves into the complexities of central nervous system (CNS) pain, exploring the
										underlying mechanisms, diagnosis, and treatment options. Participants will gain a comprehensive
										understanding of how pain originates within the CNS, including conditions like neuropathic pain,
										fibromyalgia, and other chronic pain syndromes. Through a combination of theoretical knowledge and
										practical applications, this course equips healthcare professionals with the tools to assess,
										manage, and treat CNS-related pain, ultimately improving patient outcomes. Ideal for physicians,
										nurses, physical therapists, and other healthcare providers, this course is essential for anyone
										seeking to deepen their expertise in pain management.
									</Typography>
								</Tooltip>
							</Box>
						</Box>
					</Box>
					<Box className='course-card-panel'>
						<Box className='courses-img'>
							<img src={DummyImage} alt='course-img' />
						</Box>
						<Box className='courses-content-section'>
							<Box className='course-content-header'>
								<Typography variant='subtitle2'>Leadership</Typography>
								<Tooltip title='Tooltip Text' arrow>
									<Typography variant='h4' className='font-weight-normal'>
										A World of Hurt: Central Nervous System Pain A World of Hurt: Central Nervous System Pain
									</Typography>
								</Tooltip>
							</Box>
							<Box className='course-session'>
								<Box className='course-published'>
									<CalendarIcon />
									<Typography variant='h6'>Apr 24, 2025</Typography>
								</Box>
								<Box className='courses-duration'>
									<TimeIcon />
									<Typography variant='h6'>06:00</Typography>
								</Box>
							</Box>
							<Box className='course-description'>
								<Tooltip title='Tooltip Text' arrow>
									<Typography variant='body2' className='font-weight-normal'>
										This course delves into the complexities of central nervous system (CNS) pain, exploring the
										underlying mechanisms, diagnosis, and treatment options. Participants will gain a comprehensive
										understanding of how pain originates within the CNS, including conditions like neuropathic pain,
										fibromyalgia, and other chronic pain syndromes. Through a combination of theoretical knowledge and
										practical applications, this course equips healthcare professionals with the tools to assess,
										manage, and treat CNS-related pain, ultimately improving patient outcomes. Ideal for physicians,
										nurses, physical therapists, and other healthcare providers, this course is essential for anyone
										seeking to deepen their expertise in pain management.
									</Typography>
								</Tooltip>
							</Box>
						</Box>
					</Box>

					{/* No Course section */}
					<Box className='course-card-panel'>
						<Box className='courses-img no-course-img'>
							<Box className='no-img-content'>
								<GreyLogo />
								<Typography variant='h6'>{t('moreAacademiesText')}</Typography>
							</Box>
						</Box>
						<Box className='courses-content-section'>
							<Box className='course-content-header'>
								<Skeleton animation={false} width='70px'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
							<Box className='course-session'>
								<Box className='course-published'>
									<Skeleton animation={false} width='70px'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
								<Box className='courses-duration'>
									<Skeleton animation={false} width='70px'>
										<Typography>.</Typography>
									</Skeleton>
								</Box>
							</Box>
							<Box className='course-description'>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton animation={false} width='100%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className='other-trainings-comment-box'>
					<Box className='other-trainings-label'>
						<TrainingIcon />
						<Typography variant='h4' className='font-weight-normal'>
							{t('otherTrainings')}
						</Typography>
					</Box>
					<Box className={`other-trainings-comment-field helperText-bottom editior-pane long-text preview-overlay`}>
						{formDisabled ? (
							<Box className='view-only-comment long-text'>
								<span dangerouslySetInnerHTML={{ __html: htmlContent || 'No comment' }}></span>
							</Box>
						) : (
							<>
								<CkEditor
									placeholder={t('Recommend some other trainings')}
									showEmoticons={true}
									value={''}
									// handleEditorChange={(value: string) => {
									// 	if (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>') {
									// 	} else {
									// 	}
									// 	setFinalRatingData({ ...finalRatingData, finalComment: value });
									// 	setIsFormEdited(true);
									// }}
								/>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
