import {
	Box,
	TextField,
	Typography,
	FormLabel,
	Switch,
	FormControlLabel,
	Checkbox,
	Link,
	List,
	ListItem,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EmployeeIDIcon,
	ErrorIcon,
	NameIcon,
	ReportingToIcon,
	TeamsIcon,
	BorderCheckboxIcon,
	CheckedIcon,
	HeadIcon,
	SkipLevelManager,
	EmailIcon,
	HrBpIcon,
	HrBuddyIcon,
	RoleIcon,
	JobLevelIcon,
} from '../../../../config/svg/formElementIcons';
import { CalendarIcon, DeleteIcon, DownloadIcon, EditIcon } from '../../../../config/svg/CommonSvgIcon';
import _ from 'lodash';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { userDataFields } from '../UsersHelper';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { checkSupportPermission, formatDate, getTooltipLicense, isTrialVersion } from '../../../../config/utils';
import { InformationMessage } from '../../CommonComponent/InformationMessage';
import { Enums } from '../../../../config/enums';
import { USER_ROLE_ID } from '../../../../config/users';
import { OKRButton } from '../../../Common/OKRButton';
import '../../../../styles/pages/admin/users.scss';
import { DepartmentIcon, DesignationIcon } from '../../../../config/svg/CommonSvg';
import { useDispatch } from 'react-redux';
import { goalFormList, performanceRoleDetail, userDepartmentList } from '../../../../action/adminSettings';
import ReactDateRangePicker from '../../../Common/ReactDateRangePicker';
import { GoalFormIcon } from '../../../../config/svg/MyGoalSvg';
import { supportModuleAlignGoals, supportModulePerformAssessment } from '../../AdminConstant';
import UploadImg from '../../../../images/upload-img-new.svg';
import { InfoprolearningDomainName, PIFileExtension, PIFileFormat } from '../../../../config/constant';
import { getLocalStorageItem } from '../../../../services/StorageService';
import { useSnackbar } from 'notistack';
import { uploadPIPDocument } from '../../../../action/performanceImprovementPlan';

export const CreateUserForm: React.FC<any> = (props) => {
	const {
		getDesignation,
		searchEmployee,
		getActiveRoles,
		rolesDataResult,
		fetchOrganizationsList,
		listOrgResult,
		userFormData,
		setUserFormData,
		isEditForm,
		formError,
		setFormError,
		setFormEdited,
		licenseDetails,
		getLicenseDetails,
		licenseDetailsStatus,
		isExcludeFromCurrentPerformanceCycleShow,
		selectedDateRange,
		setSelectedDateRange,
		featureListType = 8,
		setLoader,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const [designationList, setDesignationList] = useState<string[]>([]);
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [teamsOptions, setTeamsOptions] = useState<any>([]);
	const [rolesOptions, setRolesOptions] = useState<any>([]);
	const [goalFormOptions, setGoalFormOptions] = useState<any>([]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
		performanceGoalFormId: false,
	});
	const [performanceRoleNameList, setPerformanceRoleName] = useState<string[]>([]);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isEditField, setIsEditField] = useState<boolean>(false);
	const [departmentList, setDepartmentList] = useState<string[]>([]);

	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 50);
	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 10);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const preventDefault = (event: any) => event.preventDefault();
	const inputFileRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		getGoalFormListDetails({
			finder: '',
			pageIndex: 1,
			sortingText: 'name',
			order: 'asc',
			pageSize: 99999,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (licenseDetailsStatus === '' && (!licenseDetails || Object.keys(licenseDetails).length === 0)) {
			getLicenseDetails();
		}
	}, [licenseDetails, licenseDetailsStatus]);

	useEffect(() => {
		if (rolesDataResult.length === 0) {
			getActiveRoles();
		} else {
			let defaultRole: any = [];
			let rolesOptionsList = rolesDataResult.map((rolesData: any) => {
				if (rolesData.roleId === USER_ROLE_ID) {
					defaultRole.push({
						...rolesData,
						label: rolesData.roleName,
						value: rolesData.roleId,
					});
				}
				return {
					...rolesData,
					label: rolesData.roleName,
					value: rolesData.roleId,
				};
			});
			if (!isEditForm && rolesOptionsList.length > 0) {
				setUserFormData({ ...userFormData, [userDataFields.roleId]: defaultRole });
			}
			setRolesOptions(rolesOptionsList);
		}
	}, [rolesDataResult]);

	useEffect(() => {
		if (!listOrgResult?.entityList) {
			fetchOrganizationsList();
		}
		if (listOrgResult) {
			if (listOrgResult.entityList && listOrgResult.entityList.length > 0) {
				setTeamsOptions(
					listOrgResult.entityList.map((orgData: any) => {
						return {
							...orgData,
							label: orgData.teamName,
							value: orgData.teamId,
							imagePath: orgData.logoImagePath,
							secondaryText: orgData.teamHeadName,
							count: orgData.employeeCount,
							colorCode: orgData.colorcode,
							backGroundColorCode: orgData.backGroundColorCode,
						};
					})
				);
			}
		}
	}, [listOrgResult]);

	const getGoalFormListDetails = async (requestPayload: any) => {
		const response: any = await dispatch(goalFormList(requestPayload));
		if (response) {
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				const { records } = entity;
				let optionsList = records
					.filter((data: any) => data.status === 2)
					.map((data: any) => {
						return {
							...data,
							label: data.name,
							value: data.performanceGoalFormId,
						};
					});
				setEmptyRecords({ ...emptyRecords, performanceGoalFormId: false });
				setGoalFormOptions(optionsList);
			} else {
				setEmptyRecords({ ...emptyRecords, performanceGoalFormId: true });
				setGoalFormOptions([]);
			}
		}
	};

	const searchPerformanceGoalForm = async (value: string) => {
		await getGoalFormListDetails({
			finder: value,
			pageIndex: Enums.ONE,
			sortingText: 'name',
			order: 'asc',
			pageSize: Enums.MAX_USER_SIZE,
		});
	};
	/**
	 * Designation on change funtion to initiate throttle call
	 * @param e event
	 */
	const handleDesignationThrottled = (e: any) => {
		e.preventDefault();
		const { value } = e.target;
		delayedQuery(value);
	};

	/**
	 * delay user typing and call api when user stops typing
	 */
	const delayedQuery = useCallback(
		_.debounce((q) => onDesignationChange(q), 500),
		[]
	);

	/**
	 * call designation api to fetch listing
	 * @param value string
	 */
	const onDesignationChange = async (value: string) => {
		if (value) {
			let resp = await getDesignation(`designation=${value}`);
			if (resp.data.status === 200) {
				setDesignationList(resp.data.entityList);
			}
		}
	};
	const handlePerformanceRoleNameThrottled = (e: any) => {
		e.preventDefault();
		const { value } = e.target;
		delayedPerformanceRoleNameQuery(value);
	};
	const delayedPerformanceRoleNameQuery = useCallback(
		_.debounce((q) => onPerformanceRoleChange(q), 500),
		[]
	);
	const onPerformanceRoleChange = async (value: string) => {
		if (value) {
			const resp: any = await dispatch(performanceRoleDetail(`performanceRole=${value}`));
			if (resp && resp?.data && resp?.data?.status === 200) {
				const { entityList } = resp.data || {};
				let roleName = [];
				if (entityList && entityList.length > 0) {
					roleName = entityList.map((item: any) => item.performanceRoleName);
				}
				setPerformanceRoleName(roleName || []);
			}
		}
	};

	const handleDepartmentThrottled = (e: any) => {
		e.preventDefault();
		const { value } = e.target;
		delayedDepartmentQuery(value);
	};

	const delayedDepartmentQuery = useCallback(
		_.debounce((q) => onDepartmentChange(q), 500),
		[]
	);

	const onDepartmentChange = async (value: string) => {
		if (value) {
			const resp: any = await dispatch(userDepartmentList(`department=${value}`));
			if (resp && resp?.data && resp?.data?.status === 200) {
				const { entityList } = resp.data || {};
				let department = [];
				if (entityList && entityList.length > 0) {
					department = entityList.map((item: any) => item);
				}
				setDepartmentList(department || []);
			}
		}
	};

	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			const resp = await searchEmployee(value, 1, 6, 1);
			let list = resp.data.entityList;
			//list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: userData.firstName + ' ' + userData.lastName,
							value: userData.employeeId,
							secondaryText: userData.designation,
						};
					})
				);
				setEmptyRecords({ ...emptyRecords, reportingTo: false });
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
				setReportingToOptions([]);
			}
		}
	};

	const setUserFieldData = (field: string, value: any) => {
		setUserFormData({ ...userFormData, [field]: value });
		setFormError({ ...formError, [field]: '' });
		setFormEdited(true);
	};
	const getUserFieldData = (field: string) => {
		return userFormData[field];
	};

	const handleChange = (e: any, name: any) => {
		e.preventDefault();
		const { target } = e;
		if (target.value.trim().length > 100) {
			return false;
		} else {
			setUserFieldData(name, target.value);
		}
	};

	const handleEditField = () => {
		setIsEditField(true);
	};

	// =========== Upload File ========================================================================
	const handleDragOver = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		const { file } = e?.dataTransfer || {};
		if (file && file.length) {
			onUpload(e, file);
		}
	};
	const onUpload = (e: any, file: any) => {
		if (file) {
			callUploadFileAPI(e, file);
		} else {
			showApiMsgs(`An error occurred`, 'error');
		}
	};

	const callUploadFileAPI = async (e: any, files: any) => {
		try {
			if (files && files?.length) {
				const allFiles: any[] = [];
				let isSizeError = false;
				let isFileTypeError = false;
				[...files]?.forEach((file: any) => {
					const fSize = file.size;
					const size = Math.round(fSize / 1024);
					const fileType = file.type;
					const extension = getFileExtension(file.name);
					if (size <= 30000) {
						if (PIFileFormat.includes(fileType.toLowerCase()) || PIFileExtension.includes(extension)) {
							allFiles.push(file);
						} else {
							isFileTypeError = true;
						}
					} else {
						isSizeError = true;
					}
				});
				if (isSizeError) {
					showApiMsgs(`File size exceeds the maximum allowed size of 30 MB.`, 'error');
				} else if (isFileTypeError) {
					showApiMsgs(t('Format of the file is not supported to upload.'), 'error');
				} else {
					await validateBulkTeam(allFiles);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getFileExtension = (fileName: any) => {
		return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const validateBulkTeam = async (files: any) => {
		try {
			const formData = new FormData();
			files.forEach((fileData: any) => {
				formData.append('uploadFiles', fileData);
			});
			setLoader(true);
			const response: any = await dispatch(uploadPIPDocument(formData, featureListType));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data || {};
				const newFile: any = {};
				if (entity) {
					const storageFileName = entity ? entity.split('/').at(-1) : '';
					newFile.filePath = entity || '';
					newFile.uploadFileName = files[0]?.name || '';
					newFile.storageFileName = storageFileName || entity?.name || '';

					setUserFormData({
						...userFormData,
						[userDataFields.piFilePath]: newFile.filePath,
						[userDataFields.piUploadFileName]: newFile.uploadFileName,
						[userDataFields.piStorageFileName]: newFile.storageFileName,
					});
					setFormEdited(true);
					setLoader(false);
				}
			} else if (response?.data?.status === 400 || response?.data?.errors?.status === 400) {
				const responseAPI = response?.data?.messageList || response?.data?.errors?.UploadFiles || {};
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				showApiMsgs(`${messages} `, 'error');
				setLoader(false);
			}
		} catch (error) {
			showApiMsgs(`An error occurred`, 'error');
			setLoader(false);
		}
	};

	const handleUploadedFileDownload = async (e: any, file: any) => {
		try {
			const s3Url = file?.piFilePath || '';
			if (s3Url) {
				const response = await fetch(s3Url);
				const blob = await response.blob();

				// Create a link element and simulate a click to trigger the download
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = file?.piUploadFileName || 'PI Form'; // Set the desired file name
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				showApiMsgs(`An error occurred`, 'error');
			}
		} catch (error) {
			showApiMsgs(`An error occurred`, 'error');
		}
	};
	const handleUploadedFileDeletion = (e: any, file: any) => {
		setUserFormData({
			...userFormData,
			[userDataFields.piFilePath]: '',
			[userDataFields.piUploadFileName]: '',
			[userDataFields.piStorageFileName]: '',
		});
		setFormEdited(true);
	};

	const handleFileChange = async (e: any) => {
		const file = e.target.files;
		callUploadFileAPI(e, file);
	};

	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};
	// =========== Upload File ========================================================================

	return (
		<Fragment>
			{!isEditForm && licenseDetails && licenseDetails.availableLicense <= Enums.LICENSE_THRESHOLD ? (
				<InformationMessage
					messageText={t('lessThenFiveSeatsAvailable', {
						availableLicense: licenseDetails.availableLicense > 0 ? licenseDetails.availableLicense : 0,
					})}
					title={`${getTooltipLicense(
						licenseDetails.availableLicense > 0 ? licenseDetails.availableLicense : 0,
						licenseDetails.bufferLicense,
						t
					)}`}
					icon={false}
					toolTipClassName={
						licenseDetails.availableLicense <= 0 ||
						(licenseDetails.availableLicense === 0 && licenseDetails.bufferLicense === 0)
							? 'tooltip-layout4'
							: 'tooltip-layout2'
					}
				/>
			) : (
				<></>
			)}
			<form className='drawer-form-fields' noValidate autoComplete='off'>
				<>
					<Box className='drawer-blue-bg-full'>
						<Box className='drawer-form-fields-group'>
							<Box className='drawer-input-field drawer-input-field-col2'>
								<TextField
									id='firstName'
									className='field-col2 helperText-bottom'
									label={
										<Fragment>
											<NameIcon />
											{t('firstNameLabel')}
											<i style={{ color: 'red' }}> *</i>
										</Fragment>
									}
									placeholder={t('First name')}
									value={getUserFieldData(userDataFields.firstName)}
									name={t('firstNameLabel')}
									error={(formError && formError[userDataFields.firstName]) || ''}
									helperText={
										formError &&
										formError[userDataFields.firstName] && (
											<>
												<ErrorIcon /> {formError[userDataFields.firstName] || ''}
											</>
										)
									}
									onChange={(e) => {
										handleChange(e, userDataFields.firstName);
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t('firstNameLabel'), disableUnderline: true }}
								/>
								<TextField
									id='lastName'
									className='field-col2 helperText-bottom'
									label={
										<Fragment>
											<NameIcon />
											{t('lastNameLabel')}
											<i style={{ color: 'red' }}> *</i>
										</Fragment>
									}
									placeholder={t('Last name')}
									value={getUserFieldData(userDataFields.lastName)}
									name={t('lastNameLabel')}
									onChange={(e) => {
										handleChange(e, userDataFields.lastName);
									}}
									error={(formError && formError[userDataFields.lastName]) || ''}
									helperText={
										formError &&
										formError[userDataFields.lastName] && (
											<>
												<ErrorIcon /> {formError[userDataFields.lastName] || ''}
											</>
										)
									}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ 'aria-label': t('lastNameLabel'), disableUnderline: true }}
								/>
							</Box>
							<Box className='drawer-input-field drawer-input-field-col2'>
								<TextField
									id='emailAddress'
									className='field-col2 helperText-bottom'
									label={
										<Fragment>
											<EmailIcon />
											{t('emailAddressLabel')}
											<i style={{ color: 'red' }}> *</i>
										</Fragment>
									}
									placeholder={t('emailAddressPlaceholder')}
									error={(formError && formError[userDataFields.emailId]) || ''}
									// disabled={isEditForm === true}
									disabled={isEditField === false && isEditForm}
									helperText={
										formError &&
										formError[userDataFields.emailId] && (
											<>
												<ErrorIcon /> {formError[userDataFields.emailId] || ''}
											</>
										)
									}
									value={getUserFieldData(userDataFields.emailId)}
									name={t('emailAddressLabel')}
									onChange={(e) => {
										setUserFieldData(userDataFields.emailId, e.target.value);
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										'aria-label': t('emailAddressPlaceholder'),
										disableUnderline: true,
										// endAdornment: <span className='edit-icon-in-label'><EditIcon /></span>,
										endAdornment: (
											<span>
												{isEditForm ? (
													<span className='edit-icon-in-label' onClick={handleEditField}>
														{/* {<EditIcon />} */}
														<OKRButton
															className='user-edit-icon'
															// handleClick={(e) => handleMenuClick(e, 'editUser', user)}
															icon={<EditIcon />}
															title={t('editUserDetailsText')}
														/>
													</span>
												) : (
													<></>
												)}
											</span>
										),
									}}
								/>
								<Box className='field-col2'>
									<Box className='drawer-form-fields-group'>
										<Box className='drawer-input-field drawer-input-field-col2 drawer-input-field-col2-inner'>
											<TextField
												id='employeeID'
												className='field-col2 helperText-bottom'
												label={
													<Fragment>
														<EmployeeIDIcon />
														{t('employeeIDLabel')}
													</Fragment>
												}
												placeholder={t('userEmployeeIDPlaceholder')}
												value={getUserFieldData(userDataFields.employeeCode)}
												name={t('employeeIDLabel')}
												onChange={(e) => {
													setUserFieldData(userDataFields.employeeCode, e.target.value);
												}}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{ 'aria-label': t('userEmployeeIDPlaceholder'), disableUnderline: true }}
												error={(formError && formError[userDataFields.employeeCode]) || ''}
												helperText={
													formError &&
													formError[userDataFields.employeeCode] && (
														<>
															<ErrorIcon /> {formError[userDataFields.employeeCode] || ''}
														</>
													)
												}
											/>
											<Box className='field-col2'>
												<FormLabel>
													<CalendarIcon />
													{t('joiningDate')}
												</FormLabel>
												<Box className='range-selector-calender drawer-date-field'>
													<ReactDateRangePicker
														type={'joiningDate'}
														startDateValue={null}
														minimumDate={minDateValue}
														maximumDate={maxDateValue}
														handleDateSelection={(
															range: any,
															start: any,
															end: any,
															selectionType: any,
															isCycleChanged: any
														) => {
															range && setSelectedDateRange(range);
															setUserFieldData(userDataFields.joiningDate, new Date(formatDate(start)));
														}}
														isDateRangeTextFieldVisible={true}
														isOnlyDueDateVisible={false}
														allCycleDetails={[]}
														isCycleVisible={false}
														isEndOfMonthVisible={false}
														numberOfCalendars={Enums.ONE}
														selectionType={'single'}
														selectedDateRange={selectedDateRange}
														setSelectedDateRange={(range: any) => {
															range && setSelectedDateRange(range);
														}}
														placeholderText={t('selectDateLabel')}
														isDatePickerDisable={false}
													/>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box className='drawer-input-field drawer-input-field-col2'>
								<Box className='field-col2'>
									<FormControlLabel
										className='toggle-label'
										control={
											<Switch
												name='isProbationPeriod'
												checked={getUserFieldData(userDataFields.isProbationPeriod)}
												onChange={(event: any) => {
													setUserFieldData(userDataFields.isProbationPeriod, event.target.checked ? true : false);
												}}
											/>
										}
										label={t('isProbationPeriod')}
									/>
								</Box>
								<Box className='field-col2'>
									{!isEditForm && (
										<Box className='invite-user-text'>
											<Box className='invite-user-action'>
												<FormControlLabel
													control={
														<Switch
															name='inviteUser'
															checked={getUserFieldData(userDataFields.isEmailSend)}
															onChange={(e) => setUserFieldData(userDataFields.isEmailSend, e.target.checked)}
														/>
													}
													label={t('inviteUser')}
													className='toggle-label'
												/>
												<Typography className='short-des-text'>
													{getUserFieldData(userDataFields.isEmailSend)
														? t('enableInviteLabel')
														: t('disableInviteLabel')}
												</Typography>
											</Box>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
						<Box className='drawer-form-fields-group user-team-info-group'>
							<Box className='drawer-input-field drawer-input-field-col2'>
								<Autocomplete
									freeSolo
									className='field-col2 autocomplete-text helperText-bottom'
									classes={{ popper: 'autocomplete-popper' }}
									disableClearable
									options={designationList}
									value={getUserFieldData(userDataFields.designation)}
									onChange={(e, newValue) => {
										setUserFieldData(userDataFields.designation, newValue);
									}}
									renderInput={(params: any) => (
										<TextField
											{...params}
											label={
												<Fragment>
													<DesignationIcon />
													{t('designationLabel')}
												</Fragment>
											}
											onChange={(e) => {
												handleDesignationThrottled(e);
												setUserFieldData(userDataFields.designation, e.target.value);
											}}
											error={(formError[userDataFields.designation] && formError[userDataFields.designation]) || ''}
											helperText={
												formError[userDataFields.designation] &&
												formError[userDataFields.designation] && (
													<>
														<ErrorIcon />
														{(formError[userDataFields.designation] && formError[userDataFields.designation]) || ''}
													</>
												)
											}
											placeholder={t('searchAddJobTitleText')}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
								{
									<Box
										className={`field-col2 ${
											formError[userDataFields.teamId] && formError[userDataFields.teamId]
												? 'select-error helperText-bottom '
												: ''
										}`}
									>
										<FormLabel>
											<TeamsIcon />
											{t('teamsLabel')}
											<i style={{ color: 'red' }}> *</i>
										</FormLabel>
										<MultiSelect
											key={'teamId'}
											id='team-id-dropdown'
											selectedOptions={getUserFieldData(userDataFields.teamId)}
											optionsList={teamsOptions}
											onSelectOption={(value: any) => {
												setUserFieldData(userDataFields.teamId, value);
											}}
											customFilter={(option: any, searchText: any) => {
												if (searchText.length >= 3 && option.label.toLowerCase().includes(searchText.toLowerCase())) {
													return true;
												}
											}}
											placeHolder={t('searchText')}
											isMulti={true}
											noOptionsMessage={<NoSearchRecord />}
											showNoOptions={emptyRecords.team === true}
											fetchAsyncData={false}
											isSingleSelection={false}
											performSearch={(searchText: string) => {
												if (searchText.length > 3) {
													setEmptyRecords({ ...emptyRecords, team: true });
												} else {
													setEmptyRecords({ ...emptyRecords, team: false });
												}
												setFormError({ ...formError, teamId: '' });
											}}
											blurCalled={() => {
												setEmptyRecords({ ...emptyRecords, team: false });
											}}
											closeMenuOnSelect={true}
											labelTemplate={'teamAvatarLabel'}
											selectClassName={'select-search-dropdown select-search-team-dropdown'}
											selectClassNamePrefix={'react-select'}
											error={(formError[userDataFields.teamId] && formError[userDataFields.teamId]) || ''}
											helperText={
												formError[userDataFields.teamId] &&
												formError[userDataFields.teamId] && (
													<>
														<ErrorIcon />
														{(formError[userDataFields.teamId] && formError[userDataFields.teamId]) || ''}
													</>
												)
											}
										/>
									</Box>
								}
							</Box>
							<Box className={`drawer-input-field drawer-input-field-col2`}>
								{
									<Box
										className={`field-col2 ${
											formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]
												? 'select-error helperText-bottom '
												: ''
										}`}
									>
										<FormLabel>
											<ReportingToIcon />
											{t('reportingToLabel')}
										</FormLabel>
										<MultiSelect
											key={'reportingTo'}
											id='reporting-to-dropdown'
											selectedOptions={getUserFieldData(userDataFields.reportingTo)}
											optionsList={reportingToOptions}
											onSelectOption={(value: any) => {
												setUserFieldData(userDataFields.reportingTo, value);
												setReportingToOptions([]);
												setEmptyRecords({ ...emptyRecords, reportingTo: false });
											}}
											isMulti={true}
											customFilter={() => {}}
											placeHolder={t('searchText')}
											noOptionsMessage={<NoSearchRecord />}
											showNoOptions={emptyRecords.reportingTo === true}
											fetchAsyncData={true}
											isSingleSelection={true}
											performSearch={searchReportingTo}
											closeMenuOnSelect={true}
											labelTemplate={'avatarLabel'}
											selectClassName={'select-search-dropdown'}
											selectClassNamePrefix={'react-select'}
											error={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
											helperText={
												(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''
											}
										/>
									</Box>
								}
								<Box
									className={`field-col2 ${
										formError[userDataFields.skipLevelManagerId] && formError[userDataFields.skipLevelManagerId]
											? 'select-error helperText-bottom '
											: ''
									}`}
								>
									<FormLabel>
										<SkipLevelManager />
										{t('skipLevelManager')}
									</FormLabel>
									<MultiSelect
										key={'skipLevelManager'}
										id='skip-level-manager'
										selectedOptions={getUserFieldData(userDataFields.skipLevelManagerId)}
										optionsList={reportingToOptions}
										onSelectOption={(value: any) => {
											setUserFieldData(userDataFields.skipLevelManagerId, value);
											setReportingToOptions([]);
											setEmptyRecords({ ...emptyRecords, skipLevelManagerId: false });
										}}
										isMulti={true}
										customFilter={() => {}}
										placeHolder={t('searchText')}
										noOptionsMessage={<NoSearchRecord />}
										showNoOptions={emptyRecords.skipLevelManagerId === true}
										fetchAsyncData={true}
										isSingleSelection={true}
										performSearch={searchReportingTo}
										closeMenuOnSelect={true}
										labelTemplate={'avatarLabel'}
										selectClassName={'select-search-dropdown'}
										selectClassNamePrefix={'react-select'}
										//error={(formError[userDataFields.skipManager] && formError[userDataFields.skipManager]) || ''}
										//helperText={(formError[userDataFields.skipManager] && formError[userDataFields.skipManager]) || ''}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box className='drawer-form-fields-group department-fields-group'>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<Box className='field-col2'>
								<Autocomplete
									freeSolo
									className='autocomplete-text helperText-bottom'
									classes={{ popper: 'autocomplete-popper' }}
									disableClearable
									options={departmentList}
									value={getUserFieldData(userDataFields.department)}
									onChange={(e, newValue) => {
										setUserFieldData(userDataFields.department, newValue);
									}}
									renderInput={(params: any) => (
										<TextField
											{...params}
											label={
												<Fragment>
													<DepartmentIcon />
													{t('departmentLabel')}
												</Fragment>
											}
											onChange={(e) => {
												handleDepartmentThrottled(e);
												setUserFieldData(userDataFields.department, e.target.value);
											}}
											error={(formError[userDataFields.department] && formError[userDataFields.department]) || ''}
											helperText={
												formError[userDataFields.department] &&
												formError[userDataFields.department] && (
													<>
														<ErrorIcon />
														{(formError[userDataFields.department] && formError[userDataFields.department]) || ''}
													</>
												)
											}
											placeholder={t('departmentPlaceholdar')}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
							</Box>
							<Box
								className={`field-col2 ${
									formError[userDataFields.businessUnitHeadId] && formError[userDataFields.businessUnitHeadId]
										? 'select-error helperText-bottom '
										: ''
								}`}
							>
								<FormLabel>
									<HeadIcon />
									{t('buHead')}
								</FormLabel>
								<MultiSelect
									key={'business-unit-head'}
									id='business-unit-head'
									selectedOptions={getUserFieldData(userDataFields.businessUnitHeadId)}
									optionsList={reportingToOptions}
									onSelectOption={(value: any) => {
										setUserFieldData(userDataFields.businessUnitHeadId, value);
										setReportingToOptions([]);
										setEmptyRecords({ ...emptyRecords, businessUnitHeadId: false });
									}}
									isMulti={true}
									customFilter={() => {}}
									placeHolder={t('searchText')}
									noOptionsMessage={<NoSearchRecord />}
									showNoOptions={emptyRecords.businessUnitHeadId === true}
									fetchAsyncData={true}
									isSingleSelection={true}
									performSearch={searchReportingTo}
									closeMenuOnSelect={true}
									labelTemplate={'avatarLabel'}
									selectClassName={'select-search-dropdown'}
									selectClassNamePrefix={'react-select'}
									//error={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
									//helperText={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
								/>
							</Box>
						</Box>
						<Box className='drawer-input-field drawer-input-field-col2'>
							<Box
								className={`field-col2 ${
									formError[userDataFields.hrbpId] && formError[userDataFields.hrbpId]
										? 'select-error helperText-bottom '
										: ''
								}`}
							>
								<FormLabel>
									<HrBpIcon />
									{t('hrBp')}
								</FormLabel>
								<MultiSelect
									key={'hrBp'}
									id='hr-bp'
									selectedOptions={getUserFieldData(userDataFields.hrbpId)}
									optionsList={reportingToOptions}
									onSelectOption={(value: any) => {
										setUserFieldData(userDataFields.hrbpId, value);
										setReportingToOptions([]);
										setEmptyRecords({ ...emptyRecords, hrbpId: false });
									}}
									isMulti={true}
									customFilter={() => {}}
									placeHolder={t('searchText')}
									noOptionsMessage={<NoSearchRecord />}
									showNoOptions={emptyRecords.hrbpId === true}
									fetchAsyncData={true}
									isSingleSelection={true}
									performSearch={searchReportingTo}
									closeMenuOnSelect={true}
									labelTemplate={'avatarLabel'}
									selectClassName={'select-search-dropdown'}
									selectClassNamePrefix={'react-select'}
									//error={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
									//helperText={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
								/>
							</Box>
							{/* <Box
								className={`field-col2 ${
									formError[userDataFields.hrBuddyId] && formError[userDataFields.hrBuddyId]
										? 'select-error helperText-bottom '
										: ''
								}`}
							>
								<FormLabel>
									<HrBuddyIcon />
									{t('hrBuddy')}
								</FormLabel>
								<MultiSelect
									key={'hrBuddy'}
									id='hr-buddy'
									selectedOptions={getUserFieldData(userDataFields.hrBuddyId)}
									optionsList={reportingToOptions}
									onSelectOption={(value: any) => {
										setUserFieldData(userDataFields.hrBuddyId, value);
										setReportingToOptions([]);
										setEmptyRecords({ ...emptyRecords, hrBuddyId: false });
									}}
									isMulti={true}
									customFilter={() => {}}
									placeHolder={t('searchText')}
									noOptionsMessage={<NoSearchRecord />}
									showNoOptions={emptyRecords.hrBuddyId === true}
									fetchAsyncData={true}
									isSingleSelection={true}
									performSearch={searchReportingTo}
									closeMenuOnSelect={true}
									labelTemplate={'avatarLabel'}
									selectClassName={'select-search-dropdown'}
									selectClassNamePrefix={'react-select'}
									//error={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
									//helperText={(formError[userDataFields.reportingTo] && formError[userDataFields.reportingTo]) || ''}
								/>
							</Box> */}
						</Box>
					</Box>
					<Box className='drawer-form-fields-group assign-role-fields-group'>
						<Box className='assign-role-field-divider'></Box>
						<Box className='drawer-input-field drawer-input-field-col2'>
							{
								<Box
									className={`field-col2  ${
										formError[userDataFields.roleId] && formError[userDataFields.roleId]
											? 'select-error helperText-bottom '
											: ''
									}`}
								>
									<FormLabel>
										<RoleIcon />
										{t('roleLabel')}
									</FormLabel>
									<MultiSelect
										key={'rolesIdDropdown'}
										id='roles-id-dropdown'
										selectedOptions={getUserFieldData(userDataFields.roleId)}
										optionsList={rolesOptions}
										onSelectOption={(value: any) => {
											setUserFieldData(userDataFields.roleId, value);
										}}
										customFilter={(option: any, searchText: any) => {
											if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
												return true;
											}
										}}
										placeHolder={t('assignRoleLabel')}
										noOptionsMessage={<NoSearchRecord />}
										showNoOptions={emptyRecords.roleId === true}
										fetchAsyncData={false}
										isSingleSelection={false}
										performSearch={() => {}}
										closeMenuOnSelect={true}
										labelTemplate={'onlyLabel'}
										isMulti={false}
										selectClassName={`dropdown-default-wrapper`}
										selectClassNamePrefix={'dropdown-default'}
										disableSearch={true}
										error={(formError[userDataFields.roleId] && formError[userDataFields.roleId]) || ''}
										helperText={
											formError[userDataFields.roleId] &&
											formError[userDataFields.roleId] && (
												<>
													<ErrorIcon /> {(formError[userDataFields.roleId] && formError[userDataFields.roleId]) || ''}
												</>
											)
										}
									/>
								</Box>
							}
							<Box className='field-col2 '>
								<Autocomplete
									freeSolo
									className='autocomplete-text helperText-bottom'
									classes={{ popper: 'autocomplete-popper' }}
									disableClearable
									options={performanceRoleNameList}
									value={getUserFieldData(userDataFields.performanceRoleName)}
									onChange={(e, newValue) => {
										setUserFieldData(userDataFields.performanceRoleName, newValue);
									}}
									renderInput={(params: any) => (
										<TextField
											{...params}
											label={
												<Fragment>
													<JobLevelIcon />
													{t('gradeLabel')}
													<i style={{ color: 'red' }}> *</i>
												</Fragment>
											}
											onChange={(e) => {
												handlePerformanceRoleNameThrottled(e);
												setUserFieldData(userDataFields.performanceRoleName, e.target.value);
											}}
											error={
												(formError[userDataFields.performanceRoleName] &&
													formError[userDataFields.performanceRoleName]) ||
												''
											}
											helperText={
												formError[userDataFields.performanceRoleName] &&
												formError[userDataFields.performanceRoleName] && (
													<>
														<ErrorIcon />
														{(formError[userDataFields.performanceRoleName] &&
															formError[userDataFields.performanceRoleName]) ||
															''}
													</>
												)
											}
											placeholder={t('gradeLabel')}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									)}
								/>
								{checkSupportPermission(supportModulePerformAssessment) && isExcludeFromCurrentPerformanceCycleShow ? (
									<FormControlLabel
										control={
											<Checkbox
												name='exclude-current-cycle'
												checked={getUserFieldData(userDataFields.isExcludeFromCurrentPerformanceCycle)}
												onChange={(e: any) => {
													const { checked } = e?.target;
													setUserFieldData(userDataFields.isExcludeFromCurrentPerformanceCycle, checked || false);
												}}
												icon={<BorderCheckboxIcon />}
												checkedIcon={<CheckedIcon />}
												id={'exclude-current-cycle'}
											/>
										}
										className={'exclude-check-box'}
										label={t('excludeCurrentCycle')}
									/>
								) : (
									<></>
								)}
							</Box>
						</Box>
						{checkSupportPermission(supportModuleAlignGoals) ? (
							<Box className='drawer-input-field drawer-input-field-col2'>
								<Box className='field-col2'>
									<FormLabel>
										<GoalFormIcon />
										{t('goalFormLabel')}
									</FormLabel>
									{
										<Box>
											<MultiSelect
												key={'performanceGoalFormDropdown'}
												id='performance-goal-form-dropdown'
												selectedOptions={getUserFieldData(userDataFields.performanceGoalFormId)}
												optionsList={goalFormOptions}
												onSelectOption={(value: any) => {
													setUserFieldData(userDataFields.performanceGoalFormId, value);
												}}
												customFilter={() => {}}
												placeHolder={t('goalFormPlaceholder')}
												noOptionsMessage={<NoSearchRecord />}
												showNoOptions={emptyRecords.performanceGoalFormId === true}
												fetchAsyncData={true}
												isSingleSelection={false}
												performSearch={searchPerformanceGoalForm}
												closeMenuOnSelect={true}
												labelTemplate={'onlyLabel'}
												isMulti={false}
												selectClassName={`dropdown-default-wrapper`}
												selectClassNamePrefix={'dropdown-default'}
												disableSearch={false}
											/>
										</Box>
									}
								</Box>
							</Box>
						) : (
							<></>
						)}
						{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
							<Box className='drawer-input-field drawer-input-field-col2 drawer-pi-attachment-field'>
								<Box className='field-col2'>
									<input
										ref={inputFileRef}
										type='file'
										style={{ display: 'none' }}
										onChange={handleFileChange}
										multiple={true}
									/>
									<Box className={`upload-documents`}>
										<Box className='upload-documents-field'>
											<FormLabel id='upload-document' component='legend' className='form-label'>
												<DownloadIcon />
												{t('PI Form')}
											</FormLabel>
											<div onDrop={handleDrop} onDragOver={handleDragOver}>
												<Box
													className='upload-file'
													onClick={(event: any) => {
														uploadFile(event);
													}}
												>
													<Box className='upload-file-inner'>
														<img src={UploadImg} alt='Upload File' className='upload-img' />
														<Box className='upload-right'>
															<Typography variant='h5'>
																{t('uploadText')}
																<Link id={'browse-btn'} href='#' onClick={preventDefault}>
																	{t('UsersBulkUploadBrowseFile')}
																</Link>
															</Typography>
														</Box>
													</Box>
												</Box>
											</div>
											<Box className='file-des'>
												<Typography variant='h6'>{t('piFormttachmentNotes')}</Typography>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box className='field-col2'>
									<Box className='upload-attachments-list'>
										<List>
											{userFormData?.piFilePath ? (
												<ListItem>
													<span className='count'>{1}</span>
													<Box className='file-name'>
														<Typography>{userFormData?.piUploadFileName}</Typography>
														<Box className='attachment-action'>
															<OKRButton
																icon={<DownloadIcon />}
																title={t('download')}
																handleClick={(e: any) =>
																	handleUploadedFileDownload(e, { ...userFormData, fileIndex: 1 })
																}
																className='btn'
															/>
															<OKRButton
																icon={<DeleteIcon />}
																title={t('delete')}
																handleClick={(e: any) =>
																	handleUploadedFileDeletion(e, { ...userFormData, fileIndex: 1 })
																}
																className='btn'
															/>
														</Box>
													</Box>
												</ListItem>
											) : (
												<></>
											)}
										</List>
									</Box>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</>
			</form>
		</Fragment>
	);
};
