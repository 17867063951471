import React, { FC, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { OKRButton } from '../../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import LinearLoader from '../../../Common/Loader';
import { createUpdateAdminLGSettings, getAdminLGSettings } from '../../../../action/letterGeneration';
import { Enums } from '../../../../config/enums';
import { UserAvatarGroup } from '../../CommonComponent/UserAvatarGroup';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { LetterAddUserDrawer } from './LetterAddUserDrawer';
import AlertDialog from '../../../Common/Dialog';

export const LetterGeneration: FC<any> = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const staticData = {
		selectedUser: [],
		raisedForId: [],
	};

	const [initialLGSettings, setInitialLGSettings] = useState<any>(null);
	const [initialDefaultLGSettings, setInitialDefaultLGSettings] = useState<any>(null);
	const [loader, setLoader] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [openUserDrawer, setOpenUserDrawer] = useState<any>({ open: false, type: '', selectedUserDetails: null });
	const [includedUseList, setIncludedUseList] = useState<any>(staticData);
	const [localArrList, setLocalArrList] = useState<any>({ selectedUser: [], raisedForId: [] });
	const [isLGUsersEdited, setIsLGUsersEdited] = useState<boolean>(false);
	const [isProgressUsersEdited, setIsProgressUsersEdited] = useState<boolean>(false);
	const [openProgressUserDrawer, setOpenProgressUserDrawer] = useState<any>({
		open: false,
		type: '',
		selectedUserDetails: null,
	});
	const [includedProgressUseList, setIncludedProgressUseList] = useState<any>(staticData);
	const [showDialog, setShowDialog] = useState<any>({
		open: false,
		message: '',
		isCancel: true,
		tagData: null,
		type: '',
	});

	useEffect(() => {
		getLGBaseSettings();
	}, []);

	useEffect(() => {
		if (initialLGSettings) {
			setIncludedProgressUseList(includedUseList);
		}
	}, [includedUseList]);

	const getLGBaseSettings = async () => {
		try {
			setLoader(true);
			const response: any = await dispatch(getAdminLGSettings());

			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				const { firstName, lastName, fullName, emailId, imagePath, employeeId, lgSettingId, settingType, designation } =
					entityList || {};
				const selectedUserDetails: any = [];
				const selectedProgressVisibilityUsers = setInitialLGSettings(entityList || null);
				setInitialDefaultLGSettings(entityList || null);
				setSelectedUser([
					{
						firstName: firstName || '',
						lastName: lastName || '',
						fullName: fullName || '',
						emailId: emailId || '',
						imagePath: imagePath || '',
						employeeId: employeeId || 0,
						lgSettingId: lgSettingId || 0,
						settingType: settingType || 0,
						designation: designation || '',
					},
				]);
				setIncludedUseList({
					selectedUser: [...entityList],
					raisedForId: [...entityList.map((item: any) => item.employeeId)],
				});
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating perspective.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const updateInitialSetting = (keyName: string, value: any) => {
		let updatedInitialLGSettings = { ...initialLGSettings };
		updatedInitialLGSettings = { ...updatedInitialLGSettings, [keyName]: value };
		setInitialLGSettings(updatedInitialLGSettings);
	};
	const handleChangeLGVisibility = (event: any, keyName: string, value: any) => {
		updateInitialSetting(keyName, value);
	};
	const handleChangeLGVisibilityForUser = (event: any, keyName: string, value: number) => {
		if (keyName) {
			updateInitialSetting(keyName, value);
		}
	};
	const handleResetLGSettingsForUser = (event: any) => {
		const { firstName, lastName, fullName, emailId, imagePath, employeeId } = initialDefaultLGSettings || {};
		setInitialLGSettings({ ...initialDefaultLGSettings });
		setSelectedUser([
			{
				firstName: firstName || '',
				lastName: lastName || '',
				fullName: fullName || '',
				emailId: emailId || '',
				imagePath: imagePath || '',
				employeeId: employeeId || 0,
			},
		]);
	};
	const handleSaveLGSettingsForUser = async (event: any) => {
		try {
			setLoader(true);
			const requestPayload = {
				settingType: 1,
				employeeIds:
					selectedUser && selectedUser.length
						? selectedUser.map((user: any) => user.employeeId)
						: [initialLGSettings?.employeeId],
			};
			const response: any = await dispatch(createUpdateAdminLGSettings(requestPayload));

			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Letter Generation permission updated successfully.`, {
					variant: 'success',
					autoHideDuration: 3000,
				});
				getLGBaseSettings();
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating Letter Generation.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleAddUserClick = (event: any) => {
		const selectedUserDetails =
			initialLGSettings?.LGVisibilityTypeId === Enums.THREE ? initialLGSettings?.LGVisibilityUsers : [];
		setOpenUserDrawer({ open: true, type: 'LGVisibilitySpecificUsers', selectedUserDetails });
	};
	const handleDrawerClose = (event: any, type: string) => {
		if (type === 'include') {
			let updatedData = {
				raisedForId: [...includedUseList?.raisedForId, ...localArrList?.raisedForId],
				selectedUser: [...includedUseList?.selectedUser, ...localArrList?.selectedUser],
			};
			const uniqueValue =
				updatedData?.raisedForId && updatedData?.raisedForId.length
					? Array.from(new Set([...updatedData.raisedForId]))
					: [];
			const finalData: any = [];
			uniqueValue &&
				uniqueValue.length &&
				uniqueValue.forEach((item: any) => {
					const data =
						updatedData.selectedUser && updatedData.selectedUser.length
							? updatedData.selectedUser.find((rec: any) => rec?.employeeId === item)
							: null;
					if (data) {
						finalData.push({ ...data });
					}
				});
			updatedData = {
				raisedForId: [...uniqueValue],
				selectedUser: [...finalData],
			};
			setIncludedUseList(updatedData);
			setOpenUserDrawer({ open: false, type: '', selectedUserDetails: null });
			setIsLGUsersEdited(false);
		} else if (type === 'cancel') {
			if (isLGUsersEdited && openUserDrawer?.type !== 'viewLGVisibilitySpecificUsers') {
				setShowDialog({
					open: true,
					type: 'LGVisibility',
					message: t('unSavedItemAlert'),
					isCancel: false,
					tagData: null,
				});
			} else {
				setIncludedUseList(includedUseList);
				setOpenUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsLGUsersEdited(false);
			}
		}
	};
	const handleUserAvatarClick = (event: any) => {
		const selectedUserDetails =
			initialLGSettings?.LGVisibilityTypeId === Enums.THREE ? initialLGSettings?.LGVisibilityUsers : [];
		setOpenUserDrawer({ open: true, type: 'LGVisibilitySpecificUsers', selectedUserDetails });
	};
	const handleCloseModal = (e: any, type: number) => {
		if (type === 1) {
			if (showDialog && showDialog?.type === 'LGVisibility') {
				setIncludedUseList(includedUseList);
				setOpenUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsLGUsersEdited(false);
			} else if (showDialog && showDialog?.type === 'ProgressVisibility') {
				setIncludedProgressUseList(includedProgressUseList);
				setOpenProgressUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsProgressUsersEdited(false);
			}
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
		} else {
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
			setIsLGUsersEdited(false);
			setIsProgressUsersEdited(false);
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}

			<Box className='align-settings'>
				<Box className='admin-tabpanel-inner'>
					<Box className='admin-tabpanel-head'>
						<Box className='admin-tabpanel-head-left'>
							<Typography variant='h2'>{t('letterGenTitle')}</Typography>
						</Box>
						<Box className='admin-tabpanel-head-right'></Box>
					</Box>

					<Box className='so-setting-card full-rectangular-card'>
						<Box className='so-setting-head'>
							<Box>
								<Typography variant='h4' className='font-weight-normal'>
									{t('letterGenTitle')}
								</Typography>
								<Typography variant='subtitle2' className='subtitle3'>
									{t('lgSettingSubHead')}
								</Typography>
							</Box>
						</Box>
						{/* <Box className='so-setting-switch'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='isLGfeature'
                                        checked={initialLGSettings?.isLGfeature || false}
                                        onChange={(event) => handleChangeLGVisibility(event, 'isLGfeature', event?.target?.checked)}
                                    />
                                }
                                label={t('setLetterGenLabel')}
                                labelPlacement='end'
                                className='toggle-label'
                            />
                        </Box> */}
						<Box className='so-setting-card-row' mt={2}>
							<Box className='so-setting-card-col'>
								<Typography variant='h5' className='font-weight-normal'>
									{t('visibiltyLGSettingLabel')}
								</Typography>
								{initialLGSettings ? (
									<UserAvatarGroup
										{...props}
										assignedUserDetails={
											includedUseList && includedUseList?.selectedUser && includedUseList?.selectedUser.length > 0
												? includedUseList?.selectedUser.map((item: any) => item)
												: []
										}
										isUserPlaceholder={true}
										subTitle={' '}
										helpText={' '}
										max={6}
										handleAddUserClick={handleAddUserClick}
										isOnHoverVisible={false}
										isUserAvatarClick={true}
										handleUserAvatarClick={handleUserAvatarClick}
										//isDisabled={!initialLGSettings?.isLGfeature}
									/>
								) : (
									<></>
								)}
								{openUserDrawer && openUserDrawer?.open && (
									<MainDrawer
										open={openUserDrawer?.open || false}
										loader={loader}
										transitionDuration={{ enter: 500, exit: 1000 }}
										headerTitle={
											openUserDrawer?.type === 'viewLGVisibilitySpecificUsers' ? 'Selected Users' : t('addUserLabel')
										}
										children={
											<Box className='drawer-inner-content'>
												{openUserDrawer?.type === 'LGVisibilitySpecificUsers' ||
												openUserDrawer?.type === 'viewLGVisibilitySpecificUsers' ? (
													<LetterAddUserDrawer
														settingType={1}
														type={openUserDrawer?.type || ''}
														selectedUserDetails={openUserDrawer?.selectedUserDetails}
														includedUseList={includedUseList}
														setIncludedUseList={setIncludedUseList}
														localArrList={localArrList}
														setLocalArrList={setLocalArrList}
														setLoader={setLoader}
														isEdited={isLGUsersEdited}
														setIsEdited={setIsLGUsersEdited}
														isLGVisibilitySpecificUserSelected={true}
														setSelectedUser={setSelectedUser}
													/>
												) : (
													<></>
												)}
											</Box>
										}
										saveButtonText={t('saveBtn')}
										rightCloseBtn={false}
										drawerClassName={'main-drawer-panel '}
										isSaveButtonVisible={openUserDrawer?.type !== 'viewLGVisibilitySpecificUsers' ? true : false}
										handleDrawerClose={(event: any) => handleDrawerClose(event, 'cancel')}
										handleSaveClick={(event: any) => handleDrawerClose(event, 'include')}
										handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
									/>
								)}
							</Box>
						</Box>

						<Box className='so-setting-card-actions'>
							<Box className='so-setting-card-actions-left'></Box>
							<Box className='so-setting-card-actions-right'>
								{/* <OKRButton className='btn-link' text={t('resetLabel')} handleClick={handleResetLGSettingsForUser} /> */}
								<OKRButton className='btn-primary' text={t('saveBtn')} handleClick={handleSaveLGSettingsForUser} />
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			{showDialog && showDialog?.open ? (
				<AlertDialog
					message={showDialog?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog?.open}
					isCancel={showDialog?.isCancel}
				/>
			) : (
				<></>
			)}
		</>
	);
};
