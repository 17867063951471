import {
	Box,
	Tabs,
	Tab,
	Typography,
	FormLabel,
	Avatar,
	Chip,
	TextField,
	InputAdornment,
	Switch,
	FormControlLabel,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { ManageUserDrawerForm } from '../../RolesAndPermissions/SystemRolesPermissions/ManageUserDrawerForm';
import { UserAvatarGroup } from '../../CommonComponent/UserAvatarGroup';
import { OKRButton } from '../../../Common/OKRButton';
import { TextInlineEdit } from '../../CommonComponent/TextInlineEdit';
import { CameraIcon, CrossIcon, EditIcon, NoOrgImgIcon } from '../../../../config/svg/CommonSvgIcon';
import { CalendarIcon, CheckInReminderIcon, ErrorIcon, TimerIcon } from '../../../../config/svg/formElementIcons';
import '../../../../styles/pages/admin/textInlineEdit.scss';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../../config/utils';
import { DatePickerField } from '../../CommonComponent/DatePickerField';
import { QuickSearchPopover } from '../../CommonComponent/QuickSearchPopover';
import { Enums } from '../../../../config/enums';
import { EmailTriggerIcon } from '../../../../config/svg/formElementIcons';
import { CheckInsIcon } from '../../../../config/svg/MyGoalSvg';

export const EditOrganizationForm: React.FC<any> = (props) => {
	const {
		selectedAddNewUserTab,
		handleTabChange,
		listOrgResult,
		fetchOrganizationsList,
		handleDeleteUser,
		searchUser,
		handleAssignModal,
		searchEmployeeText,
		assignedUser,
		showImageEditor,
		organisationDetails,
		handleRemoveOrgHead,
		handleOrgHead,
		errors,
		parentOrganisation,
	} = props;
	const { t } = useTranslation();
	const [inlineEdit, setInlineEdit] = React.useState(false);

	function a11yProps(index: number) {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	}
	const handleClick = () => {
		setInlineEdit(true);
	};
	useEffect(() => {
		if (!listOrgResult || listOrgResult.length === 0) {
			fetchOrganizationsList();
		}
	}, [listOrgResult]);

	return (
		<Fragment>
			<Tabs
				className='okr-tabs drawer-tabs'
				value={selectedAddNewUserTab}
				onChange={handleTabChange}
				aria-label='Admin Tabs'
			>
				<Tab label={t('editOrgDetailsLabel')} value={0} {...a11yProps(0)} />
				{/* {assignedUser.length > -1 && <Tab label={`${t('manageMembers')}`} value={1} {...a11yProps(1)} />} */}
				{(assignedUser?.length > 0 || (parentOrganisation?.employeeCount && parentOrganisation?.employeeCount > 0)) && (
					<Tab
						label={`${t('manageMembers')} 
							${parentOrganisation?.employeeCount > 0 ? '(' : ''}${
							parentOrganisation?.employeeCount > 0 ? parentOrganisation?.employeeCount : ''
						}${parentOrganisation?.employeeCount > 0 ? ')' : ''}`}
						value={1}
						{...a11yProps(1)}
					/>
				)}
			</Tabs>

			<Box className='drawer-tabs-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{selectedAddNewUserTab === 0 && (
						<>
							<Box className='drawer-input-field'>
								<Box className='user-team-detail'>
									<div className='team-logo-wrapper' id='team-avatar' onClick={() => showImageEditor(true)}>
										{organisationDetails.teamName && organisationDetails.logoImagePath ? (
											<Avatar
												id='team-logo'
												className='team-logo'
												key={`user-icon-${organisationDetails.teamId}`}
												src={organisationDetails.logoImagePath}
											>
												{organisationDetails.teamName
													? getUserName({
															firstName: '',
															lastName: '',
															fullName: organisationDetails.teamName,
													  })
													: ''}
											</Avatar>
										) : (
											<>
												{props.uploadedImage ? (
													<img className='org-logo' src={props.uploadedImage ? props.uploadedImage : ''} />
												) : (
													<Avatar id='team-logo' className='team-logo no-org-image' key={`user-icon-`} src={''}>
														{organisationDetails.teamName ? (
															getUserName({
																firstName: '',
																lastName: '',
																fullName: organisationDetails.teamName,
															})
														) : (
															<NoOrgImgIcon />
														)}
													</Avatar>
												)}
											</>
										)}

										<div className='camera-icon' onClick={() => showImageEditor(true)}>
											<label htmlFor='file-input' className='upload-image-label'>
												<CameraIcon />
											</label>
										</div>
									</div>
									<Box className='team-info'>
										<Box className='title-inline-field'>
											{!inlineEdit && (
												<Box className='title-field' id='title-field'>
													<Typography variant='h4'>
														{organisationDetails.teamName}
														<OKRButton
															handleClick={(e) => handleClick()}
															className='editIcon'
															id='edit-btn'
															icon={<EditIcon />}
															title={t('editTitleLabel')}
															tooltipPlacement={'top'}
														/>
													</Typography>
												</Box>
											)}
											{inlineEdit && (
												<TextInlineEdit
													id='team-name-inline'
													{...props}
													inlineEdit={inlineEdit}
													setInlineEdit={setInlineEdit}
													errorText={t('organisationFieldValidationText')}
													parentName={organisationDetails.teamName}
													updateValue={(value: any) => {
														props.handleOrgNameChange(value);
													}}
												/>
											)}
										</Box>
										<Box className='leader-detail'>
											{organisationDetails.teamHeadName ? (
												<Chip
													avatar={
														<Avatar src={organisationDetails.teamHeadImage || ''}>
															{getUserName({
																firstName: '',
																lastName: '',
																fullName: organisationDetails.teamHeadName,
															})}
														</Avatar>
													}
													label={organisationDetails.teamHeadName}
													className='chip-avatar'
													deleteIcon={
														<span>
															<CrossIcon />
														</span>
													}
													onDelete={handleRemoveOrgHead}
												/>
											) : (
												<QuickSearchPopover
													{...props}
													leader={organisationDetails.teamHeadName}
													onSelectedUser={(user: any) => {
														handleOrgHead(user);
													}}
												/>
											)}
											{errors.organisationHead && (
												<Typography className='search-error'>
													<ErrorIcon />
													{errors.organisationHead}
												</Typography>
											)}
											{errors.leader && (
												<Typography className='search-error'>
													{' '}
													<ErrorIcon />
													{errors.leader}
												</Typography>
											)}
										</Box>
									</Box>
								</Box>
							</Box>

							<Box className='drawer-blue-bg-full'>
								<Box className='drawer-form-fields-group'>
									<Box className='drawer-input-field'>
										<UserAvatarGroup
											{...props}
											subTitle={t('addMembersLabel')}
											helpText={t('addMembersShortText')}
											handleAddUserClick={handleAssignModal}
											assignedUserDetails={
												assignedUser.length > 0 && assignedUser.map((item: any, index: number) => item)
											}
											max={6}
										/>
									</Box>
								</Box>
							</Box>
						</>
					)}
					{selectedAddNewUserTab === 1 && (
						<ManageUserDrawerForm
							t={t}
							{...props}
							searchEmployeeText={searchEmployeeText}
							searchUser={searchUser}
							handleAssignModal={handleAssignModal}
							handleDeleteUser={handleDeleteUser}
							view={'editOrganisation'}
						/>
					)}
				</form>
			</Box>
		</Fragment>
	);
};
