import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Collapse, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { AttachmentIcon, RightArrowIcon } from '../../config/svg/CommonSvgIcon';
import '../../styles/pages/user-goals/user-goals-listing.scss';
import '../../styles/pages/user-goals/teams-goals-card.scss';
import { ConversationIcon, ResponseLogIcon } from '../../config/svg/CommonSvg';
import { CategorySettings } from './CategorySettings';
import {
	getGoalAlertConversation,
	getSelfReviewList,
	saveGoalReviewSavedApi,
	setPerfomanceGoalAttachmentCount,
	setPerformanceReviewDrUserData,
} from '../../action/adminSettings';
import { checkUnSavePopUpVisible, formatFeedbackDate, getMonthDateYearTime, getUserDetails } from '../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../config/enums';
import AlertDialog from '../Common/Dialog';
import { setPerformanceGoalForRedirection } from '../../action/common';
import { useSnackbar } from 'notistack';
import { GoalSettings } from './GoalSettings';
import { StarRating } from './SelfReview/StarRating';

export const ReviewGoalListing: React.FC<any> = (props) => {
	const { drUserData, fromTeamDetails, isDrSelfReviewData = true, handleNext } = props;
	const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
	const { t } = useTranslation();
	const loggedInUserDetail = getUserDetails();
	const [loader, setLoader] = useState<boolean>(false);
	const [categorySettingsOpen, setCategorySettingsOpen] = useState<any>({ open: false });
	const [currentTab, setCurrentTab] = useState<string>('');
	const dispatch = useDispatch();
	const [performanceGoalsList, setPerformanceGoalsList] = useState<any>({});
	const [expandedGoalList, setExpandedGoalList] = useState<number[]>([]);
	const { performanceGoalCycle } = useSelector((state: any) => state?.adminSettingReducer);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, isDraft: false, status: 0 });
	const { redirectedPerformanceGoalDetails } = useSelector((state: any) => state.commonReducer);
	const { enqueueSnackbar } = useSnackbar();
	const ref = useRef<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const [goalSettingsOpen, setGoalSettingsOpen] = useState<any>({ open: false });
	const userDetails = getUserDetails();
	const { currentGoalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);
	const [tooltipId, setTooltipId] = useState<any>('');

	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 20);
	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 20);
	useEffect(() => {
		if (redirectedPerformanceGoalDetails) {
			setTimeout(() => {
				dispatch(setPerformanceGoalForRedirection(null));
			}, 10000);
		}
		setPerformanceGoalsList({});
		setIsDataChanged(false);
	}, []);
	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDataChanged]);
	useEffect(() => {
		if (currentGoalReviewCycle && currentGoalReviewCycle?.performanceGoalReviewCycleId) {
			getUserPerformanceGoalsList();
		}
	}, [currentGoalReviewCycle]);
	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = document.getElementById('createLinkPopupDetail');
		const id2 = document.getElementById('back-to-main');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			checkUnSavePopUpVisible(event) &&
			isDataChanged &&
			((!ref.current?.contains(event.target) &&
				!id?.contains(event.target) &&
				!(class1.length > 0 && class1[0].contains(event.target))) ||
				id2?.contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsDataChanged(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				// resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	useEffect(() => {
		//notification redirection for conversation
		if (redirectedPerformanceGoalDetails && performanceGoalsList) {
			const { tabId, cycleId, employeeId, categoryId, conversationId, conversationsReplyId } =
				redirectedPerformanceGoalDetails || {};
			if (categoryId) {
				const performanceGoalsCategory = performanceGoalsList?.goalListingFormCategories?.find(
					(item: any) => item.performanceGoalLinkedFormCategoryId === categoryId
				);
				if (performanceGoalsCategory) {
					handleSettingsDrawerOpen(null, performanceGoalsCategory, 'conversation');
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redirectedPerformanceGoalDetails, performanceGoalsList]);

	const handleExpandGoals = (e: any, goals: any) => {
		let list = Object.assign([], expandedGoalList);
		const elementIndex = list.indexOf(goals.performanceGoalLinkedFormCategoryId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(goals.performanceGoalLinkedFormCategoryId);
		}
		setExpandedGoalList(list);
	};
	const handleSettingsDrawerOpen = (event: any, performanceGoalSetListingFormDetails: any, tabToOpen?: string) => {
		setCategorySettingsOpen({ open: true, data: performanceGoalSetListingFormDetails });
		dispatch(setPerfomanceGoalAttachmentCount(0));
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};

	const handleSettingsDrawerClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setCategorySettingsOpen({ open: false, data: '' });
		if (currentTab === 'conversation') {
			getUserPerformanceGoalsList();
			dispatch(getGoalAlertConversation());
		}
	};

	const handleGoalSettingsDrawerOpen = (
		event: any,
		performanceGoalSetListingFormDetails: any,
		tabToOpen?: string,
		goalList?: any
	) => {
		setGoalSettingsOpen({ open: true, data: { ...performanceGoalSetListingFormDetails, goalList: goalList } });
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};
	const handleGoalSettingsDrawerClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setGoalSettingsOpen({ open: false, data: '' });
		//getUserPerformanceGoalsList();
	};
	const getUserPerformanceGoalsList = async () => {
		try {
			setLoader(true);
			let performanceGoalCycleId =
				currentGoalReviewCycle && currentGoalReviewCycle.performanceGoalReviewCycleId
					? currentGoalReviewCycle.performanceGoalReviewCycleId
					: 0;
			let requestParam: any = `performanceGoalCycleReviewId=${performanceGoalCycleId}&employeeId=${drUserData?.employeeId}`;
			let requestParamManager: any = `performanceGoalCycleReviewId=${performanceGoalCycleId}&employeeId=${drUserData?.employeeId}&isManagerView=true&managerId=${loggedInUserDetail.employeeId}`;

			const response: any = await dispatch(getSelfReviewList(fromTeamDetails ? requestParamManager : requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setPerformanceGoalsList(response?.data.entity);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {}
	};

	const handleGoalDetailsChange = (key: string, catIndex: number, goalIndex: number, value: any) => {
		let goalCopy = { ...performanceGoalsList };
		let oldValue: any =
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex][key];
		if (key === 'comment' || key === 'comment') {
			if (oldValue !== value) {
				setIsDataChanged(true);
			}
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isCommentError'] =
				false;
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isQuestionError'] =
				false;
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isCommentChanged'] =
				true;
		}
		if (key === 'score' || key === 'score') {
			if (oldValue !== value) {
				setIsDataChanged(true);
			}
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isRatingError'] =
				false;
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isQuestionError'] =
				false;
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isRatingChanged'] =
				true;
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isError'] = false;
			goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['openComments'] =
				true;
			setTooltipId(catIndex + '' + goalIndex);
			setTimeout(function () {
				setTooltipId('');
			}, 5000);
		}
		goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex][key] = value;
		goalCopy.goalListingFormCategories[catIndex].performanceGoalSetListingFormDetails[goalIndex]['isError'] = false;

		setPerformanceGoalsList(goalCopy);
	};
	const showSavePopup = (isDraft = false, status: number) => {
		let formData: any = status !== 3 ? validateForm(isDraft) : {};
		if (!formData.formError) {
			setOpenDeleteDialog({ open: true, isDraft: isDraft, status: status });
		}
	};

	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			saveUserGoalClick(openDeleteDialog.isDraft, openDeleteDialog.status);
		}
		if (type === 1 && openDeleteDialog.status === 3) {
			//clearForm();
		}
		setOpenDeleteDialog({ open: false, isDraft: false, status: 0 });
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.scrollHeight;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				//window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};
	const validateForm = (isDraft = false, status = 0) => {
		let postGoalData: any[] = [];
		let formError: boolean = false;
		let expandListCopy = [...expandedGoalList];
		let goalCopy = { ...performanceGoalsList };
		let scrollToId = '';
		goalCopy.goalListingFormCategories.map((catData: any) => {
			catData.performanceGoalSetListingFormDetails.map((goalData: any) => {
				if (
					!goalData.comment ||
					goalData.comment.trim() === '' ||
					goalData.comment.trim() === '<p></p>' ||
					goalData.comment.trim() === '<p><br></p>'
				) {
					if (!isDraft) {
						goalData.isError = true;
						formError = true;
						const elementIndex = expandListCopy.indexOf(catData.performanceGoalLinkedFormCategoryId);
						if (elementIndex === -1) {
							expandListCopy.push(catData.performanceGoalLinkedFormCategoryId);
						}
						if (scrollToId === '') {
							scrollToId = 'performanceGoalLinkedFormDetailId' + goalData.performanceGoalLinkedFormDetailId;
						}
						goalData.isCommentError = true;
						goalData.isQuestionError = true;
					}
				}
				if (!isDraft && !goalData.score) {
					goalData.isRatingError = true;
					goalData.isQuestionError = true;
					goalData.isError = true;
					formError = true;
				}
				postGoalData.push({
					performanceGoalLinkedFormDetailId: goalData.performanceGoalLinkedFormDetailId,
					performanceGoalSetId: goalData.performanceGoalSetId,
					performanceGoalSetDetailId: goalData.performanceGoalSetDetailId,
					performanceGoalReviewWorkFlowTypeId: catData.performanceGoalLinkedFormCategoryId,
					score: goalData.score ? goalData.score : 0,
					comment: String(goalData.comment),
					description: String(goalData.description),
					status: status,
					isDescriptionChanged: goalData.isDescriptionChanged || false,
					descriptionDate: goalData.descriptionDate ? formatFeedbackDate(new Date(goalData.descriptionDate)) : null,
				});
			});
		});
		if (scrollToId !== '') {
			scrollTo(scrollToId);
		}
		setExpandedGoalList(expandListCopy);
		setPerformanceGoalsList(goalCopy);
		return { formError, postGoalData };
	};
	const saveUserGoalClick = async (isDraft = false, status: number) => {
		try {
			let formData: any = status !== 3 ? validateForm(isDraft, status) : null;
			//console.log('formData', formData);return false;
			let postGoalData: any[] = formData?.postGoalData ? formData?.postGoalData : [];
			let formError: boolean = formData?.formError ? formData?.formError : false;
			const requestParams = {
				performanceGoalRatingAssessmentId: 0,
				performanceGoalReviewCycleId: currentGoalReviewCycle?.performanceGoalReviewCycleId,
				employeeId: fromTeamDetails ? drUserData?.employeeId : userDetails?.employeeId,
				performanceGoalSetId: performanceGoalsList.performanceGoalSetId,
				performanceGoalReviewWorkFlowTypeId: status === 3 ? 1 : fromTeamDetails ? 2 : 1,
				status: status === 3 ? status : isDraft ? 1 : status,
				performanceGoalRatingAssessmentDetailRequest: postGoalData,
				reportingTo: fromTeamDetails ? loggedInUserDetail.employeeId : 0,
			};
			if (!formError || isDraft || status === 3) {
				const response: any = await dispatch(saveGoalReviewSavedApi(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					if (status === 3) {
						dispatch(setPerformanceReviewDrUserData(null));
					} else {
						setPerformanceGoalsList(response?.data.entity);
						getUserPerformanceGoalsList();
					}
					if (requestParams?.status === 2) {
						handleNext && handleNext();
					}
					enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
						variant: 'success',
						autoHideDuration: 3000,
					});
					window.scroll({ top: 0, behavior: 'smooth' });
				} else {
					window.scroll({ top: 0, behavior: 'smooth' });
				}
				setIsDataChanged(false);
			}
		} catch (error) {}
	};

	const getLabelText = (value: number, labels: any) => {
		return `${labels[value - 1]?.name || ''}`;
	};
	const handleChangeStarQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			handleGoalDetailsChange('score', sectionIndex, questionIndex, value);
			setIsDataChanged(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleChangeStarQuestionTextValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			handleGoalDetailsChange('comment', sectionIndex, questionIndex, value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeStarQuestionHoverValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			/*updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'starRatingHoverValue',
				value
			);*/
		} catch (error) {
			console.error(error);
		}
	};
	const clearForm = () => {
		let goalCopy = { ...performanceGoalsList };
		goalCopy.goalListingFormCategories.map((catData: any) => {
			catData.performanceGoalSetListingFormDetails.map((goalData: any) => {
				goalData.comment = '';
				delete goalData.rating;
				delete goalData.score;
				goalData.isCommentError = false;
				goalData.isQuestionError = false;
				goalData.isError = false;
			});
		});
		setPerformanceGoalsList(goalCopy);
		setIsDataChanged(false);
	};
	return (
		<>
			<div id='createLinkPopupDetail' ref={ref}>
				<Box className='user-goals-section-content'>
					{performanceGoalsList?.goalListingFormCategories &&
					performanceGoalsList?.goalListingFormCategories?.length ? (
						performanceGoalsList?.goalListingFormCategories?.map((item: any, catIndex: number) => (
							<Box className='user-goals-section-list-panel'>
								<Box className='user-goals-section-list-panel-inner'>
									<Box className='user-goals-section-name-panel'>
										<List disablePadding>
											<ListItem className='user-goals-col1'>
												<Box className='user-goal-title'>
													<Tooltip arrow title={item.categoryName} classes={{ popper: 'tooltip-layout-auto' }}>
														<Typography variant='h4'>{item.categoryName}</Typography>
													</Tooltip>
												</Box>
												<Box className='user-goals-section-actions'>
													<OKRButton
														className={`btn-blue-chip ${
															expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1
																? t('expendLabel').toLowerCase()
																: t('collapseLabel').toLowerCase()
														}`}
														icon={<RightArrowIcon />}
														text={`${item?.performanceGoalSetListingFormDetails?.length} ${t('goalsLabel')}`}
														id={`goals-toggle-btn`}
														handleClick={(e) => handleExpandGoals(e, item)}
													/>
													<OKRButton
														title={t('categoryWeightage')}
														className='btn-oval-shape'
														text={`${item.weightage}%`}
													/>
													<Box
														className={`user-goals-sec-hvr-actions ${
															expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1
																? 'hover-active'
																: ''
														}`}
													>
														<OKRButton
															className={`attachments-btn`}
															icon={<AttachmentIcon />}
															title={`${t('attachmentsLabel')}`}
															handleClick={(event: any) => handleSettingsDrawerOpen(event, item, 'attachments')}
														/>
														<OKRButton
															className={`conversation-btn`}
															wrapClassName={'conversation-btn-wrap'}
															icon={
																<Badge
																	variant='dot'
																	className='dot-bubble'
																	overlap='circular'
																	invisible={!item.isConversationRedDotAlert ? true : false}
																>
																	<ConversationIcon />
																</Badge>
															}
															title={`${t('conversationLabel')} ${
																item.isConversationRedDotAlert ? `(${item.conversationRedDotAlert})` : ''
															}`}
															handleClick={(event: any) => handleSettingsDrawerOpen(event, item, 'conversation')}
														/>
													</Box>
												</Box>
											</ListItem>
										</List>
									</Box>
									{item?.performanceGoalSetListingFormDetails && item?.performanceGoalSetListingFormDetails.length ? (
										item?.performanceGoalSetListingFormDetails?.map((goalList: any, goalIndex: number) => (
											<Collapse
												in={expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1}
												className='user-goals-list-panel'
											>
												<Box
													className='user-goals-list-panel-inner'
													id={`${'performanceGoalLinkedFormDetailId' + goalList.performanceGoalLinkedFormDetailId}`}
												>
													<Box className='user-goal-name'>
														<Tooltip arrow title={goalList.name} classes={{ popper: 'tooltip-layout-auto' }}>
															<Typography variant='h4' className='font-weight-normal'>
																{goalList.name}
															</Typography>
														</Tooltip>
													</Box>
													{goalList?.description && goalList?.questionType === 2 ? (
														<Box className='user-goal-description-saved'>
															<Box
																dangerouslySetInnerHTML={{
																	__html: goalList.description || '',
																}}
															></Box>
														</Box>
													) : goalList?.description && goalList?.questionType === 3 ? (
														<Box className='user-goal-num-date-saved'>
															<Typography>{goalList?.description}</Typography>
														</Box>
													) : goalList?.questionType === 4 ? (
														<Box className={`${goalList.descriptionDate ? 'user-goal-num-date-saved' : ''}`}>
															{goalList.descriptionDate ? (
																<Typography>{getMonthDateYearTime(new Date(goalList.descriptionDate))}</Typography>
															) : (
																<></>
															)}
														</Box>
													) : goalList?.questionType === 5 ? (
														<Box
															className={`${
																goalList?.description || goalList.descriptionDate ? 'user-goal-num-date-saved' : ''
															}`}
														>
															<Typography>{goalList?.description}</Typography>
															{goalList.descriptionDate ? (
																<Typography>{getMonthDateYearTime(new Date(goalList.descriptionDate))}</Typography>
															) : (
																<></>
															)}
														</Box>
													) : (
														<></>
													)}

													<Box className='user-goals-actions'>
														<OKRButton
															title={t('goalWeightage')}
															className='btn-oval-shape'
															text={`${goalList.weightage}%`}
														/>
														<Box className='user-goals-hvr-actions'>
															{/* {catIndex + '' + goalIndex === tooltipId ? (
																		<Tooltip title={'highlightHere'} arrow>
																			<Box>
																				I am here
																				<OKRButton
																					className={`attachments-btn`}
																					icon={<AttachmentIcon />}
																					title={`${t('attachmentsLabel')}`}
																					handleClick={(event: any) =>
																						handleGoalSettingsDrawerOpen(event, item, 'attachments', goalList)
																					}
																				/>
																			</Box>
																		</Tooltip>
																	) : ( */}
															<OKRButton
																className={`attachments-btn`}
																icon={<AttachmentIcon />}
																title={`${t('attachmentsLabel')}`}
																handleClick={(event: any) =>
																	handleGoalSettingsDrawerOpen(event, item, 'attachments', goalList)
																}
															/>
															{/* )} */}
															<OKRButton
																className={`conversation-btn`}
																icon={<ResponseLogIcon />}
																title={t('responseLog')}
																handleClick={(event: any) =>
																	handleGoalSettingsDrawerOpen(event, item, 'conversation', goalList)
																}
															/>
														</Box>
													</Box>

													{expandedGoalList.indexOf(item.performanceGoalLinkedFormCategoryId) !== -1 && (
														<Box className='goal-review-rating-area'>
															<StarRating
																t={t}
																performanceGoalsList={performanceGoalsList}
																questionIndex={goalIndex}
																sectionIndex={catIndex}
																question={goalList}
																getLabelText={getLabelText}
																section={item}
																isCommentError={goalList?.isCommentError || goalList.isQuestionError}
																handleChangeStarQuestionValue={(
																	event: any,
																	value: any,
																	question: any,
																	questionIndex: number,
																	section: any,
																	sectionIndex: number
																) =>
																	handleChangeStarQuestionValue(
																		event,
																		value,
																		question,
																		questionIndex,
																		section,
																		sectionIndex
																	)
																}
																handleChangeStarQuestionTextValue={(
																	event: any,
																	value: any,
																	question: any,
																	questionIndex: number,
																	section: any,
																	sectionIndex: number
																) =>
																	handleChangeStarQuestionTextValue(
																		event,
																		value,
																		question,
																		questionIndex,
																		section,
																		sectionIndex
																	)
																}
																handleChangeStarQuestionHoverValue={(
																	event: any,
																	value: any,
																	question: any,
																	questionIndex: number,
																	section: any,
																	sectionIndex: number
																) => console.log(event, value, question, questionIndex, section, sectionIndex)}
															/>
														</Box>
													)}
												</Box>
											</Collapse>
										))
									) : (
										<></>
									)}
								</Box>
							</Box>
						))
					) : (
						<></>
					)}
				</Box>
				{performanceGoalsList?.isEditable && performanceGoalsList?.goalListingFormCategories?.length > 0 ? (
					<Box className='performance-goals-action'>
						<Box>
							{!isDrSelfReviewData && (performanceGoalsList?.status === 0 || performanceGoalsList?.status === 1) && (
								<OKRButton
									className={'btn-link'}
									disabled={!isDataChanged}
									id='pip_create_submit_form'
									text={t('clearForm')}
									handleClick={() => {
										showSavePopup(false, 3);
									}}
								/>
							)}
						</Box>
						<Box>
							{(performanceGoalsList?.status === 0 || performanceGoalsList?.status === 1) && (
								<>
									<OKRButton
										className={'btn-link'}
										id='pip_create_saveAsDraft_form'
										text={t('saveDraft')}
										handleClick={() => {
											saveUserGoalClick(true, 1);
										}}
									/>
								</>
							)}
							{!isDrSelfReviewData && performanceGoalsList?.status === 0 && performanceGoalsList?.status === 1 ? (
								<>
									{
										<OKRButton
											className={'btn-link'}
											id='pip_create_submit_form'
											text={t('reconsiderLabel')}
											handleClick={() => {
												showSavePopup(false, 3);
											}}
										/>
									}
									<OKRButton
										className={'btn-primary'}
										id='pip_create_submit_form'
										text={t('submitnNext')}
										handleClick={() => {
											showSavePopup(false, 2);
											//handleNext && handleNext();
										}}
									/>
								</>
							) : (
								<OKRButton
									className={'btn-primary'}
									id='pip_create_submit_form'
									text={t('submit')}
									handleClick={() => {
										showSavePopup(false, 2);
									}}
								/>
							)}
						</Box>
					</Box>
				) : (
					<></>
				)}
				{categorySettingsOpen.open && (
					<CategorySettings
						{...props}
						t={t}
						categorySettingsOpen={categorySettingsOpen}
						handleSettingsDrawerClose={handleSettingsDrawerClose}
						selectTab={currentTab}
						performanceGoalsList={performanceGoalsList}
					/>
				)}
				{goalSettingsOpen.open && (
					<GoalSettings
						{...props}
						t={t}
						goalSettingsOpen={goalSettingsOpen}
						handleGoalSettingsDrawerClose={handleGoalSettingsDrawerClose}
						selectTab={currentTab}
						performanceGoalsList={performanceGoalsList}
					/>
				)}
			</div>

			{openDeleteDialog.open && (
				<AlertDialog
					module='information'
					message={
						openDeleteDialog.status === 3
							? t('clearSelfReview')
							: openDeleteDialog.status === 2
							? t('selfReviewSubmit')
							: t('selfReviewSubmit')
					}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
