import React, { useState, useEffect, useRef } from 'react';
import { UserAssessmentHeader } from '../UserAssessmentHeader';
import { UserAssessmentForm } from './UserAssessmentForm';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionTypeMasterData } from '../../../action/adminSettings';
import AlertDialog from '../../Common/Dialog';
import { useTranslation } from 'react-i18next';
import {
	getAssessmentVisibilityApi,
	getIsUserExluded,
	getMultipleAssessment,
	updateAssessmentAlert,
} from '../../../action/perform';
import { checkUnSavePopUpVisible, getUserDetails } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { saveAssessmentForm } from '../../../action/UserPerformance';
import { useSnackbar } from 'notistack';
import { globalSearchAPIWithTeam } from '../../../action/search';
import { Box, Typography } from '@material-ui/core';
import assessmentYetToBegin from '../../../images/assessment-yet-to-begin.svg';
import { MultipleThreeSixtyAssessmentDetails } from '../UserThreeSixtyAssessment/MultipleThreeSixtyAssessmentDetails';
import { FinalRatingAssessmentForm } from '../FinalRatingAssessment/FinalRatingAssessmentForm';
import { NoPerformanceRecord } from '../NoPerformanceRecord';
import { getOkrMasterData } from '../../../action/myGoal';

export const UserSelfAssessment = (props: any) => {
	const { performanceCycle, getSelfAssessmentDetails, loader, setLoader, handleOneOnOneClick } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const userDetails = getUserDetails();
	const dispatch = useDispatch();
	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	const ref2 = useRef<any>(null);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { questionTypeMasterData } = useSelector((state: any) => state?.adminSettingReducer);
	const { multipleAssessmentResponse, employeeAssessmentCycleListStatus } = useSelector(
		(state: any) => state.performReducer
	);
	const {
		// employeeAssessmentResponse,
		// employeeAssessmentResponseStatus,
		selfAssessmentResponse,
		// selfAssessmentResponseStatus,
	} = useSelector((state: any) => state?.userPerformanceReducer);
	const [selfAssessmentVisibilityData, setSelfAssessmentVisibilityData] = useState<any>(null);

	// console.log('SELF ===>', selfAssessmentResponse, selfAssessmentResponseStatus);
	// console.log('EMPLOYEE ===> ', employeeAssessmentResponse, employeeAssessmentResponseStatus);

	const [assessmentFormDetails, setAssessmentFormDetails] = useState<any>(null);
	const [fullAssessmentFormDetails, setFullAssessmentFormDetails] = useState<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [userExluded, setUserExluded] = React.useState<any>(null);
	const [excludedLoaded, setExcludedLoaded] = React.useState<any>(false);
	const [showTeamAssessment, setShowTeamAssessment] = useState<number>(1);
	const [performanceRatings, setPerformanceRatings] = useState<any>([]);
	const [threeSixtyBackClicked, setThreeSixtyBackClicked] = useState<boolean>(false);

	useEffect(() => {
		setExcludedLoaded(false);
		if (performanceCycle?.performanceCycleId) {
			getAssessmentVisibilitySetting();
			checkIsUserExcluded();
		}
	}, [performanceCycle]);
	const [requestOneOnOneDrawer, setRequestOneOnOneDrawer] = useState<any>({ open: false, type: '' });

	useEffect(() => {
		if (selfAssessmentResponse?.performanceRoleLinkedFormSection) {
			setLoader(false);
			setAssessmentFormDetails(addQuestionNumber(selfAssessmentResponse?.performanceRoleLinkedFormSection));
		}
		if (selfAssessmentResponse) {
			setFullAssessmentFormDetails(selfAssessmentResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selfAssessmentResponse]);
	useEffect(() => {
		if (!questionTypeMasterData) {
			dispatch(getQuestionTypeMasterData());
		}
		if (!Boolean(okrMasterData)) {
			dispatch(getOkrMasterData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (okrMasterData?.performanceRatings) {
			setPerformanceRatings(okrMasterData.performanceRatings);
		}
	}, [okrMasterData]);
	useEffect(() => {
		if (performanceCycle?.performanceCycleId) {
			setLoader(true);
			// { SelfAssessment = 1, ManagerAssessment = 2, ThreeSixtyAssessment = 3 }
			getSelfAssessmentDetails(
				userDetails?.employeeId || Enums.ZERO,
				performanceCycle?.performanceCycleId || Enums.ZERO,
				Enums.ONE
			);
			setLoader(false);
			// getEmployeeAssessmentDetails(
			// 	userDetails?.employeeId || Enums.ZERO,
			// 	performanceCycle?.performanceCycleId || Enums.ZERO,
			// 	Enums.ONE
			// );
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [performanceCycle]);

	const addQuestionNumber = (details: any) => {
		let quesNumber = 0;
		const updatedDetails =
			details && details?.length
				? [...details].map((section: any) => {
						if (section?.performanceRoleLinkedFormDetails && section?.performanceRoleLinkedFormDetails?.length) {
							section.performanceRoleLinkedFormDetails = section?.performanceRoleLinkedFormDetails.map(
								(question: any) => {
									quesNumber = quesNumber + 1;
									question.questionNumber = quesNumber;

									return question;
								}
							);
						}
						return section;
				  })
				: details;
		return updatedDetails;
	};
	const getAssessmentVisibilitySetting = async () => {
		const response: any = await dispatch(
			getAssessmentVisibilityApi(`performanceCycleId=${performanceCycle?.performanceCycleId}&assessmentId=1`)
		);
		if (response && response.status === 200) {
			setSelfAssessmentVisibilityData(response.data.entity);
		} else {
			//	setListLoader(false);
		}
	};
	// ======= HANDLING UN_SAVE CHANGES WITH BELOW CODE =========== //
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);

	const handleClickOutside = (event: any) => {
		if (
			ref.current &&
			(ref1.current?.contains(event.target) ||
				ref2.current?.contains(event.target) ||
				!ref.current?.contains(event.target)) &&
			checkUnSavePopUpVisible(event) &&
			isFormEdited
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('unSavedItemAlert') });
			}
		} else {
			//
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'ClearForm') {
				if (selfAssessmentResponse?.performanceRoleLinkedFormSection) {
					setAssessmentFormDetails(addQuestionNumber(selfAssessmentResponse?.performanceRoleLinkedFormSection));
					setIsFormEdited(false);
				}
			} else if (modalProps?.type === 'UnSaveChanges') {
				if (modalProps?.event) {
					setAssessmentFormDetails(addQuestionNumber(selfAssessmentResponse?.performanceRoleLinkedFormSection));
					setIsFormEdited(false);
					const { target } = modalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
			} else if (modalProps?.type === 'CompletedAlert') {
				setLoader(true);
				handleSaveAssessmentAPICall(Enums.TWO, Enums.ONE);
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	const handleSaveAssessmentAPICall = async (status: any, performanceType = Enums.ONE) => {
		try {
			const requestPayload = {
				performanceCycleId: performanceCycle?.performanceCycleId || Enums.ZERO,
				ratingAssessmentId: selfAssessmentResponse?.ratingAssessmentId || Enums.ZERO,
				employeeId: userDetails?.employeeId || Enums.ZERO,
				reportingTo: userDetails?.reportingTo || Enums.ZERO,
				ratingAssessmentType: selfAssessmentResponse?.ratingAssessmentType || Enums.ZERO,
				status: status || Enums.ZERO,
				performanceRoleLinkedFormSection: [...assessmentFormDetails],
			};
			const response: any = await dispatch(saveAssessmentForm(requestPayload));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				getAssessmentVisibilitySetting();
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(
					messages ? messages : status === Enums.TWO ? 'Successfully saved in drafts.' : `Successfully saved.`,
					{
						variant: 'success',
						autoHideDuration: 3000,
					}
				);
				window.scrollTo(0, 0);
				if (status === Enums.TWO) {
					dispatch(updateAssessmentAlert(false));
				}
				if (performanceCycle?.performanceCycleId) {
					setLoader(true);
					getSelfAssessmentDetails(
						userDetails?.employeeId || Enums.ZERO,
						performanceCycle?.performanceCycleId || Enums.ZERO,
						performanceType || Enums.ONE
					);
				}
				setIsFormEdited(false);
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating assessment form.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleClearAssessmentForm = (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (isFormEdited) {
				setModalProps({
					open: true,
					event: null,
					type: 'ClearForm',
					message: t('clearAssessmentForm'),
				});
			} else {
				if (selfAssessmentResponse?.performanceRoleLinkedFormSection) {
					setAssessmentFormDetails(addQuestionNumber(selfAssessmentResponse?.performanceRoleLinkedFormSection));
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleSaveAsDraftAssessmentForm = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				handleSaveAssessmentAPICall(Enums.ONE, Enums.ONE);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleSubmitAssessmentForm = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (checkAssessmentFormValidation()) {
				if (assessmentFormDetails && assessmentFormDetails?.length) {
					if (okrMasterData?.performanceSubmissionMessages[0]?.constantDescription) {
						setModalProps({
							open: true,
							event: null,
							type: 'CompletedAlert',
							message: okrMasterData?.performanceSubmissionMessages[0]?.constantDescription,
						});
					} else {
						setLoader(true);
						handleSaveAssessmentAPICall(Enums.TWO, Enums.ONE);
					}
				} else {
					setLoader(false);
				}
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const checkAssessmentFormValidation = () => {
		let isFormValidated = true;
		let firstErrorQuestion = 0;
		let firstErrorQuestionComment = 0;
		if (assessmentFormDetails && assessmentFormDetails?.length) {
			let updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
				const data = { ...item };
				return data;
			});
			updatedAssessmentFormDetails = [...updatedAssessmentFormDetails].map((section: any) => {
				const sectionData = { ...section };
				if (sectionData?.performanceRoleLinkedFormDetails && sectionData?.performanceRoleLinkedFormDetails?.length) {
					sectionData.performanceRoleLinkedFormDetails = sectionData.performanceRoleLinkedFormDetails.map(
						(ques: any) => {
							const quesData = { ...ques };
							if (
								quesData?.isQuestionsMandatory &&
								(!quesData?.ratingValue ||
									(quesData?.ratingValue &&
										typeof quesData.ratingValue === 'string' &&
										quesData.ratingValue.trim() === ''))
							) {
								quesData.isQuestionError = true;
								quesData.isQuestionErrorMsg = 'This question is mandatory to answer';
								firstErrorQuestion = ++firstErrorQuestion;
								isFormValidated = false;
								if (firstErrorQuestion === 1 && firstErrorQuestionComment === 0) {
									scrollTo(
										`section_question_index_value_${sectionData?.performanceRoleLinkedFormSectionId}_${quesData?.performanceRoleLinkedFormDetailsId}_${quesData?.ratingAssessmentId}`
									);
								}
							}

							if (quesData?.isCommentBoxAvailable && quesData?.isCommentBoxMandatory) {
								if (
									!quesData?.questionCommentBox ||
									(quesData?.questionCommentBox && quesData.questionCommentBox.trim() === '')
								) {
									quesData.isCommentExpand = true;
									quesData.isQuestionCommentError = true;
									quesData.isQuestionCommentErrorMsg = 'This question comment is mandatory to answer';
									firstErrorQuestionComment = ++firstErrorQuestionComment;
									isFormValidated = false;

									if (firstErrorQuestionComment === 1 && firstErrorQuestion === 0) {
										scrollTo(
											`section_question_index_value_${sectionData?.performanceRoleLinkedFormSectionId}_${quesData?.performanceRoleLinkedFormDetailsId}_${quesData?.ratingAssessmentId}`
										);
									}
								}
							}

							return quesData;
						}
					);
				}

				return sectionData;
			});

			setAssessmentFormDetails([...updatedAssessmentFormDetails]);
		}
		return isFormValidated;
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 180 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 100);
	};
	// ==========================================================
	const handleChangeStarQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeStarQuestionHoverValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'starRatingHoverValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeScaleQuestionValue = (
		event: any,
		valueIndex: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		isCommitted: boolean
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value, isCommitted);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeMultiChoiceQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeQuestionComment = (
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'questionCommentBox', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeShortQuestionValue = (
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleCommentsToggle = (
		e: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'isCommentExpand', value);
		} catch (error) {
			console.error(error);
		}
	};
	const updateAssessmentFormDetails = (
		sectionIndex: number,
		questionIndex: number,
		keyName: string,
		value: any,
		scaleCommittedFlag = false
		// keyName1?: string,
		// value1?: any
	) => {
		try {
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				const updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
					const data = { ...item };
					return data;
				});
				updatedAssessmentFormDetails[sectionIndex].performanceRoleLinkedFormDetails = updatedAssessmentFormDetails[
					sectionIndex
				].performanceRoleLinkedFormDetails?.length
					? updatedAssessmentFormDetails[sectionIndex].performanceRoleLinkedFormDetails?.map(
							(ques: any, quesIndex: number) => {
								const quesData = { ...ques };
								if (quesIndex === questionIndex && keyName) {
									if (keyName === 'isCommentExpand') {
										quesData[keyName] = !quesData?.isCommentExpand;
									} else {
										if (keyName !== 'starRatingHoverValue' || scaleCommittedFlag) {
											quesData.isQuestionError = false;
											quesData.isQuestionErrorMsg = '';
										}
										if (keyName === 'questionCommentBox') {
											quesData.isQuestionCommentError = false;
											quesData.isQuestionCommentErrorMsg = '';
										}
										if (keyName === 'ratingValue') {
											quesData[keyName] = value ? String(value) : '';
											if (quesData?.isCommentBoxMandatory && !quesData?.questionCommentBox) {
												quesData.isCommentExpand = true;
											}
										} else {
											quesData[keyName] = value;
										}
									}
									// if (keyName1) {
									// 	quesData[keyName1] = value1;
									// }
								}
								return quesData;
							}
					  )
					: [];
				setAssessmentFormDetails([...updatedAssessmentFormDetails]);
				// console.log(keyName);
				if (keyName !== 'starRatingHoverValue') {
					setIsFormEdited(true);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const checkIsUserExcluded = async () => {
		setExcludedLoaded(false);
		let data = `performanceCycleId=${performanceCycle?.performanceCycleId}&employeeId=${userDetails?.employeeId}`;
		let response: any = await dispatch(getIsUserExluded(data));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			setExcludedLoaded(true);
			setUserExluded(response.data.entity);
		}
	};
	const handleThreeSixtyBoxClick = () => {
		try {
			setThreeSixtyBackClicked(false);
			setShowTeamAssessment(3);
			const data = {
				performanceCycleId: performanceCycle?.performanceCycleId || Enums.ZERO,
				employeeIds: [],
				nomineeForEmployeeId: userDetails?.employeeId || Enums.ZERO,
				performanceRoleId: selfAssessmentVisibilityData?.performanceRoleId || Enums.ZERO,
				ratingAssessmentType: Enums.THREE,
			};
			dispatch(getMultipleAssessment(data));
		} catch (error) {
			console.error(error);
		}
	};
	const backClicked = () => {
		setThreeSixtyBackClicked(true);
		setShowTeamAssessment(1);
	};
	return (
		<>
			{(excludedLoaded && userExluded) || (!performanceCycle && employeeAssessmentCycleListStatus === 'success') ? (
				<>
					<Box className='assessment-no-record'>
						<Box className='no-record-message'>
							<Typography variant='h2'>{t('assessmentYetToBegin1')}</Typography>
							<Typography>{t('assessmentYetToBegin2')}</Typography>
							<img width='470' alt={t('assessmentYetToBegin1')} src={assessmentYetToBegin} />
						</Box>
					</Box>
				</>
			) : (
				excludedLoaded && (
					<>
						<UserAssessmentHeader
							ref1={ref1}
							ref2={ref2}
							handleOneOnOneClick={handleOneOnOneClick}
							userDetails={userDetails}
							performanceCycle={performanceCycle}
							fromTeamAssessment={false}
							employeeId={Enums.ZERO}
							selfAssessmentVisibilityData={selfAssessmentVisibilityData}
							setSelfAssessmentVisibilityData={setSelfAssessmentVisibilityData}
							getSelfAssessmentDetails={getSelfAssessmentDetails}
							setLoader={setLoader}
							handleThreeSixtyBoxClick={handleThreeSixtyBoxClick}
							setShowTeamAssessment={setShowTeamAssessment}
							threeSixtyBackClicked={threeSixtyBackClicked}
							setThreeSixtyBackClicked={setThreeSixtyBackClicked}
						/>
						<div ref={ref}>
							{showTeamAssessment === 3 ? (
								<>
									<MultipleThreeSixtyAssessmentDetails
										callingType={'ThreeSixtyBulkAssessmentFromUser'}
										performanceCycle={performanceCycle}
										loader={loader}
										setLoader={setLoader}
										backClicked={backClicked}
										employeeId={userDetails?.employeeId}
										selectedUserAssessment={userDetails}
										handleBack={() => {
											backClicked();
										}}
										userDetail={userDetails}
										multipleAssessmentResponse={multipleAssessmentResponse}
										performanceCycleId={performanceCycle?.performanceCycleId}
										getTeamAssessList={() => {}}
										performanceRoleId={0}
										performanceRatings={performanceRatings}
										handleOneOnOneClick={handleOneOnOneClick}
										handleSnapshot={() => {}}
										getSelfAssessmentDetails={getSelfAssessmentDetails}
										isViewMode={true}
										isBackHide={true}
									/>
								</>
							) : showTeamAssessment === 5 ? (
								<FinalRatingAssessmentForm
									{...props}
									refComp={ref}
									setIsFormEdited={setIsFormEdited}
									selectedUserAssessment={{
										...selfAssessmentVisibilityData.finalRatingAssessment,
										finalRating: selfAssessmentVisibilityData.finalRatingAssessment.finalRatingLabel,
									}}
									backClicked={backClicked}
								/>
							) : !selfAssessmentVisibilityData?.selfAssessment?.isSelfTileDisplay &&
							  !selfAssessmentVisibilityData?.managerAssessment?.isManagerScoreVisible ? (
								<>
									<NoPerformanceRecord
										heading={t("Manager's assessment details will be seen here.")}
										subHeading={t('Your score will be visible based on organization settings.')}
										imgAlt={t("Manager's assessment details will be seen here.")}
									/>
								</>
							) : (
								<UserAssessmentForm
									callingFrom={'MyAssessment'}
									isViewMode={
										fullAssessmentFormDetails?.status === Enums.TWO ||
										!selfAssessmentVisibilityData?.selfAssessment?.isSelfAssessmentPeriodOpen
									}
									performanceCycle={performanceCycle}
									questionTypeMasterData={questionTypeMasterData}
									assessmentFormDetails={assessmentFormDetails}
									defaultAssessmentFormDetails={selfAssessmentResponse?.performanceRoleLinkedFormSection}
									handleClearAssessmentForm={handleClearAssessmentForm}
									handleSaveAsDraftAssessmentForm={handleSaveAsDraftAssessmentForm}
									handleSubmitAssessmentForm={handleSubmitAssessmentForm}
									handleChangeQuestionComment={handleChangeQuestionComment}
									handleChangeShortQuestionValue={handleChangeShortQuestionValue}
									handleChangeStarQuestionValue={handleChangeStarQuestionValue}
									handleChangeStarQuestionHoverValue={handleChangeStarQuestionHoverValue}
									handleChangeScaleQuestionValue={handleChangeScaleQuestionValue}
									handleChangeMultiChoiceQuestionValue={handleChangeMultiChoiceQuestionValue}
									handleCommentsToggle={handleCommentsToggle}
									loader={loader}
									isFormEdited={isFormEdited}
									handleOneOnOneClick={handleOneOnOneClick}
									selfAssessmentVisibilityData={selfAssessmentVisibilityData}
								/>
							)}
						</div>
						{modalProps.open && (
							<AlertDialog
								message={modalProps?.message || ''}
								handleCloseModal={handleCloseModal}
								modalOpen={modalProps.open}
							/>
						)}
					</>
				)
			)}
		</>
	);
};
