import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import LinearLoader from '../../../../Common/Loader';
import { AlertInfoIcon } from '../../../../../config/svg/CommonSvg';
import { saveFeedbackPermission } from '../../../../../action/adminSettings';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../../../config/enums';
import { PermissionBox } from '../PermissionBox';

export const GoalSettingsPermissions: React.FC<any> = (props: any) => {
	const { formPermissionData, loader, setLoader, isFormEdited, setIsFormEdited, tabSelected, getData } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [formErrors, setFormErrors] = useState<any>({});
	const [focussed, setFocussed] = React.useState<boolean>(false);
	const permissionDefault = { grantPermissionToId: 0, grantPermissionToCategory: 0 };
	const accessDefault = { accessOnId: 0, accessOnCategory: 0 };
	const defaultData = {
		additionalPermissionId: 0,
		isGrantPermissionToEveryOne: true,
		isAccessOnEveryOne: true,
		moduleId: 1,
		permissionType: 1,
		isAllowToAddComment: false,
		additionalPermissionGrantToMapping: [],
		additionalPermissionAccessOnMapping: [],
		feedBackCategoryId: [],
	};
	const [formData, setFormData] = useState<any>([[{ ...defaultData }]]);
	useEffect(() => {
		//console.log('formPermissionData', formPermissionData);
		if (formPermissionData && formPermissionData.length > 0) {
			setFormData([formPermissionData]);
		} else {
			setFormData([[{ ...defaultData }]]);
		}
	}, [formPermissionData]);

	const deleteRolePermission = (index: number, indexChild: number) => {
		let formDataCp = [...formData];
		formDataCp[index].splice(indexChild, 1);
		setFormData(formDataCp);
	};
	const { enqueueSnackbar } = useSnackbar();
	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};
	const saveForm = async (itemIndex: any) => {
		let isFormValid = true;
		let data: any = [...formData];
		let payload: any = [];
		formData.forEach((levelOnePermission: any, levelOneIndex: number) => {
			if (!payload[levelOneIndex]) {
				payload[levelOneIndex] = [];
			}
			levelOnePermission.forEach((permissionData: any, permissionIndex: number) => {
				let permissionDataCopy = Object.assign({}, permissionData);
				if (permissionDataCopy.additionalPermissionGrantToMapping.length > 0) {
					let additionalPermissionGrantToMappingCopy: any[] = [];
					permissionDataCopy.additionalPermissionGrantToMapping.forEach(
						(additionalPermissionGrantToMappingData: any) => {
							additionalPermissionGrantToMappingCopy.push({
								...permissionDefault,
								grantPermissionToId: additionalPermissionGrantToMappingData.grantPermissionToId
									? additionalPermissionGrantToMappingData.grantPermissionToId
									: additionalPermissionGrantToMappingData.searchType === 2
									? additionalPermissionGrantToMappingData.teamId
									: additionalPermissionGrantToMappingData.employeeId,
								grantPermissionToCategory: additionalPermissionGrantToMappingData.grantPermissionToCategory
									? additionalPermissionGrantToMappingData.grantPermissionToCategory
									: additionalPermissionGrantToMappingData.searchType,
							});
						}
					);
					permissionDataCopy.additionalPermissionGrantToMapping = additionalPermissionGrantToMappingCopy;
				} else if (!permissionData.isGrantPermissionToEveryOne) {
					isFormValid = false;
					permissionData['additionalPermissionGrantToMappingError'] = 'Please select grant permission to';
				}

				if (permissionDataCopy.additionalPermissionAccessOnMapping.length > 0) {
					let additionalPermissionAccessOnMappingCopy: any[] = [];
					permissionDataCopy.additionalPermissionAccessOnMapping.forEach(
						(additionalPermissionGrantToMappingData: any) => {
							additionalPermissionAccessOnMappingCopy.push({
								...accessDefault,
								accessOnId: additionalPermissionGrantToMappingData.accessOnId
									? additionalPermissionGrantToMappingData.accessOnId
									: additionalPermissionGrantToMappingData.searchType === 2
									? additionalPermissionGrantToMappingData.teamId
									: additionalPermissionGrantToMappingData.employeeId,
								accessOnCategory: additionalPermissionGrantToMappingData.accessOnCategory
									? additionalPermissionGrantToMappingData.accessOnCategory
									: additionalPermissionGrantToMappingData.searchType,
							});
						}
					);
					permissionDataCopy.additionalPermissionAccessOnMapping = additionalPermissionAccessOnMappingCopy;
				} else if (!permissionData.isAccessOnEveryOne) {
					isFormValid = false;
					permissionData['additionalPermissionAccessOnMappingError'] = 'Please select access on grade';
				}
				data[levelOneIndex][permissionIndex] = { ...permissionData };
				payload[levelOneIndex][permissionIndex] = { ...permissionDataCopy };
			});
		});
		setFormData(data);
		if (isFormValid) {
			setLoader(true);
			let response: any = await dispatch(saveFeedbackPermission(payload[0]));
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				setLoader(false);
				getData(0);
				setIsFormEdited(false);
				showApiMsgs(t('permissionsSavedSuccessfully'), 'success');
			} else {
				setLoader(false);
				if (response?.data?.messageList) {
					if (response?.data?.messageList.message) {
						showApiMsgs(response?.data?.messageList.message, 'error');
					} else {
						const responseAPI = response?.data?.messageList;
						const keys = Object.keys(responseAPI);
						const messages = keys.map((item) => responseAPI[item]);
						//showApiMsgs(`${messages} `, 'error');
						//setFormError(response?.data?.messageList);
					}
				} else {
					showApiMsgs('Something went wrong', 'error');
				}
			}
		}
	};
	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}

			<Box className='additional-permissions-area'>
				<Box className='page-info-box'>
					<AlertInfoIcon />
					<List>
						<ListItem>
							<Typography variant='body2' className='font-weight-normal'>
								{t('goalSettingNotes')}
							</Typography>
						</ListItem>
					</List>
				</Box>
				{formData &&
					formData.length > 0 &&
					formData.map((formElement: any, formIndex: number) => {
						return (
							<PermissionBox
								formElement={formElement}
								formIndex={formIndex}
								formData={formData}
								setFormData={setFormData}
								setIsFormEdited={setIsFormEdited}
								setFocussed={setFocussed}
								formErrors={formErrors}
								setFormErrors={setFormErrors}
								focussed={focussed}
								defaultData={defaultData}
								saveForm={saveForm}
								hideCategory={true}
								isFormEdited={isFormEdited}
								callingTab={'goalSetting'}
								getData={getData}
								tabSelected={tabSelected}
								hideAllowToggle={false}
							/>
						);
					})}
			</Box>
		</>
	);
};
