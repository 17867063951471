import { Box } from '@material-ui/core';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddUserDrawer from '../../RolesAndPermissions/SystemRolesPermissions/AddUserDrawer';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { Enums } from '../../../../config/enums';
import ImageEditor from '../ImageEditor';
import { EditOrganizationForm } from './EditOrganizationForm';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import AvatarEditor from 'react-avatar-editor';
import { createUUID } from '../../../../config/utils';

export const EditOrganization: React.FC<any> = (props) => {
	const { openEditOrgDrawer, handleClose, cycleMasterData, parentOrganisation } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [selectedAddNewUserTab, setselectedAddNewUserTab] = useState<any>(0);
	const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
	const [assignedUser, setAssignedUser] = useState<any>([]);
	const [assignedUsersCopy, setAssignedUsersCopy] = useState<any>([]);
	const [selectedUserList, setSelectedUserList] = useState<any>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [userList, setUserList] = useState<any>([]);
	const [employeeList, setEmployeeList] = useState<any>([]);
	const [sortOrder, setSortOrder] = useState<any>('asc');
	const [currentSort, setCurrentSort] = useState<any>('');
	const [originalList, setOriginalList] = useState<any>([]);
	const [searchEmployeeText, setSearchEmployeeText] = useState<any>('');
	const [isImageEditor, setIsImageEditor] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [organisationDetails, setOrganisationDetails] = useState<any>({});
	const [orgOriginalData, setOrgOriginalData] = useState<any>({});
	const [errors, setErrors] = useState<any>({});
	const [userFormEdited, setUserFormEdited] = useState<boolean>(false);

	//== ====Upload Logo Functions==== ==//
	const [uploadingPic, setUploadingPic] = useState(false);
	const avatarEditorRef = useRef<AvatarEditor>(null);
	const [imageName, setImageName] = useState('');
	const [image, setImage] = useState<any>(null);
	const [formError, setFormError] = useState({});
	const [teamData, setTeamData] = useState({ imagePath: '', imageName: '' });
	const [uploadedImage, setUploadedImage] = useState('');

	useEffect(() => {
		props.getParentOrganization();
	}, []);

	const showImageEditor = (open: any) => {
		setIsImageEditor(open);
	};

	const updateImageDataImagePath = (formData: any, imageBase64: any) => {
		if (formData && imageBase64) {
			formData && setTeamData({ ...teamData, imagePath: formData });
			imageBase64 && setUploadedImage(imageBase64);
			let data = { ...organisationDetails };
			data.logoImagePath = imageBase64;
			setOrganisationDetails(data);
		} else {
			setTeamData({ ...teamData, imagePath: '' });
			setUploadedImage('');
		}
	};

	const updateImage = async (file: any, imageBase64: any) => {
		if (file && imageBase64) {
			const formData = new FormData();
			const name = imageName && !imageName.includes('svg') ? imageName : createUUID() + '.png';
			formData.append('formFile', file, name);
			updateImageDataImagePath(formData, imageBase64);
		} else {
			updateImageDataImagePath('', '');
			let org = organisationDetails;
			org.logoImagePath = '';
			setOrganisationDetails(org);
		}
		showImageEditor(false);
	};

	const DataURIToBlob = (dataURI: any) => {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	};

	const handleImageEditiorClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			setUploadingPic(true);
			setFormEdited(true);
			try {
				if (image && avatarEditorRef && avatarEditorRef.current) {
					const imageBase64 = avatarEditorRef.current?.getImageScaledToCanvas().toDataURL(image.type || 'image/png');
					const file = DataURIToBlob(imageBase64);
					updateImage(file, imageBase64);
				} else {
					updateImage('', '');
				}
			} catch (err) {
				setUploadingPic(false);
				// console.log(err);
			}
		} else {
			showImageEditor(false);
			setImage(parentOrganisation.logoImagePath);
		}
	};

	const handleFileChange = async (e: any, files: any) => {
		setFormError({});
		const file = files ? files : e.target.files[0];
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = ['image/jpg', 'image/svg+xml', 'image/png', 'image/jpeg'];
		if (size >= 1024) {
			setFormError({ fileSize: true });
		} else if (!format.includes(fileType.toLowerCase())) {
			setFormError({ fileType: true });
		} else {
			let fileData = { ...teamData, imageName: file.name, imagePath: file };
			fileData = files ? fileData : { ...teamData, imagePath: file };
			setFormError({});
			setTeamData(fileData);
		}
	};
	const uploadLogoHandler = async () => {
		if (teamData.imagePath) {
			const response = await props.uploadLogo(teamData.imagePath);
			if (Boolean(response) && response.data && response.data.entity) {
				setTeamData({ ...teamData, imagePath: response.data.entity });
				return response.data.entity;
			}
		}
	};
	//=================================//
	useEffect(() => {
		if (parentOrganisation && parentOrganisation.teamId) {
			setOrganisationDetails(parentOrganisation);
			setOrgOriginalData(parentOrganisation);
			let updateList = [];
			updateList =
				parentOrganisation.assignedUsers.length > 0
					? parentOrganisation.assignedUsers.map((item: any) => {
							return { ...item, alreadyAssigned: true };
					  })
					: [];
			if (parentOrganisation.logoImagePath) {
				setImage(parentOrganisation.logoImagePath);
			}
			setAssignedUser(updateList);
			setAssignedUsersCopy(JSON.parse(JSON.stringify(updateList)));
		}
	}, [parentOrganisation]);

	const handleOrgSave = async () => {
		if (validateForm()) {
			setShowLoader(true);
			try {
				let data = organisationDetails;
				if (uploadedImage.length) {
					try {
						const logoDetails = await uploadLogoHandler();
						data.logoImagePath = logoDetails;
					} catch (err) {
						showApiMsgs(t('uploadingLogoError'), 'success');
					}
				}
				let usersList = [];
				if (assignedUsersCopy.length) {
					usersList = assignedUsersCopy.map((item: any) => {
						return { employeeId: item.employeeId, isActive: item.isRemoved ? false : true };
					});
				}

				let orgData = {
					teamId: organisationDetails.teamId,
					teamName: organisationDetails.teamName,
					teamHeadId: organisationDetails.teamHeadId,
					logoImagePath: organisationDetails.logoImagePath,
					assignedUsers: usersList,
				};

				const response = await props.updateParentOrganisation(orgData);
				if (response && response.data.status === 200) {
					setShowLoader(false);
					showApiMsgs(t('orgUpdatedSuccess'), 'success');
					props.handleOrgUpdate();
					handleClose();
					if (
						response.data?.entity?.teamHeadId === 0 &&
						orgOriginalData.teamHeadId != response.data?.entity?.teamHeadId
					) {
						setTimeout(() => {
							showApiMsgs(t('unlockValetAddMsg'), 'success');
						}, 1000);
					}
				} else {
					const responseAPI = response.data.messageList;
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					showApiMsgs(`${messages}`, 'error');
					setShowLoader(false);
				}
			} catch (err) {
				setShowLoader(false);
				showApiMsgs(t('saveOrgFail'), 'error');
			}
		} else {
			return;
		}
	};

	/**
	 * Handle drawer actions
	 */
	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			if (!formEdited) {
				handleClose();
				props.resetParentOrganisation();
			} else {
				handleOrgSave();
			}
		} else if (type === 'cancel' || type === 'close') {
			if (!formEdited) {
				handleClose();
				props.resetParentOrganisation();
			} else {
				setModalOpen(true);
			}
		}
	};
	/**
	 * Handle Role detail and Manage User tab change
	 * @param event HTML Event
	 * @param newValue Number
	 */
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setselectedAddNewUserTab(newValue);
		setAssignedUser(assignedUsersCopy);
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const handleAddUserDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			setAssignedUser([...assignedUser, ...selectedUserList]);
			setAssignedUsersCopy([...assignedUser, ...selectedUserList]);
			setOpenUserDrawer(!openUserDrawer);
			setFormEdited(true);
			setUserFormEdited(false);
			if (selectedUserList.length) {
				showApiMsgs(t('wellDoneMsg', { numberOfUser: selectedUserList.length }), 'success');
			}
		} else if (type === 'close') {
			if (userFormEdited) {
				setModalOpen(true);
			} else {
				setOpenUserDrawer(!openUserDrawer);
			}
		} else {
			setSelectedUserList(assignedUser);
			setOpenUserDrawer(!openUserDrawer);
		}
	};

	/**
	 * Handle when user click on any records
	 * @param selectedOptions Array
	 */
	const onSelectEmployee = (selectedOptions: any, actionMeta: any) => {
		setEmployeeList(selectedOptions);
	};

	/**Add users to list panel*/
	const handleAddUserNextClick = () => {
		let filterByEmp = userList.filter((user: any) => {
			return !employeeList.some((item: any) => item.employeeId === user.employeeId);
		});
		setUserFormEdited(true);
		let mergeList = [...employeeList, ...selectedUserList];
		setSelectedUserList(mergeList);
		setUserList(filterByEmp);
		setEmployeeList([]);
	};

	/**
	 * Handle delete from add user drawer
	 * @param event HTML Event
	 * @param index Number
	 */
	const handleAddUserDelete = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const allSelectedEmployee = [...selectedUserList];
		const originalListCopy = [...originalList];
		allSelectedEmployee.splice(index, 1);
		let removeSelectedRecords = originalListCopy.filter((x: any) => {
			return !allSelectedEmployee.some((t: any) => t.value === x.value);
		});
		setUserList(removeSelectedRecords);
		setSelectedUserList(allSelectedEmployee);
	};

	/**
	 * Handle sorting in user list drawer
	 * @param type string
	 */
	const handleSortData = (type: any) => {
		let list = _.orderBy(selectedUserList, [(user: any) => user[type].toLowerCase()], sortOrder);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		setCurrentSort(type === 'label' ? 'label' : 'email');
		setSelectedUserList(list);
	};

	/**
	 * Handle filter in add user drawer
	 * @param option Object
	 * @param searchText string
	 * @returns boolean
	 */
	const customFilter = (option: any, searchText: any) => {
		if (
			option.label.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.emailId.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.employeeId === parseInt(searchText)
		) {
			return true;
		}
	};

	/**
	 * Toggle assign user drawer
	 */
	const handleAssignModal = () => {
		setSortOrder('asc');
		setSearchEmployeeText('');
		setAssignedUser(assignedUser);
		setOpenUserDrawer(!openUserDrawer);
	};

	/**
	 * Handle user input in manage user drawer
	 * @param e HTML Event
	 */
	const searchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		let originalAssignedUserList = [...assignedUsersCopy];
		setSearchEmployeeText(value);
		let filtered = originalAssignedUserList.filter((item: any) => {
			if (
				`${item.firstName} ${item.lastName}`.toLowerCase().includes(value.toLowerCase()) ||
				item.emailId.toLowerCase().includes(value.toLowerCase()) ||
				item.employeeId === parseInt(value)
			) {
				return item;
			}
		});
		setAssignedUser(filtered);
	};

	/**
	 * Fetch all users
	 */
	const fetchUsers = () => {
		const data = `pageIndex=0&pageSize=${Enums.MAX_USER_SIZE}`;
		props.getAllUsers(data);
	};

	/**
	 * fetch user if not avalable in store
	 */
	useEffect(() => {
		if (openUserDrawer && !props.fetchAllUserStatus) {
			if (!props.allUsers?.records?.length) {
				fetchUsers();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUserDrawer]);

	useEffect(() => {
		if (openUserDrawer && props.allUsers?.records?.length) {
			let allUsersClone = JSON.parse(JSON.stringify(props.allUsers));
			const activeUser = allUsersClone.records.filter((user: any) => user.isActive);
			let filterByEmp = [];
			let updatedList = [];
			filterByEmp = activeUser.filter((user: any) => {
				return assignedUser.length ? !assignedUser.some((item: any) => item.employeeId === user.employeeId) : [];
			});
			updatedList = filterByEmp.map((item: any) => {
				return { ...item, label: `${item.firstName} ${item.lastName}`, value: item.employeeId };
			});
			setOriginalList(updatedList);
			setUserList(updatedList);
		}
		if (!openUserDrawer) {
			setSelectedUserList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.allUsers, openUserDrawer]);

	const validateForm = () => {
		let isValidForm = true;
		if (organisationDetails.teamName === '') {
			isValidForm = false;
		}
		return isValidForm;
	};

	/**
	 * Handle alert dialog box
	 * @param e HTMLInputElement
	 * @param type Number
	 */
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (openUserDrawer) {
			if (type === 1) {
				setFormEdited(false);
				setOpenUserDrawer(false);
			}
			setModalOpen(false);
		} else {
			if (type === 1) {
				handleClose();
				props.resetParentOrganisation();
			}
			setModalOpen(false);
		}
	};

	const handleRemoveOrgHead = () => {
		let organisation = { ...organisationDetails };
		organisation.teamHeadName = '';
		organisation.teamHeadId = 0;
		organisation.teamHeadImage = '';
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};

	const handleOrgHead = (user: any) => {
		let organisation = { ...organisationDetails };
		organisation.teamHeadName = `${user.firstName} ${user.lastName}`;
		organisation.teamHeadId = user.employeeId;
		organisation.teamHeadImage = user.imagePath;
		setOrganisationDetails(organisation);
		setErrors({});
		setFormEdited(true);
	};

	const handleDeleteLogo = () => {
		setImage('');
	};

	const handleOrgNameChange = (value: any) => {
		let organisation = { ...organisationDetails };
		organisation.teamName = value;
		setFormEdited(true);
		setOrganisationDetails(organisation);
	};

	/**
	 * Handle sorting for users in manage user listing
	 */
	const handleSortManageUsers = () => {
		let sortManageUserByFirstName = _.orderBy(assignedUser, [(user: any) => user.firstName.toLowerCase()], sortOrder);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		setAssignedUser(sortManageUserByFirstName);
	};

	return (
		<Fragment>
			<MainDrawer
				loader={showLoader}
				open={openEditOrgDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('manageOrganizationLabel')}
				children={
					<Box className='drawer-inner-content'>
						<EditOrganizationForm
							{...props}
							parentOrganisation={parentOrganisation}
							assignedUser={assignedUser}
							selectedAddNewUserTab={selectedAddNewUserTab}
							handleTabChange={handleTabChange}
							searchEmployeeText={searchEmployeeText}
							searchUser={searchUser}
							handleAssignModal={handleAssignModal}
							showImageEditor={showImageEditor}
							organisationDetails={organisationDetails}
							handleRemoveOrgHead={handleRemoveOrgHead}
							handleOrgHead={handleOrgHead}
							errors={errors}
							uploadedImage={uploadedImage}
							handleOrgNameChange={handleOrgNameChange}
							handleSortManageUsers={handleSortManageUsers}
							sortOrder={sortOrder}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
			/>

			<MainDrawer
				open={openUserDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				children={
					<Box className='drawer-inner-content'>
						<AddUserDrawer
							t={t}
							userList={userList}
							onSelectEmployee={onSelectEmployee}
							handleAddUserNextClick={handleAddUserNextClick}
							selectedUserList={selectedUserList}
							employeeList={employeeList}
							handleAddUserDelete={handleAddUserDelete}
							handleSortData={handleSortData}
							sortOrder={sortOrder}
							customFilter={customFilter}
							currentSort={currentSort}
							noRecordMessage={t('addUserMsg')}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				rightCloseBtn={true}
				isSaveButtonDisabled={!selectedUserList.length}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				handleDrawerClose={(event: any) => handleAddUserDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleAddUserDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleAddUserDrawerClose(event, 'cancel')}
			/>
			<MainDrawer
				open={isImageEditor}
				transitionDuration={{ enter: 500, exit: 1000 }}
				children={
					<Box className='drawer-inner-content'>
						<ImageEditor
							{...props}
							handleFileChange={handleFileChange}
							removeLogo={() => {}}
							setImage={setImage}
							image={image}
							avatarEditorRef={avatarEditorRef}
							handleDeleteLogo={handleDeleteLogo}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				rightCloseBtn={true}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				handleDrawerClose={(event: any) => handleImageEditiorClose(event, 'close')}
				handleSaveClick={(event: any) => handleImageEditiorClose(event, 'save')}
				handleCancelClick={(event: any) => handleImageEditiorClose(event, 'cancel')}
			/>

			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};
