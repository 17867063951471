import React from 'react';

export const CancelIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M25.9602 4.03987C26.7412 4.82091 26.7412 6.08725 25.9602 6.86829L17.8284 15L25.9602 23.1317C26.7412 23.9128 26.7412 25.1791 25.9602 25.9602C25.1791 26.7412 23.9128 26.7412 23.1317 25.9602L15 17.8284L6.86827 25.9602C6.08722 26.7412 4.82089 26.7412 4.03985 25.9602C3.2588 25.1791 3.2588 23.9128 4.03985 23.1317L12.1716 15L4.03985 6.86829C3.2588 6.08725 3.2588 4.82091 4.03985 4.03987C4.82089 3.25882 6.08722 3.25882 6.86827 4.03987L15 12.1716L23.1317 4.03987C23.9128 3.25882 25.1791 3.25882 25.9602 4.03987Z'
			fill='#292929'
		/>
	</svg>
);
export const TickIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M25.5319 6.51925C26.3497 7.26173 26.4107 8.52659 25.6683 9.34439L12.7308 23.5944C12.363 23.9995 11.8452 24.2362 11.2982 24.2494C10.7512 24.2626 10.2227 24.0511 9.83578 23.6642L4.58579 18.4142C3.80474 17.6332 3.80474 16.3668 4.58579 15.5858C5.36683 14.8047 6.63316 14.8047 7.41421 15.5858L11.1801 19.3517L22.7067 6.65564C23.4492 5.83784 24.7141 5.77678 25.5319 6.51925Z'
			fill='#292929'
		/>
	</svg>
);
export const UploadIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect width='30' height='30' rx='15' fill='#0664B1' />
		<mask id='path-2-inside-1' fill='white'>
			<path d='M15.4547 22H13.5455C13.1942 22 12.9091 21.7007 12.9091 21.3333V18.0001H11.3181C11.1895 18.0001 11.073 17.9188 11.024 17.7941C10.975 17.6694 11.0024 17.5261 11.0928 17.4308L14.2748 14.0975C14.3989 13.9675 14.6007 13.9675 14.7248 14.0975L17.9068 17.4308C17.9978 17.5261 18.0252 17.6694 17.9755 17.7941C17.9259 17.9188 17.8107 18.0001 17.6821 18.0001H16.0911V21.3333C16.0911 21.7007 15.806 22 15.4547 22Z' />
		</mask>
		<path
			d='M15.4547 22H13.5455C13.1942 22 12.9091 21.7007 12.9091 21.3333V18.0001H11.3181C11.1895 18.0001 11.073 17.9188 11.024 17.7941C10.975 17.6694 11.0024 17.5261 11.0928 17.4308L14.2748 14.0975C14.3989 13.9675 14.6007 13.9675 14.7248 14.0975L17.9068 17.4308C17.9978 17.5261 18.0252 17.6694 17.9755 17.7941C17.9259 17.9188 17.8107 18.0001 17.6821 18.0001H16.0911V21.3333C16.0911 21.7007 15.806 22 15.4547 22Z'
			fill='white'
		/>
		<path
			d='M12.9091 18.0001H17.9091C17.9091 15.2387 15.6705 13.0001 12.9091 13.0001V18.0001ZM11.0928 17.4308L7.47616 13.9782C7.47212 13.9824 7.4681 13.9867 7.46408 13.9909L11.0928 17.4308ZM14.2748 14.0975L17.8914 17.55V17.55L14.2748 14.0975ZM14.7248 14.0975L11.1081 17.55L11.1081 17.55L14.7248 14.0975ZM17.9068 17.4308L21.5234 13.9782L21.5234 13.9782L17.9068 17.4308ZM16.0911 18.0001V13.0001C13.3297 13.0001 11.0911 15.2387 11.0911 18.0001H16.0911ZM15.4547 17H13.5455V27H15.4547V17ZM13.5455 17C16.175 17 17.9091 19.1639 17.9091 21.3333H7.90909C7.90909 24.2375 10.2134 27 13.5455 27V17ZM17.9091 21.3333V18.0001H7.90909V21.3333H17.9091ZM12.9091 13.0001H11.3181V23.0001H12.9091V13.0001ZM11.3181 13.0001C13.3454 13.0001 15.012 14.2719 15.6774 15.9649L6.37065 19.6233C7.13414 21.5656 9.03358 23.0001 11.3181 23.0001V13.0001ZM15.6774 15.9649C16.325 17.6124 15.9915 19.5309 14.7215 20.8706L7.46408 13.9909C6.01332 15.5213 5.62504 17.7265 6.37065 19.6233L15.6774 15.9649ZM14.7094 20.8833L17.8914 17.55L10.6582 10.645L7.47616 13.9782L14.7094 20.8833ZM17.8914 17.55C16.0459 19.4833 12.9537 19.4833 11.1081 17.55L18.3414 10.645C16.2476 8.45168 12.752 8.45168 10.6582 10.645L17.8914 17.55ZM11.1081 17.55L14.2902 20.8833L21.5234 13.9782L18.3414 10.645L11.1081 17.55ZM14.2902 20.8833C13.0083 19.5405 12.6689 17.6054 13.3303 15.9444L22.6208 19.6438C23.3815 17.7335 22.9873 15.5117 21.5234 13.9782L14.2902 20.8833ZM13.3303 15.9444C13.9868 14.2955 15.6283 13.0001 17.6821 13.0001V23.0001C19.9931 23.0001 21.865 21.542 22.6208 19.6438L13.3303 15.9444ZM17.6821 13.0001H16.0911V23.0001H17.6821V13.0001ZM11.0911 18.0001V21.3333H21.0911V18.0001H11.0911ZM11.0911 21.3333C11.0911 19.1639 12.8252 17 15.4547 17V27C18.7868 27 21.0911 24.2375 21.0911 21.3333H11.0911Z'
			fill='white'
			mask='url(#path-2-inside-1)'
		/>
		<path
			d='M9.18821 19C9.17475 19 9.162 18.9994 9.14783 18.9981C7.35362 18.8222 6 17.4847 6 15.8879C6 14.2336 7.45846 12.8754 9.298 12.7646C9.78817 10.6005 11.9734 9 14.5 9C16.484 9 18.3115 9.9881 19.2125 11.5322C21.3531 11.7307 23 13.3344 23 15.2617C23 16.9399 21.7278 18.4239 19.9074 18.8698C19.7176 18.9155 19.5235 18.8178 19.4718 18.6512C19.4194 18.4847 19.5299 18.3118 19.7183 18.2661C21.2334 17.8954 22.2917 16.6594 22.2917 15.2617C22.2917 13.6043 20.8304 12.2342 18.9646 12.1428C18.8336 12.1365 18.7181 12.067 18.6629 11.9624C17.9135 10.5435 16.28 9.62617 14.5 9.62617C12.2227 9.62617 10.2663 11.1259 9.94896 13.114C9.92417 13.268 9.77471 13.3832 9.59833 13.3832C7.97908 13.3832 6.70833 14.5066 6.70833 15.8879C6.70833 17.1653 7.79067 18.2348 9.22717 18.3757C9.42125 18.3951 9.5615 18.5498 9.53954 18.7214C9.51971 18.8817 9.366 19 9.18821 19Z'
			fill='white'
			stroke='white'
			strokeWidth='0.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const AddIconFilled = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect width='30' height='30' rx='15' fill='#0664B1' />
		<path d='M15 11.3999V18.5999' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M11.4023 15H18.6023' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export const UnLockIconUser = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15 3.375C13.0247 3.375 11.25 5.12267 11.25 7.5V10.875H23.75C25.6407 10.875 27 12.4852 27 14.25V26.25C27 28.0148 25.6407 29.625 23.75 29.625H6.25C4.35928 29.625 3 28.0148 3 26.25V14.25C3 12.4852 4.35928 10.875 6.25 10.875H8.25V7.5C8.25 3.66413 11.1763 0.375 15 0.375C18.8237 0.375 21.75 3.66413 21.75 7.5V8.4375H18.75V7.5C18.75 5.12267 16.9753 3.375 15 3.375ZM6.10915 13.9477C6.05093 14.01 6 14.1133 6 14.25V26.25C6 26.3867 6.05093 26.49 6.10915 26.5523C6.16571 26.6129 6.21584 26.625 6.25 26.625H23.75C23.7842 26.625 23.8343 26.6129 23.8909 26.5523C23.9491 26.49 24 26.3867 24 26.25V14.25C24 14.1133 23.9491 14.01 23.8909 13.9477C23.8343 13.8871 23.7842 13.875 23.75 13.875H6.25C6.21584 13.875 6.16571 13.8871 6.10915 13.9477Z'
			fill='#292929'
		/>
	</svg>
);

export const LockIconPurple = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M15.15 3.375C12.9624 3.375 11.1375 5.19311 11.1375 7.5V10.5H19.1625V7.5C19.1625 5.19311 17.3376 3.375 15.15 3.375ZM22.1625 10.5V7.5C22.1625 3.59368 19.0513 0.375 15.15 0.375C11.2487 0.375 8.1375 3.59368 8.1375 7.5V10.5H5.9625C4.09083 10.5 2.625 12.0398 2.625 13.875V26.25C2.625 28.0852 4.09082 29.625 5.9625 29.625H24.3375C26.2092 29.625 27.675 28.0852 27.675 26.25V13.875C27.675 12.0398 26.2092 10.5 24.3375 10.5H22.1625ZM5.9625 13.5C5.80453 13.5 5.625 13.6392 5.625 13.875V26.25C5.625 26.4858 5.80453 26.625 5.9625 26.625H24.3375C24.4955 26.625 24.675 26.4858 24.675 26.25V13.875C24.675 13.6392 24.4955 13.5 24.3375 13.5H5.9625Z'
			fill='#CD0CFF'
		/>
	</svg>
);

export const GoToButton = () => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0 16C0 15.4477 0.447715 15 1 15H9C9.55228 15 10 15.4477 10 16C10 16.5523 9.55228 17 9 17H1C0.447715 17 0 16.5523 0 16Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0 23C0 22.4477 0.447715 22 1 22H12C12.5523 22 13 22.4477 13 23C13 23.5523 12.5523 24 12 24H1C0.447715 24 0 23.5523 0 23Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0 31C0 30.4477 0.447715 30 1 30H16C16.5523 30 17 30.4477 17 31C17 31.5523 16.5523 32 16 32H1C0.447715 32 0 31.5523 0 31Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M31.9993 15.2168L31.6692 1.37682C31.6507 0.839427 31.2216 0.408722 30.6843 0.388155L16.8408 0.000198549C16.2839 -0.010636 15.819 0.423244 15.8033 0.984994C15.7925 1.54187 16.2263 2.00678 16.7881 2.02251L28.084 2.33969L15.2934 16.0136C14.884 16.4231 14.884 17.087 15.2934 17.4965C15.7029 17.9059 16.3668 17.9059 16.7763 17.4965L29.7007 3.67947L29.9817 15.2667C29.9953 15.8285 30.4537 16.269 31.0155 16.2554C31.5724 16.2369 32.0178 15.7737 31.9993 15.2168Z'
			fill='#292929'
		/>
	</svg>
);
