import { Box, Tabs, Tab, Typography, FormLabel, Avatar, Chip, Tooltip } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelectLoadMore } from '../../CommonComponent/MultiSelectLoadMore';
import { ManageUserDrawerForm } from '../../RolesAndPermissions/SystemRolesPermissions/ManageUserDrawerForm';
import { UserAvatarGroup } from '../../CommonComponent/UserAvatarGroup';
import { TextInlineEdit } from '../../CommonComponent/TextInlineEdit';
import { CameraIcon, NoOrgImgIcon } from '../../../../config/svg/CommonSvgIcon';
import { ErrorIcon, TeamsIcon } from '../../../../config/svg/formElementIcons';
import '../.././../../styles/pages/admin/textInlineEdit.scss';
import { QuickSearchPopover } from '../../CommonComponent/QuickSearchPopover';
import { getUserName } from '../../../../config/utils';
import AvatarEditor from 'react-avatar-editor';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { AddBulkTeamForm } from './AddBulkTeamForm';

export const AddTeamForm: React.FC<any> = (props) => {
	const {
		selectedAddNewUserTab,
		handleTabChange,
		getParentTeam,
		teamFormData,
		setTeamFormData,
		handleDeleteUser,
		searchUser,
		handleAssignModal,
		searchEmployeeText,
		assignedUser,
		showImageEditor,
		editForm,
		formError,
		setFormError,
		setFormEdited,
		image,
		isEditForm,
		isChildTeam,
		setUploadFileData,
		uploadFileData,
		statusLogMessage,
		setBulkTeamEdited,
		downloadButtonClass,
	} = props;
	const { t } = useTranslation();
	const [inlineEdit, setInlineEdit] = React.useState(false);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const [modalOpenProps, setModalOpenProps] = useState({
		open: false,
		userData: {},
		isPopupDisplayed: false,
	});

	function a11yProps(index: number) {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		setInlineEdit(!isEditForm);
	}, [isEditForm]);

	const setTeamFieldData = (field: string, value: any) => {
		setTeamFormData({ ...teamFormData, [field]: value });
		setFormError({ ...formError, [field]: '' });
		setFormEdited(true);
	};

	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			setTeamFieldData('teamLeaderId', modalOpenProps.userData);
		}
		setModalOpenProps({
			open: false,
			userData: {},
			isPopupDisplayed: true,
		});
	};

	return (
		<Fragment>
			<Tabs
				className='okr-tabs drawer-tabs'
				value={selectedAddNewUserTab}
				onChange={handleTabChange}
				aria-label='Admin Tabs'
			>
				<Tab label={t('teamDetailsLabel')} value={0} {...a11yProps(0)} />
				{assignedUser.length > 0 && (
					<Tab
						label={`${t('manageMembers')} 
							${teamFormData?.employeeCount > 0 ? '(' : ''}${teamFormData?.employeeCount > 0 ? teamFormData?.employeeCount : ''}${
							teamFormData?.employeeCount > 0 ? ')' : ''
						}`}
						value={1}
						{...a11yProps(1)}
					/>
				)}
				<Tab label={t('addBulkTeams')} value={2} {...a11yProps(2)} />
			</Tabs>

			<Box className='drawer-tabs-panel'>
				{selectedAddNewUserTab !== 2 ? (
					<form className='drawer-form-fields' noValidate autoComplete='off'>
						{selectedAddNewUserTab === 0 && (
							<>
								<Box className='drawer-input-field'>
									<Box className='user-team-detail'>
										<div className='team-logo-wrapper' id='team-avatar' onClick={() => showImageEditor(true)}>
											{teamFormData.teamName && !image ? (
												<Avatar id='team-logo' className='team-logo ' key={`user-icon-`} src={''}>
													{teamFormData.teamName
														? getUserName({
																firstName: '',
																lastName: '',
																fullName: teamFormData.teamName || '',
														  })
														: ''}
												</Avatar>
											) : image ? (
												<Box className='team-logo profile-avatar'>
													<AvatarEditor
														image={image}
														width={53}
														height={54}
														border={0}
														borderRadius={168}
														rotate={0}
														disableBoundaryChecks={false}
														// @ts-expect-error
														disableHiDPIScaling={false}
														id='team-logo'
														className={image ? 'team-logo image-section-white-bg' : 'team-logo image-section'}
													/>
												</Box>
											) : (
												<Avatar id='team-logo' className='team-logo no-org-image' key={`user-icon-`} src={''}>
													<NoOrgImgIcon />
												</Avatar>
											)}
											<div className='camera-icon'>
												<label htmlFor='file-input' className='upload-image-label'>
													<CameraIcon />
												</label>
											</div>
										</div>
										<Box className='team-info'>
											<TextInlineEdit
												{...props}
												inlineEdit={inlineEdit}
												setInlineEdit={setInlineEdit}
												parentName={teamFormData.teamName}
												updateValue={(value: any) => {
													setTeamFieldData('teamName', value);
												}}
												errorText={t('teamFieldValidationText')}
												error={formError.teamName}
											/>
											<QuickSearchPopover
												{...props}
												leader={teamFormData.teamLeaderId}
												onSelectedUser={(user: any, actionType: string) => {
													if (
														isEditForm === true &&
														actionType !== 'delete' &&
														modalOpenProps.isPopupDisplayed === false &&
														teamFormData.teamLeaderEmployeeId &&
														teamFormData.teamLeaderEmployeeId !== user.employeeId
													) {
														setModalOpenProps({
															open: true,
															userData: user,
															isPopupDisplayed: false,
														});
													} else {
														setTeamFieldData('teamLeaderId', user);
													}
												}}
												error={formError.teamLeaderId}
											/>
										</Box>
									</Box>
								</Box>
								{/* {(teamFormData.parentId !== 0 || !isEditForm) && ( */}
								<Box className={`drawer-input-field drawer-input-field-col2 ${isMenuOpen ? 'label-active' : ''}`}>
									<Box
										className={`field-col2 ${
											formError['ParentId'] && formError['ParentId'] ? 'select-error helperText-bottom ' : ''
										}`}
									>
										<FormLabel>
											<TeamsIcon />
											{t('parentTeamLabel')}
											<span className='asterisk'>*</span>
										</FormLabel>
										{
											<Tooltip arrow classes={{ popper: 'tooltip-layout4' }} title={''}>
												<Box>
													<MultiSelectLoadMore
														key={'parentId'}
														id='team-org-dropdown'
														selectedOptions={teamFormData.parentId}
														onSelectOption={(value: any) => {
															setTeamFieldData('parentId', value);
															if (value) {
																setFormError({ ...formError, ParentId: '' });
															}
														}}
														fieldMap={{
															label: 'teamName',
															value: 'teamId',
															imagePath: 'logoImagePath',
															secondaryText: 'teamHeadName',
															count: 'employeeCount',
														}}
														placeHolder={t('searcTeamLabel')}
														isMulti={true}
														isSingleSelection={true}
														getOptions={getParentTeam}
														closeMenuOnSelect={true}
														selectClassName={'select-search-dropdown select-search-team-dropdown select-search-icon'}
														selectClassNamePrefix={'react-select'}
														error={formError['ParentId'] || ''}
														helperText={
															formError['ParentId'] && (
																<>
																	<ErrorIcon />
																	{(formError['ParentId'] && formError['ParentId']) || ''}
																</>
															)
														}
														// disabled={isEditForm === true}
														isMenuOpen={setIsMenuOpen}
														hideLoadMore={true}
													/>
												</Box>
											</Tooltip>
										}
										<Typography className={`short-des-text `}>{t('parentTeamShortText')}</Typography>
									</Box>
								</Box>
								{/* )} */}
								<Box className='drawer-blue-bg-full'>
									<Box className='drawer-form-fields-group'>
										<Box className='drawer-input-field'>
											<UserAvatarGroup
												{...props}
												subTitle={t('addMembersLabel')}
												helpText={t('addMembersShortText')}
												handleAddUserClick={handleAssignModal}
												assignedUserDetails={
													assignedUser.length > 0 && assignedUser.map((item: any, index: number) => item)
												}
												max={6}
											/>
										</Box>
									</Box>
								</Box>
							</>
						)}
						{selectedAddNewUserTab === 1 && (
							<ManageUserDrawerForm
								t={t}
								{...props}
								searchEmployeeText={searchEmployeeText}
								searchUser={searchUser}
								handleAssignModal={handleAssignModal}
								handleDeleteUser={handleDeleteUser}
								view={'editTeam'}
							/>
						)}
					</form>
				) : selectedAddNewUserTab === 2 ? (
					<>
						<AddBulkTeamForm
							{...props}
							setUploadFileData={setUploadFileData}
							uploadFileData={uploadFileData}
							statusLogMessage={statusLogMessage}
							setBulkTeamEdited={setBulkTeamEdited}
							downloadButtonClass={downloadButtonClass}
						/>
					</>
				) : (
					<></>
				)}
			</Box>
			{modalOpenProps.open && (
				<DialogComponent
					module='information'
					message={t('changeOrgLeader', {
						teamName: teamFormData.teamName,
					})}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpenProps.open}
				/>
			)}
		</Fragment>
	);
};
