import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormLabel, Link, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import UploadImg from '../../../images/upload-img-new.svg';
import { OKRButton } from '../../Common/OKRButton';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import { useDispatch } from 'react-redux';
import {
	deleteUploadedFiles,
	uploadPIPDocument,
	uploadPIPMultiDocument,
} from '../../../action/performanceImprovementPlan';
import { Enums } from '../../../config/enums';
import { useSnackbar } from 'notistack';
import { DownloadIcon, DeleteIcon } from '../../../config/svg/CommonSvgIcon';
import { getMonthDateYear, getTime } from '../../../config/utils';

export const UploadDocuments = (props: any) => {
	const {
		loader,
		setLoader,
		updatePipFormDetails,
		pipFormDetails,
		featureListType = 4,
		moduleDetailId = 0,
		isFormCleared,
		setIsFormCleared,
		pipFormType = '',
		documentList,
		uploadDocumentType = '',
		handleDelete,
		deletedDocumentList,
		showDeletedList = false,
	} = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const format = [
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'text/csv',
		'application/pdf',
		'image/jpeg',
		'image/png',
		'image/jpg',
		'image/svg',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'application/vnd.ms-powerpoint',
		'application/msword',
		'text/plain',
	];
	const fileExtension = ['msg', 'zip', 'tar', 'rar', 'vtt', 'mp4'];

	const [uploadFileData, setUploadFileData] = useState<any>(pipFormDetails?.documents || []);
	const [deletedList, setDeletedList] = useState<any>(deletedDocumentList || []);
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '', details: null });
	const preventDefault = (event: any) => event.preventDefault();

	useEffect(() => {
		if (isFormCleared) {
			setUploadFileData([]);
			setIsFormCleared(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormCleared]);
	useEffect(() => {
		if (documentList) {
			setUploadFileData(documentList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [documentList]);

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	/**
	 * handle upload browse File
	 */
	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};

	/**
	 * handle Upload Bulk Team API response
	 */
	const validateBulkTeam = async (files: any) => {
		try {
			const formData = new FormData();
			files.forEach((fileData: any) => {
				formData.append('files', fileData);
			});

			setLoader(true);
			let urlParams = `?type=${featureListType}`;
			if (moduleDetailId) {
				urlParams += `&moduleDetailId=${moduleDetailId}`;
			}
			if (pipFormDetails?.performanceGoalsList?.performanceGoalCycleEmployeeId) {
				urlParams += `&documentLinkId=${pipFormDetails?.performanceGoalsList?.performanceGoalCycleEmployeeId}`;
			}
			// const response: any = await dispatch(uploadPIPDocument(formData, featureListType));
			const response: any = await dispatch(uploadPIPMultiDocument(formData, urlParams));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};

				// const s3Url = response?.data?.entity || '';
				// const storageFileName = s3Url ? s3Url.split('/').at(-1) : file.uploadFileName;
				const newFilesList: any[] = [];
				if (entityList && entityList?.length) {
					entityList?.forEach((item: any) => {
						const storageFileName = item?.cdnUrl ? item?.cdnUrl.split('/').at(-1) : item?.fileName;
						newFilesList.push({
							filePath: item?.cdnUrl || '',
							uploadFileName: item?.fileName || '',
							storageFileName: storageFileName || item?.fileName || '',
						});
					});
				}
				const uploadedFiles = [...uploadFileData, ...newFilesList];
				setLoader(false);
				setUploadFileData(uploadedFiles);
				updatePipFormDetails('documents', uploadedFiles);
				if (handleDelete) {
					handleDelete();
				}
				// const responseAPI = response.data.messageList;
				// const keys = Object.keys(responseAPI);
				// const messages = keys.map((item) => responseAPI[item]);
				// showApiMsgs(`${messages} `, 'success');
			} else if (response?.data?.status === 400 || response?.data?.errors?.status === 400) {
				const responseAPI = response?.data?.messageList || response?.data?.errors?.UploadFiles || {};
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				showApiMsgs(`${messages} `, 'error');
				setLoader(false);
			}
		} catch (error) {
			showApiMsgs(`An error occurred`, 'error');
			setLoader(false);
		}
	};

	const callUploadFileAPI = async (e: any, files: any) => {
		try {
			if (files && files?.length) {
				const allFiles: any[] = [];
				let isSizeError = false;
				let isFileTypeError = false;
				[...files]?.forEach((file: any) => {
					const fSize = file.size;
					const size = Math.round(fSize / 1024);
					const fileType = file.type;
					const extension = getFileExtension(file.name);
					if (size <= 30000) {
						if (format.includes(fileType.toLowerCase()) || fileExtension.includes(extension)) {
							allFiles.push(file);
						} else {
							isFileTypeError = true;
						}
					} else {
						isSizeError = true;
					}
				});
				if (isSizeError) {
					showApiMsgs(`File size exceeds the maximum allowed size of 30 MB.`, 'error');
				} else if (isFileTypeError) {
					showApiMsgs(t('Format of the file is not supported to upload.'), 'error');
				} else {
					await validateBulkTeam(allFiles);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getFileExtension = (fileName: any) => {
		return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
	};

	/**
	 * handle uploaded browse file
	 */
	const handleFileChange = async (e: any) => {
		const file = e.target.files;
		callUploadFileAPI(e, file);
	};
	/**
	 * handle delete file
	 */
	const removeFile = async (selectedFile: any) => {
		try {
			// let updatedUploadFileData = uploadFileData && uploadFileData?.length ? [...uploadFileData] : [];
			// updatedUploadFileData = updatedUploadFileData?.filter(
			// 	(rec: any, index: number) => index !== selectedFile?.fileIndex
			// );
			// setUploadFileData(updatedUploadFileData);
			// updatePipFormDetails('documents', updatedUploadFileData);
			if (selectedFile?.storageFileName) {
				setLoader(true);
				const response: any = await dispatch(
					deleteUploadedFiles(`type=${featureListType}`, [selectedFile?.storageFileName])
				);
				if (response?.data?.status === Enums.STATUS_SUCCESS) {
					let updatedUploadFileData = uploadFileData && uploadFileData?.length ? [...uploadFileData] : [];
					updatedUploadFileData = updatedUploadFileData?.filter(
						(rec: any, index: number) => index !== selectedFile?.fileIndex
					);
					setUploadFileData(updatedUploadFileData);
					updatePipFormDetails('documents', updatedUploadFileData);
					if (handleDelete) {
						handleDelete();
					}
					setLoader(false);
				} else {
					const responseAPI = response?.data?.messageList || response?.data?.errors?.UploadFiles || {};
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					showApiMsgs(`${messages} `, 'error');
					setLoader(false);
				}
			}
		} catch (error) {
			showApiMsgs(`An error occurred`, 'error');
			setLoader(false);
		}
	};
	const handleUploadedFileDownload = async (e: any, file: any) => {
		try {
			const s3Url = file?.filePath || '';
			if (s3Url) {
				const response = await fetch(s3Url);
				const blob = await response.blob();

				// Create a link element and simulate a click to trigger the download
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = file?.uploadFileName || 'performance-improvement-development'; // Set the desired file name
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				showApiMsgs(`An error occurred`, 'error');
			}
		} catch (error) {
			showApiMsgs(`An error occurred`, 'error');
			// Handle error accordingly
		}
	};
	const handleUploadedFileDeletion = (e: any, file: any) => {
		setModalProps({
			open: true,
			module: 'delete',
			type: 'deleteFile',
			details: { ...file },
			message: t('UsersBulkUploadDeleteFile'),
		});
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'deleteFile') {
				const { details } = modalProps;
				if (details) {
					removeFile(details);
				}
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '', details: null });
	};
	// ===================================================================================
	const handleDragOver = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		const { files } = e?.dataTransfer || {};
		if (files && files.length) {
			onUpload(e, files);
		}
	};
	const onUpload = (e: any, files: any[]) => {
		if (files[0]) {
			callUploadFileAPI(e, files);
		} else {
			showApiMsgs(`An error occurred`, 'error');
		}
	};

	return (
		<>
			<input ref={inputFileRef} type='file' style={{ display: 'none' }} onChange={handleFileChange} multiple={true} />
			<Box
				className={`upload-documents ${uploadDocumentType === 'pipFeedbackAttachments' ? 'pip-attachments-box' : ''} ${
					uploadDocumentType === 'goalsCategoryAttachments' || uploadDocumentType === 'goalsReviewAttachments'
						? 'goals-attachments-box'
						: ''
				}`}
			>
				<Box className='upload-documents-field'>
					{uploadDocumentType === 'goalsCategoryAttachments' || uploadDocumentType === 'goalsReviewAttachments' ? (
						<></>
					) : (
						<FormLabel id='upload-document' component='legend' className='form-label'>
							<DownloadIcon />
							{t('uploadDocuments')}
						</FormLabel>
					)}

					<div onDrop={handleDrop} onDragOver={handleDragOver}>
						<Box
							className='upload-file'
							onClick={(event: any) => {
								uploadFile(event);
							}}
						>
							<Box className='upload-file-inner'>
								<img src={UploadImg} alt='Upload File' className='upload-img' />
								<Box className='upload-right'>
									<Typography variant='h5'>
										{t('uploadText')}
										<Link id={'browse-btn'} href='#' onClick={preventDefault}>
											{t('UsersBulkUploadBrowseFile')}
										</Link>
									</Typography>
								</Box>
							</Box>
						</Box>
					</div>
					<Box className='file-des'>
						<Typography variant='h6'>{t('attachmentNotes')}</Typography>
					</Box>
				</Box>
				<Box className='upload-attachments-list'>
					<List>
						{uploadFileData && uploadFileData?.length ? (
							uploadFileData?.map((file: any, index: number) => {
								return (
									<ListItem>
										<span className='count'>{index + 1}</span>
										<Box className='file-name'>
											<Typography>{file?.uploadFileName}</Typography>
											{file?.createdBy && (
												<Box className='uploded-by'>
													<Typography>{`By: ${file?.createdBy}`}</Typography>
													{file?.createdOn && (
														<Typography variant='body2'>{getMonthDateYear(file?.createdOn)}</Typography>
													)}
												</Box>
											)}
											<Box className='attachment-action'>
												<OKRButton
													icon={<DownloadIcon />}
													title={t('download')}
													handleClick={(e: any) => handleUploadedFileDownload(e, { ...file, fileIndex: index })}
													className='btn download-btn'
													//disabled={file?.moduleId === 3 || file?.moduleId === 4}
												/>
												<OKRButton
													icon={<DeleteIcon />}
													title={t('delete')}
													handleClick={(e: any) => handleUploadedFileDeletion(e, { ...file, fileIndex: index })}
													className='btn delete-btn'
													//disabled={file?.moduleId === 3 || file?.moduleId === 4}
												/>
											</Box>
										</Box>
									</ListItem>
								);
							})
						) : (
							<></>
						)}
					</List>
					{showDeletedList && deletedDocumentList.length > 0 ? (
						<Box className='deleted-attachments-list'>
							<Typography variant='h6'>Deleted Files ({deletedDocumentList.length})</Typography>
							<List>
								{deletedDocumentList?.map((file: any, index: number) => {
									return (
										<ListItem>
											<span className='count'>{index + 1}</span>
											<Box className='file-name'>
												<Typography>{file?.uploadFileName}</Typography>
												<Typography>
													{file?.deletedOn && <span>{`Deleted Date: ${getTime(file?.deletedOn)}`}</span>}
												</Typography>
												<Typography>{file?.deletedBy && <span>{`Deleted By: ${file?.deletedBy}`}</span>}</Typography>
											</Box>
										</ListItem>
									);
								})}
							</List>
						</Box>
					) : (
						<></>
					)}
				</Box>
			</Box>

			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
