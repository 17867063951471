import * as actionTypes from '../action/actionTypes';
import { HOME } from '../config/app-url';

const INITIAL_STATE = {
	loaderStatus: false,
	currentRoute: HOME,
	prevRoute: '',
	globalSearchPage: 1,
	listMasterDataPending: false,
	listMasterDataSuccess: false,
	listMasterDataError: {},
	showNotifier: false,
	okrType: 'goal',
	guideTourVisible: null,
	guidedTour: [],
	dashboardCurrentTab: 0,
	isClosedByUser: false,
	cycleObj: {
		cycleChangedStatus: true,
	},
	isFederationUserSuccess: false,
	isFederationUserResult: {},
	isSignalRConnected: false,
	currentCycleId: {},
	cycleIdDetails: {},
	userSelectedCycle: {},
	isUserAdded: false,
	teamCycleDetails: [],
	isSessionExpire: false,
	userImpersonated: {
		redirection: true,
		tab: 0,
		userDetails: {},
	},
	aboutUsDetails: null,
	aboutUsError: null,
	isCycleChanged: false,
	userVersion: {},
	currentEngageTab: 1,
	globalTaskDrawerOpen: false,
	globalTaskAutoFocus: false,
	redirectedNomineeDetails: null,
	redirectedEmployeeDetails: null,
	currentOrgTab: null,
	dashboardCurrentTabForSO: false,
	dashboardCurrentTabForSORedirectionSoDetailsId: null,
	newKr: null,
	removedKr: null,
	newKrInOkr: null,
	removedKrInOkr: null,
	okrCreationInProgress: false,
	okrCreationPopup: { open: false, type: '', data: {}, event: null },
	defaultOkrTagName: '',
	notificationOKRCycle: 0,
	hidePerformaceCycle: false,
};

export default function commonReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.SHOW_LOADER:
			return { ...state, loaderStatus: action.payload };
		case actionTypes.UPDATE_ROUTE:
			return { ...state, currentRoute: action.payload };
		case actionTypes.UPDATE_PREV_ROUTE:
			return { ...state, prevRoute: action.payload };
		case actionTypes.UPDATE_PAGE:
			return { ...state, globalSearchPage: action.payload };
		case actionTypes.SHOW_GUIDED_TOUR:
			return { ...state, guideTourVisible: action.guideTourVisible };
		case actionTypes.USER_CLOSED_GUIDED_TOUR:
			return { ...state, isClosedByUser: action.isClosedByUser };
		case actionTypes.CURRENT_DASHBOARD_TAB:
			return { ...state, dashboardCurrentTab: action.currentTab };
		case actionTypes.SHOW_HIDE_SNACKBAR:
			return { ...state, showNotifier: action.payload };
		case actionTypes.UPDATE_GOAT_TYPE:
			return { ...state, okrType: action.payload };
		case actionTypes.FETCH_GUIDED_DATA_SUCCESS:
			return {
				...state,
				guidedTour: action.payload,
			};
		case actionTypes.CHANGE_CYCLE:
			return {
				...state,
				cycleObj: action.data,
			};
		case actionTypes.IS_FEDERATION_USER_PENDING:
			return {
				...state,
				isFederationUserSuccess: false,
				isFederationUserResult: {},
			};
		case actionTypes.IS_FEDERATION_USER_SUCCESS:
			return {
				...state,
				isFederationUserSuccess: true,
				isFederationUserResult: action.payload.entity,
			};
		case actionTypes.IS_FEDERATION_USER_FAILED:
			return {
				...state,
				isFederationUserSuccess: false,
				isFederationUserResult: {},
			};
		case actionTypes.SIGNAL_R_CONNECTION_STATUS:
			return {
				...state,
				isSignalRConnected: action.payload,
			};
		case actionTypes.CURRENT_ORG_CYCLE_ID:
			return {
				...state,
				currentCycleId: action.payload,
			};
		case actionTypes.CYCLE_ID_DETAILS:
			return {
				...state,
				cycleIdDetails: action.payload,
			};
		case actionTypes.USER_SELECTED_CYCLE:
			return {
				...state,
				userSelectedCycle: action.payload,
			};
		case actionTypes.TEAM_CYCLE_DETAILS:
			return {
				...state,
				teamCycleDetails: action.payload,
			};
		case actionTypes.IDLE_STATE_SESSION_EXPIRE:
			return {
				...state,
				isSessionExpire: action.payload || false,
			};
		case actionTypes.IS_PERSONATED_USER:
			return {
				...state,
				userImpersonated: action.payload,
			};
		case actionTypes.IS_VERSION_PENDING:
			return {
				...state,
				aboutUsDetails: null,
				aboutUsError: null,
			};
		case actionTypes.IS_VERSION_SUCCESS:
			return {
				...state,
				aboutUsDetails: action.payload,
				aboutUsError: null,
			};
		case actionTypes.IS_VERSION_FAILED:
			return {
				...state,
				aboutUsDetails: null,
				aboutUsError: action.payload,
			};
		case actionTypes.IS_CYCLE_CHANGED:
			return {
				...state,
				isCycleChanged: action.payload,
			};
		case actionTypes.USER_VERSION_STATUS_PENDING:
			return {
				...state,
				userVersion: {},
			};
		case actionTypes.USER_VERSION_STATUS_SUCCESS:
			return {
				...state,
				userVersion: action.payload.entity,
			};
		case actionTypes.USER_VERSION_STATUS_FAILED:
			return {
				...state,
				userVersion: {},
			};
		case actionTypes.UPDATE_USER_VERSION_STATUS_SUCCESS:
			return {
				...state,
				userVersion: {
					isVersionVisible: false,
					showTooltip: action.payload.showTooltip,
					isRead: action.payload.isRead,
				},
			};
		case actionTypes.UPDATE_USER_VERSION:
			return {
				...state,
				userVersion: {
					...state.userVersion,
					isVersionVisible: false,
					showTooltip: false,
				},
			};
		case actionTypes.CURRENT_ENGAGE_TAB:
			return { ...state, currentEngageTab: action.payload };
		case actionTypes.GLOBAL_TASK_AUTO_FOCUS:
			return {
				...state,
				globalTaskAutoFocus: action.globalTaskAutoFocus,
			};
		case actionTypes.SET_NOMINEE_DETAIL_FOR_REDIRECTION:
			return { ...state, redirectedNomineeDetails: action.payload };
		case actionTypes.SET_EMPLOYEE_DETAIL_FOR_REDIRECTION:
			return { ...state, redirectedEmployeeDetails: action.payload };
		case actionTypes.SET_PERFORMANCE_GOAL_FOR_REDIRECTION:
			return { ...state, redirectedPerformanceGoalDetails: action.payload };
		case actionTypes.CURRENT_ORG_TAB:
			return { ...state, currentOrgTab: action.payload };
		case actionTypes.CURRENT_SETTING_TAB:
			return { ...state, currentSettingTab: action.payload };
		case actionTypes.CURRENT_ROLES_PERMISSIONS_TAB:
			return { ...state, currentRolePermissionsTab: action.payload };
		case actionTypes.CURRENT_DASHBOARD_TAB_FOR_SO:
			return { ...state, dashboardCurrentTabForSO: action.currentTab };
		case actionTypes.CURRENT_DASHBOARD_TAB_FOR_SO_NOTI_REDIRECTION:
			return { ...state, dashboardCurrentTabForSORedirectionSoDetailsId: action.payload };
		case actionTypes.UPDATE_AI_KR:
			return { ...state, newKr: action.payload };
		case actionTypes.REMOVE_AI_KR:
			return { ...state, removedKr: action.payload };
		case actionTypes.UPDATE_AI_EDIT_KR:
			return { ...state, newKrInOkr: action.payload };
		case actionTypes.REMOVE_AI_EDIT_KR:
			return { ...state, removedKrInOkr: action.payload };
		case actionTypes.OKR_CREATION_INPROGRESS:
			return { ...state, okrCreationInProgress: action.payload };
		case actionTypes.OKR_CREATION_POPUP:
			return { ...state, okrCreationPopup: action.payload };
		case actionTypes.SET_DEFAULT_OKR_NAME:
			return { ...state, defaultOkrTagName: action.payload };
		case actionTypes.NOTIFICATION_OKR_CYCLE:
			return { ...state, notificationOKRCycle: action.cycleId };
		case actionTypes.HIDE_PERFORMANCE_CYCLE:
			return { ...state, hidePerformaceCycle: action.payload };
		default:
			return { ...state };
	}
}
