import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControlLabel, Switch, Typography } from '@material-ui/core';
// import { Rating, IconContainerProps } from '@material-ui/lab';
// import { StarBorderIcon, StarFilledIcon } from '../../../config/svg/RatingSvg';
// import { getPerformanceRatings } from '../../../config/utils';
// import CkEditor from '../../Common/CkEditor/CkEditor';
// import { CommentIcon } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import { Enums } from '../../../config/enums';
import { StarRating } from '../../Common/PerformanceComponent/StarRating';
import { MultiChoiceRating } from '../../Common/PerformanceComponent/MultiChoiceRating';
import { SliderRating } from '../../Common/PerformanceComponent/SliderRating';
import { ShortAnswerRating } from '../../Common/PerformanceComponent/ShortAnswerRating';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { AlertInfoIcon, NextIcon } from '../../../config/svg/CommonSvg';
import { AssessmentPopup } from '../../Common/AssessmentPopup';
import AlertDialog from '../../Common/Dialog';
import { checkUnSavePopUpVisible } from '../../../config/utils';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { saveMultipleAssessmentForm } from '../../../action/UserPerformance';
import { showLoader } from '../../../action/common';
// import { saveMultipleAssessmentForm } from '../../../action/UserPerformance';

export const MultipleUserAssessmentForm = (props: any) => {
	const {
		multipleAssessmentResponse,
		// ===============================================
		performanceCycleId,
		performanceCycle,
		loader,
		setLoader,
		isViewMode = false,
		backClicked,
		handleOneOnOneClick,
		handleSnapshot,
		getSelfAssessmentDetails,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	const ref2 = useRef<any>(null);

	const [assessmentFormDetails, setAssessmentFormDetails] = useState<any>(null);
	const [fullAssessmentFormDetails, setFullAssessmentFormDetails] = useState<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [assessmentPopup, setAssessmentPopup] = useState<any>({ open: false, details: null });
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });

	useEffect(() => {
		if (multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections) {
			// setLoader(false);
			setAssessmentFormDetails(
				addQuestionNumber(multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections)
			);
		}
		if (multipleAssessmentResponse) {
			dispatch(showLoader(false));
			setFullAssessmentFormDetails(multipleAssessmentResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multipleAssessmentResponse]);
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);

	const addQuestionNumber = (details: any) => {
		let quesNumber = 0;
		const updatedDetails =
			details && details?.length
				? [...details].map((section: any) => {
						if (
							section?.multiplePerformanceRoleLinkedFormDetails &&
							section?.multiplePerformanceRoleLinkedFormDetails?.length
						) {
							section.multiplePerformanceRoleLinkedFormDetails = section?.multiplePerformanceRoleLinkedFormDetails.map(
								(question: any) => {
									quesNumber = quesNumber + 1;
									question.questionNumber = quesNumber;

									return question;
								}
							);
						}
						return section;
				  })
				: details;
		return updatedDetails;
	};

	const getLabelText = (value: number, labels: any) => {
		return `${labels[value - 1]?.name || ''}`;
	};
	const getDynamicMarks = (marksLength: any, ratingValue: any) => {
		const marks = marksLength?.map((item: any, index: number) => ({
			...item,
			value: index + 1,
			// label: item?.name || index.toString(),
			label:
				index === 0 || index === marksLength?.length - 1 ? (
					<span
						className={`${ratingValue ? '' : 'show_slider_label'} ${
							!ratingValue ? '' : ratingValue - 1 === index ? 'show-selected-label-value' : 'hide_slider_label'
						}`}
					>
						{item?.name || index.toString()}
					</span>
				) : (
					<span
						className={`${ratingValue ? '' : 'show_slider_label'} ${
							!ratingValue ? '' : ratingValue - 1 === index ? 'show-selected-label-value' : 'hide_slider_label'
						}`}
					>
						{item?.name || index.toString()}
					</span>
				),
		}));
		marks.unshift({
			value: 0,
			label: '',
		});
		return marks;
	};
	const getSelectedScaleValueIndex = (selectedScaleId: any, scaleItems: any) => {
		const marks = scaleItems?.findIndex(
			(item: any) => item?.performanceRoleLinkedFormScaleDetailId === selectedScaleId
		);
		return marks > -1 ? marks + 1 : 0;
	};
	const getSelectedStartValueIndex = (selectedStartId: any, starItem: any) => {
		const marks = starItem?.findIndex((item: any) => item?.performanceRoleLinkedFormScaleDetailId === selectedStartId);
		return marks > -1 ? marks + 1 : 0;
	};
	const getSelectedMultiChoiceValue = (selectedScaleId: any, performanceRoleLinkedFormScaleDetailId: any) => {
		return Boolean(selectedScaleId === performanceRoleLinkedFormScaleDetailId) || false;
	};
	const getQuestionNumber = (section: any, question: any, sectionIndex: number, questionIndex: number) => {
		// let quesNum = 0;
		// if (sectionIndex === 0) {
		// 	quesNum = quesNum + questionIndex + 1;
		// } else {
		// 	const data = assessmentFormDetails[sectionIndex - 1]?.multiplePerformanceRoleLinkedFormDetails?.length;
		// 	quesNum = quesNum + data + questionIndex + 1;
		// }

		return `${question?.questionNumber || 0}. `;
	};

	const handleClickOutside = (event: any) => {
		if (
			ref.current &&
			(ref1.current?.contains(event.target) || !ref.current?.contains(event.target)) &&
			checkUnSavePopUpVisible(event) &&
			isFormEdited
		) {
			// ref2.current?.contains(event.target) ||
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				// || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('unSavedItemAlert') });
			}
		} else {
			//
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'ClearForm') {
				if (multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections) {
					setAssessmentFormDetails(
						addQuestionNumber(multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections)
					);
					setIsFormEdited(false);
				}
			} else if (modalProps?.type === 'UnSaveChanges') {
				if (modalProps?.event) {
					setAssessmentFormDetails(
						addQuestionNumber(multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections)
					);
					setIsFormEdited(false);
					const { target } = modalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	const handleClearAssessmentForm = (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (isFormEdited) {
				setModalProps({
					open: true,
					event: null,
					type: 'ClearForm',
					message: t('clearAssessmentForm'),
				});
			} else {
				if (multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections) {
					setAssessmentFormDetails(
						addQuestionNumber(multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections)
					);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleSaveAsDraftAssessmentForm = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				handleSaveAssessmentAPICall(Enums.ONE, Enums.ONE);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleSubmitAssessmentForm = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			if (checkAssessmentFormValidation()) {
				if (assessmentFormDetails && assessmentFormDetails?.length) {
					handleSaveAssessmentAPICall(Enums.TWO, Enums.ONE);
				} else {
					setLoader(false);
				}
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleSaveAssessmentAPICall = async (status: any, performanceType = Enums.ONE) => {
		try {
			const requestPayload = {
				performanceCycleId: performanceCycle?.performanceCycleId || Enums.ZERO,
				ratingAssessmentId: multipleAssessmentResponse?.ratingAssessmentId || Enums.ZERO,
				// employeeId: userDetails?.employeeId || Enums.ZERO,
				// reportingTo: userDetails?.reportingTo || Enums.ZERO,
				ratingAssessmentType: multipleAssessmentResponse?.ratingAssessmentType || Enums.ZERO,
				status: status || Enums.ZERO,
				multiplePerformanceRoleLinkedFormSections: [...assessmentFormDetails],
			};
			const response: any = await dispatch(saveMultipleAssessmentForm(requestPayload));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(
					messages ? messages : status === Enums.TWO ? 'Successfully saved in drafts.' : `Successfully saved.`,
					{
						variant: 'success',
						autoHideDuration: 3000,
					}
				);

				// if (performanceCycle?.performanceCycleId) {
				// 	setLoader(true);
				// 	getSelfAssessmentDetails(
				// 		userDetails?.employeeId || Enums.ZERO,
				// 		performanceCycle?.performanceCycleId || Enums.ZERO,
				// 		performanceType || Enums.ONE
				// 	);
				// }
				setIsFormEdited(false);
				backClicked(true);
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating assessment form.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const checkAssessmentFormValidation = () => {
		let isFormValidated = true;
		let firstErrorQuestion = 0;
		let firstErrorQuestionComment = 0;
		if (assessmentFormDetails && assessmentFormDetails?.length) {
			let updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
				const data = { ...item };
				return data;
			});
			updatedAssessmentFormDetails = [...updatedAssessmentFormDetails].map((section: any) => {
				const sectionData = { ...section };
				if (
					sectionData?.multiplePerformanceRoleLinkedFormDetails &&
					sectionData?.multiplePerformanceRoleLinkedFormDetails?.length
				) {
					sectionData.multiplePerformanceRoleLinkedFormDetails =
						sectionData.multiplePerformanceRoleLinkedFormDetails.map((ques: any) => {
							const quesData = { ...ques };
							quesData.multipleRatingAssessmentDetail =
								quesData?.multipleRatingAssessmentDetail && quesData?.multipleRatingAssessmentDetail?.length
									? quesData?.multipleRatingAssessmentDetail.map((employee: any, employeeIndex: number) => {
											const empData = { ...employee };
											if (
												quesData?.isQuestionsMandatory &&
												(!empData?.ratingValue ||
													(empData?.ratingValue &&
														typeof empData.ratingValue === 'string' &&
														empData.ratingValue.trim() === ''))
											) {
												empData.isQuestionError = true;
												empData.isQuestionErrorMsg = 'This question is mandatory to answer';
												firstErrorQuestion = ++firstErrorQuestion;
												isFormValidated = false;

												if (firstErrorQuestion === 1 && firstErrorQuestionComment === 0) {
													scrollTo(
														`section_question_index_value_${sectionData?.performanceRoleLinkedFormSectionId}_${quesData?.performanceRoleLinkedFormDetailsId}_${quesData?.performanceRoleLinkedFormScaleId}_${empData?.employeeId}`
													);
												}
											}
											if (quesData?.isCommentBoxAvailable && quesData?.isCommentBoxMandatory) {
												if (
													!empData?.questionCommentBox ||
													(empData?.questionCommentBox && empData.questionCommentBox.trim() === '')
												) {
													empData.isCommentExpand = true;
													empData.isQuestionCommentError = true;
													empData.isQuestionCommentErrorMsg = 'This question comment is mandatory to answer';
													firstErrorQuestionComment = ++firstErrorQuestionComment;
													isFormValidated = false;

													if (firstErrorQuestionComment === 1 && firstErrorQuestion === 0) {
														scrollTo(
															`section_question_index_value_${sectionData?.performanceRoleLinkedFormSectionId}_${quesData?.performanceRoleLinkedFormDetailsId}_${quesData?.performanceRoleLinkedFormScaleId}_${empData?.employeeId}`
														);
													}
												}
											}

											return empData;
									  })
									: [];

							return quesData;
						});
				}

				return sectionData;
			});

			setAssessmentFormDetails([...updatedAssessmentFormDetails]);
		}
		return isFormValidated;
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 180 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 100);
	};
	// =========================================================================================
	const handleChangeStarQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'ratingValue',
				value
			);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeStarQuestionHoverValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'starRatingHoverValue',
				value
			);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeScaleQuestionValue = (
		event: any,
		valueIndex: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		isCommitted: boolean,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'ratingValue',
				value,
				isCommitted
			);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeMultiChoiceQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'ratingValue',
				value
			);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeQuestionComment = (
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'questionCommentBox',
				value
			);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeShortQuestionValue = (
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'ratingValue',
				value
			);
		} catch (error) {
			console.error(error);
		}
	};
	const handleCommentsToggle = (
		e: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		employeeDetails: any,
		employeeIndex: number
	) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			updateAssessmentFormDetails(
				{ employeeDetails, employeeIndex },
				sectionIndex,
				questionIndex,
				'isCommentExpand',
				value
			);
		} catch (error) {
			console.error(error);
		}
	};
	const updateAssessmentFormDetails = (
		employeeInfo: any,
		sectionIndex: number,
		questionIndex: number,
		keyName: string,
		value: any,
		scaleCommittedFlag = false
	) => {
		try {
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				const { employeeIndex = 0 } = employeeInfo; // { employeeDetails, employeeIndex } = employeeInfo
				let updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
					const data = { ...item };
					return data;
				});
				updatedAssessmentFormDetails = [...updatedAssessmentFormDetails].map((section: any, indexVal: number) => {
					const sectionData = { ...section };
					if (indexVal === sectionIndex) {
						sectionData.multiplePerformanceRoleLinkedFormDetails =
							sectionData.multiplePerformanceRoleLinkedFormDetails.map((ques: any, quesIndex: number) => {
								const quesData = { ...ques };
								quesData.multipleRatingAssessmentDetail = quesData?.multipleRatingAssessmentDetail.map(
									(emp: any, empIndex: number) => {
										const empDetail = { ...emp };
										if (empIndex === employeeIndex) {
											if (quesIndex === questionIndex && keyName) {
												if (keyName === 'isCommentExpand') {
													empDetail[keyName] = !empDetail?.isCommentExpand;
												} else {
													if (keyName !== 'starRatingHoverValue' || scaleCommittedFlag) {
														empDetail.isQuestionError = false;
														empDetail.isQuestionErrorMsg = '';
													}
													if (keyName === 'questionCommentBox') {
														empDetail.isQuestionCommentError = false;
														empDetail.isQuestionCommentErrorMsg = '';
													}
													if (keyName === 'ratingValue') {
														empDetail[keyName] = value ? String(value) : '';
														if (quesData?.isCommentBoxMandatory && !empDetail?.questionCommentBox) {
															empDetail.isCommentExpand = true;
														}
													} else {
														empDetail[keyName] = value;
													}
												}
											}
										}

										return empDetail;
									}
								);

								return quesData;
							});
					}
					return sectionData;
				});

				// updatedAssessmentFormDetails[sectionIndex].multiplePerformanceRoleLinkedFormDetails =
				// 	updatedAssessmentFormDetails[sectionIndex].multiplePerformanceRoleLinkedFormDetails?.length
				// 		? updatedAssessmentFormDetails[sectionIndex].multiplePerformanceRoleLinkedFormDetails?.map(
				// 				(ques: any, quesIndex: number) => {
				// 					const quesData = { ...ques };
				// 					if (quesIndex === questionIndex && keyName) {
				// 						// if (keyName === 'questionCommentBox')
				// 						if (keyName === 'isCommentExpand') {
				// 							quesData.multipleRatingAssessmentDetail[employeeIndex][keyName] =
				// 								!quesData.multipleRatingAssessmentDetail[employeeIndex]?.isCommentExpand;
				// 						} else {
				// 							if (keyName !== 'starRatingHoverValue' || scaleCommittedFlag) {
				// 								quesData.multipleRatingAssessmentDetail[employeeIndex].isQuestionError = false;
				// 								quesData.multipleRatingAssessmentDetail[employeeIndex].isQuestionErrorMsg = '';
				// 							}
				// 							if (keyName === 'questionCommentBox') {
				// 								quesData.multipleRatingAssessmentDetail[employeeIndex].isQuestionCommentError = false;
				// 								quesData.multipleRatingAssessmentDetail[employeeIndex].isQuestionCommentErrorMsg = '';
				// 							}
				// 							if (keyName === 'ratingValue') {
				// 								quesData.multipleRatingAssessmentDetail[employeeIndex][keyName] = value ? String(value) : '';
				// 								if (
				// 									quesData?.isCommentBoxMandatory &&
				// 									!quesData.multipleRatingAssessmentDetail[employeeIndex]?.questionCommentBox
				// 								) {
				// 									quesData.multipleRatingAssessmentDetail[employeeIndex].isCommentExpand = true;
				// 								}
				// 							} else {
				// 								quesData.multipleRatingAssessmentDetail[employeeIndex][keyName] = value;
				// 							}
				// 						}
				// 					}
				// 					return quesData;
				// 				}
				// 		  )
				// 		: [];
				setAssessmentFormDetails([...updatedAssessmentFormDetails]);
				if (keyName !== 'starRatingHoverValue') {
					setIsFormEdited(true);
				}
			}
			// */
		} catch (error) {
			console.error(error);
		}
	};
	// ===========================================================================
	const handleEmployeeActionClick = (
		e: any,
		type: number,
		section: any,
		sectionIndex: number,
		question: any,
		questionIndex: number,
		employeeDetails: any
	) => {
		try {
			// type 1 (Self Assessment) 2 (Snapshot) 3 (1-on-1)
			if (type === 1) {
				handlePopupClick(e, employeeDetails);
			} else if (type === 2) {
				handleSnapshot(e, employeeDetails);
			} else if (type === 3) {
				handleOneOnOneClick(e, 'MyAssessmentList', employeeDetails?.employeeId);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handlePopupClick = (e: any, details: any) => {
		// e.preventDefault();
		// e.stopPropagation();
		setAssessmentPopup({ open: true, details: details });
	};
	const handleCloseDialog = () => {
		setAssessmentPopup({ open: false, details: null });
	};

	return (
		<>
			<Box className='page-sub-head no-pad-bottom multiple-assess-sub-head'>
				<Box className='sub-head-left'>
					<Box className='back-to-main'>
						<Typography variant='body2'>
							<Button id='back-btn' onClick={backClicked}>
								<BackArrow /> {t('back')}
							</Button>
						</Typography>
					</Box>
					<Typography variant='h6'>Your are taking manager assessment</Typography>
					{/* <Typography className='alert-text' variant='h6'>
						<AlertInfoIcon /> {`7 days Remaining`}
					</Typography> */}
				</Box>
				<div ref={ref1}>
					{/* <Box className='sub-head-right'>
						<FormControlLabel
							control={<Switch disabled checked={true} name='isActive' inputProps={{ 'aria-label': 'controlled' }} />}
							label={t('View all questions')}
							labelPlacement='end'
							className={`toggle-label`}
						/>
					</Box> */}
				</div>
			</Box>
			<div ref={ref}>
				<Box className='user-assessment-section-area'>
					{assessmentFormDetails && assessmentFormDetails?.length ? (
						assessmentFormDetails?.map((section: any, sectionIndex: number) => {
							const { performanceRoleLinkedFormSectionId = 0, multiplePerformanceRoleLinkedFormDetails } = section;
							return (
								<Box
									className='user-assessment-ques-area'
									key={`section_index_value_${performanceRoleLinkedFormSectionId}`}
								>
									<Box className='user-assessment-ques-head'>
										<Typography variant='h4'>{section?.name || ''}</Typography>
										<Typography>{section?.description || ''}</Typography>
									</Box>
									{multiplePerformanceRoleLinkedFormDetails && multiplePerformanceRoleLinkedFormDetails?.length ? (
										multiplePerformanceRoleLinkedFormDetails.map((question: any, questionIndex: number) => {
											const { questionsType, multipleRatingAssessmentDetail } = question;
											return (
												<Box
													key={`section_question_index_value_${performanceRoleLinkedFormSectionId}_${question?.performanceRoleLinkedFormDetailsId}_${question?.performanceRoleLinkedFormScaleId}_`}
													id={`section_question_index_value_${performanceRoleLinkedFormSectionId}_${question?.performanceRoleLinkedFormDetailsId}_${question?.performanceRoleLinkedFormScaleId}_`}
												>
													<Box className='user-assess-ques-list'>
														<Box className={`user-assess-ques-panel light-blue-panel multi-user-assess`}>
															<Box className={'user-assess-ques-type user-assess-ques-rating'}>
																<Box className='user-assess-ques-title'>
																	<Typography variant='h4' className='ques-title font-weight-normal'>
																		<span>{getQuestionNumber(section, question, sectionIndex, questionIndex)}</span>
																		{question?.questionsName || ''}{' '}
																		{question?.isQuestionsMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
																	</Typography>
																</Box>
																<Box className='user-assess-ques-des'>
																	{multipleRatingAssessmentDetail && multipleRatingAssessmentDetail?.length ? (
																		multipleRatingAssessmentDetail.map((employee: any, employeeIndex: number) => {
																			return (
																				<Box
																					className='user-multiple-row-wrap'
																					key={`section_question_index_value_${performanceRoleLinkedFormSectionId}_${question?.performanceRoleLinkedFormDetailsId}_${question?.performanceRoleLinkedFormScaleId}_${employee?.employeeId}`}
																					id={`section_question_index_value_${performanceRoleLinkedFormSectionId}_${question?.performanceRoleLinkedFormDetailsId}_${question?.performanceRoleLinkedFormScaleId}_${employee?.employeeId}`}
																				>
																					{questionsType === Enums.ONE ? (
																						<>
																							<StarRating
																								t={t}
																								section={section}
																								sectionIndex={sectionIndex}
																								questionIndex={questionIndex}
																								question={question}
																								getQuestionNumber={getQuestionNumber}
																								isViewMode={isViewMode}
																								getSelectedStartValueIndex={getSelectedStartValueIndex}
																								getLabelText={getLabelText}
																								handleChangeStarQuestionValue={(
																									event: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeStarQuestionValue(
																										event,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								handleChangeStarQuestionHoverValue={(
																									event: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeStarQuestionHoverValue(
																										event,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								handleCommentsToggle={(
																									e: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleCommentsToggle(
																										e,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								handleChangeQuestionComment={(
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeQuestionComment(
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								isMultipleUserAssessment={true}
																								employeeDetails={employee}
																								handleEmployeeActionClick={handleEmployeeActionClick}
																							/>
																						</>
																					) : questionsType === Enums.TWO ? (
																						<>
																							<MultiChoiceRating
																								t={t}
																								section={section}
																								sectionIndex={sectionIndex}
																								questionIndex={questionIndex}
																								question={question}
																								getQuestionNumber={getQuestionNumber}
																								isViewMode={isViewMode}
																								handleCommentsToggle={(
																									e: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleCommentsToggle(
																										e,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								handleChangeQuestionComment={(
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeQuestionComment(
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								handleChangeMultiChoiceQuestionValue={(
																									event: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeMultiChoiceQuestionValue(
																										event,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								getSelectedMultiChoiceValue={getSelectedMultiChoiceValue}
																								isMultipleUserAssessment={true}
																								employeeDetails={employee}
																								handleEmployeeActionClick={handleEmployeeActionClick}
																							/>
																						</>
																					) : questionsType === Enums.THREE ? (
																						<>
																							<SliderRating
																								t={t}
																								section={section}
																								sectionIndex={sectionIndex}
																								questionIndex={questionIndex}
																								question={question}
																								getQuestionNumber={getQuestionNumber}
																								isViewMode={isViewMode}
																								handleCommentsToggle={(
																									e: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleCommentsToggle(
																										e,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								handleChangeQuestionComment={(
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeQuestionComment(
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								getSelectedScaleValueIndex={getSelectedScaleValueIndex}
																								getDynamicMarks={getDynamicMarks}
																								handleChangeScaleQuestionValue={(
																									event: any,
																									valueIndex: any,
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number,
																									isCommitted: boolean
																								) =>
																									handleChangeScaleQuestionValue(
																										event,
																										valueIndex,
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										isCommitted,
																										employee,
																										employeeIndex
																									)
																								}
																								isMultipleUserAssessment={true}
																								employeeDetails={employee}
																								handleEmployeeActionClick={handleEmployeeActionClick}
																							/>
																						</>
																					) : questionsType === Enums.FOUR ? (
																						<>
																							<ShortAnswerRating
																								t={t}
																								section={section}
																								sectionIndex={sectionIndex}
																								questionIndex={questionIndex}
																								question={question}
																								getQuestionNumber={getQuestionNumber}
																								isViewMode={isViewMode}
																								handleChangeShortQuestionValue={(
																									value: any,
																									question: any,
																									questionIndex: number,
																									section: any,
																									sectionIndex: number
																								) =>
																									handleChangeShortQuestionValue(
																										value,
																										question,
																										questionIndex,
																										section,
																										sectionIndex,
																										employee,
																										employeeIndex
																									)
																								}
																								isMultipleUserAssessment={true}
																								employeeDetails={employee}
																								handleEmployeeActionClick={handleEmployeeActionClick}
																							/>
																						</>
																					) : (
																						<></>
																					)}
																				</Box>
																			);
																		})
																	) : (
																		<></>
																	)}
																</Box>
															</Box>
														</Box>
													</Box>
												</Box>
											);
										})
									) : (
										<></>
									)}
								</Box>
							);
						})
					) : (
						<></>
					)}
					{assessmentFormDetails && assessmentFormDetails?.length ? (
						<>
							<Box className='user-assessment-notes-txt'>
								<Typography variant='subtitle2'>
									{t('note')} {t('prsResponseModifyNote')}
								</Typography>
							</Box>
							<Box className='user-assessment-action multiple-assessment-action'>
								<OKRButton
									handleClick={handleClearAssessmentForm}
									className={'btn-link'}
									id={'btn-delete'}
									text={t('clearForm')}
									disabled={loader || !isFormEdited}
								/>
								<Box className={'action-right'}>
									<OKRButton
										className={'btn-link'}
										id={'btn-save-draft'}
										text={t('saveDraft')}
										disabled={loader}
										handleClick={handleSaveAsDraftAssessmentForm}
									/>
									<OKRButton
										className={'btn-primary'}
										id={'btn-submit'}
										text={t('submit')}
										disabled={loader}
										handleClick={handleSubmitAssessmentForm}
									/>
									{/* <Box className='sliding-btn'>
									<OKRButton
										className={'btn-link prev-link'}
										id={'btn-delete'}
										text={t('Previous')}
										icon={<NextIcon />}
									/>
									<OKRButton className={'btn-link next-link'} id={'btn-delete'} text={t('Next')} icon={<NextIcon />} />
								</Box> */}
								</Box>
							</Box>
						</>
					) : (
						<></>
					)}
				</Box>
			</div>

			{assessmentPopup && assessmentPopup?.open ? (
				<AssessmentPopup
					setAssessmentPopup={setAssessmentPopup}
					assessmentPopup={assessmentPopup}
					handleCloseDialog={handleCloseDialog}
					performanceCycleId={performanceCycleId}
					performanceCycle={performanceCycle}
					getSelfAssessmentDetails={getSelfAssessmentDetails}
					loader={loader}
					setLoader={setLoader}
					selectedUserDetails={assessmentPopup?.details || null}
				/>
			) : (
				<></>
			)}

			{modalProps.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
