import React, { Fragment, useEffect, useState } from 'react';
import { Box, Collapse, Link, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import * as Moment from 'moment';
// import { extendMoment } from 'moment-range';
import { useSnackbar } from 'notistack';
import { BackArrow } from '../../../../../config/svg/GlobalSvg';
import '../../../../../styles/pages/admin/settings/addAddAssessmentForm.scss';
import { useDispatch } from 'react-redux';
// import { getLocalStorageItem } from '../../../../../services/StorageService';
import AlertDialog from '../../../../Common/Dialog';
import { RightArrowIcon } from '../../../../../config/svg/ArrowSvg';
import { OKRButton } from '../../../../Common/OKRButton';
import { DeleteIcon, AddIconSvg, DragIndicator } from '../../../../../config/svg/CommonSvg';
import { getRemainingCharacter } from '../../../../../config/utils';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { Enums } from '../../../../../config/enums';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { QuestionListWrapper } from './QuestionListWrapper';
import { ratingFormCreate, ratingFormUpdate } from '../../../../../action/adminSettings';

export interface RatingFormDetailRequest {
	ratingFormDetailsId: number;
	questionsName: string | null;
	questionsType: number;
	questionsTypeName: string;
	performanceScaleId: number;
	isQuestionsMandatory: boolean;
	isCommentBoxAvailable: boolean;
	isCommentBoxMandatory: boolean;
	sequence: number;
	isSectionQuestionNameError?: boolean;
	sectionQuestionNameErrorMessage?: string | null;
	isSectionQuestionTypeError?: boolean;
	sectionQuestionTypeErrorMessage?: string | null;
	sectionIndexValue?: number;
}
export interface RatingFormSectionRequest {
	ratingFormSectionId: number;
	name: string | null;
	description: string | null;
	sequence: number;
	isSectionEditable?: boolean;
	isDescriptionEditable?: boolean;
	isSectionNameError?: boolean;
	sectionNameErrorMessage?: string | null;
	isSectionDescriptionError?: boolean;
	sectionDescriptionErrorMessage?: string | null;
	isExpand: boolean;
	isQuestionMandatoryError: boolean;
	ratingFormDetailRequest: RatingFormDetailRequest[];
}
export interface AssessmentFormDetails {
	ratingFormId: number;
	isPublish: boolean;
	formName: string | null;
	isEditEnable: boolean;
	isFormNameError?: boolean;
	formNameError?: string | null;
	ratingFormSectionRequest: RatingFormSectionRequest[];
}

export const AddAssessmentForm: React.FC<any> = (props: any) => {
	const {
		setLoader,
		loader,
		type,
		editableFormDetails,
		setIsAssessmentFormEdited,
		isAssessmentFormEdited,
		refObjectAssessmentForm,
		handleBacAssessmentFormButtonClick,
		questionTypeMasterData,
		getManageScaleListDetails,
		manageScaleList,
		handleManageScale,
		setHighlightRecordId,
	} = props;
	const defaultAssessmentFormObject = {
		ratingFormId: 0,
		isPublish: false,
		formName: '',
		isEditEnable: true,
		isFormNameError: false,
		formNameError: '',
		ratingFormSectionRequest: [
			{
				ratingFormSectionId: 0,
				name: '',
				description: '',
				sequence: 1,
				isSectionEditable: true,
				isDescriptionEditable: true,
				isSectionNameError: false,
				sectionNameErrorMessage: '',
				isSectionDescriptionError: false,
				sectionDescriptionErrorMessage: '',
				isExpand: false,
				isQuestionMandatoryError: false,
				ratingFormDetailRequest: [
					{
						ratingFormDetailsId: 0,
						questionsName: '',
						questionsType: 0,
						questionsTypeName: '',
						performanceScaleId: 0,
						isQuestionsMandatory: false,
						isCommentBoxAvailable: false,
						isCommentBoxMandatory: false,
						sequence: 1,
						isSectionQuestionNameError: false,
						sectionQuestionNameErrorMessage: '',
						isSectionQuestionTypeError: false,
						sectionQuestionTypeErrorMessage: '',
					},
				],
			},
		],
	};
	// const { competencyRatingSettingTabValue } = useSelector((state: any) => state?.adminSettingReducer);
	// const {
	// 	performanceReviewRoleList,
	// 	performanceReviewRoleError,
	// 	performanceReviewCoreValueList,
	// 	performanceReviewCoreValueError,
	// } = useSelector((state: any) => state?.adminSettingReducer);
	// const currentCycleDetails = getLocalStorageItem('currentCycle');
	// const moment = extendMoment(Moment);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);
	// const staticErrorObj = {
	// 	error: '',
	// 	helperText: '',
	// 	type: '',
	// };
	// const staticData = {
	// 	selectedUser: [],
	// 	raisedForId: [],
	// };

	// const [open, setOpen] = useState(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '', type });
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, message: '', details: null, type: '' });
	const [assessmentFormDetails, setAssessmentFormDetails] =
		useState<AssessmentFormDetails>(defaultAssessmentFormObject);
	// const [assessmentFormNameError, setAssessmentFormNameError] = useState<any>({
	// 	formName: false,
	// 	errorMessage: '',
	// });
	// const [isAssessmentFormEdited, setIsAssessmentFormEdited] = useState(false);

	// useEffect(() => {
	// 	const requestParam = {
	// 		pageIndex: Enums.ONE,
	// 		pageSize: Enums.MAX_USER_SIZE,
	// 		sortingText: '',
	// 		order: 'asc',
	// 	};
	// 	getManageScaleListDetails(requestParam);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	useEffect(() => {
		if (manageScaleList && manageScaleList?.length) {
			if (type === 'Edit') {
				renderDetails(editableFormDetails);
			} else if (type === 'Clone') {
				renderDetails(editableFormDetails);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const renderDetails = (details: any) => {
		try {
			const object = { ...defaultAssessmentFormObject };
			object.formName = details?.name || '';
			object.isPublish = type === 'Clone' ? false : details?.isPublish;
			object.ratingFormId = type === 'Edit' ? details?.ratingFormId : 0;
			object.ratingFormSectionRequest =
				details?.ratingFormSectionResponse && details?.ratingFormSectionResponse?.length
					? details?.ratingFormSectionResponse.map((rec: any) => {
							const data = {
								ratingFormSectionId: type === 'Edit' ? rec?.ratingFormSectionId : 0,
								name: rec?.name || '',
								description: rec?.description || '',
								sequence: rec?.sequence || 1,
								isSectionEditable: true,
								isDescriptionEditable: true,
								isSectionNameError: false,
								sectionNameErrorMessage: '',
								isSectionDescriptionError: false,
								sectionDescriptionErrorMessage: '',
								isExpand: type === 'Clone' ? false : true,
								isQuestionMandatoryError: false,
								ratingFormDetailRequest:
									rec?.ratingFormDetailsResponse && rec?.ratingFormDetailsResponse?.length
										? rec?.ratingFormDetailsResponse.map((item: any) => {
												const itemX = {
													ratingFormDetailsId: type === 'Edit' ? item?.ratingFormDetailsId : 0,
													questionsName: item?.questionsName || '',
													questionsType: item?.questionsType || 0,
													questionsTypeName:
														item?.questionsType && questionTypeMasterData && questionTypeMasterData?.length
															? questionTypeMasterData.find((it: any) => it.questionTypeId === item?.questionsType)
																	?.name
															: '',
													performanceScaleId: item?.performanceScaleResponse?.performanceScaleId || 0,
													isQuestionsMandatory: item?.isQuestionsMandatory,
													isCommentBoxAvailable: item?.isCommentBoxAvailable,
													isCommentBoxMandatory: item?.isCommentBoxMandatory,
													sequence: item?.sequence || 1,
													isSectionQuestionNameError: false,
													sectionQuestionNameErrorMessage: '',
													isSectionQuestionTypeError: false,
													sectionQuestionTypeErrorMessage: '',
												};
												return itemX;
										  })
										: [
												{
													ratingFormDetailsId: 0,
													questionsName: '',
													questionsType: 0,
													questionsTypeName: '',
													performanceScaleId: 0,
													isQuestionsMandatory: false,
													isCommentBoxAvailable: false,
													isCommentBoxMandatory: false,
													sequence: 1,
													isSectionQuestionNameError: false,
													sectionQuestionNameErrorMessage: '',
													isSectionQuestionTypeError: false,
													sectionQuestionTypeErrorMessage: '',
												},
										  ],
							};
							return data;
					  })
					: object.ratingFormSectionRequest;

			// console.log(object);
			setAssessmentFormDetails({ ...object });
		} catch (error) {
			console.error(error);
		}
	};
	const getScaleListByType = (scaleName: any, scaleType: any) => {
		try {
			return manageScaleList && manageScaleList?.length
				? manageScaleList.filter((item: any) => item?.questionTypeId === scaleType && item?.status === 1)
				: [];
		} catch (error) {
			console.error(error);
			return [];
		}
	};
	const handleBackButtonOnClick = (direct = false) => {
		if (isAssessmentFormEdited && direct === false) {
			setModalProps({ open: true, message: t('unSavedItemAlert'), details: '', type: 'unSave' });
		} else {
			setIsAssessmentFormEdited(false);
			handleBacAssessmentFormButtonClick();
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			const { type } = modalProps || {};
			if (type === 'unSave') {
				setIsAssessmentFormEdited(false);
				handleBacAssessmentFormButtonClick();
			} else if (type === 'QuestionError') {
			}
			setModalProps({ open: false, message: '', details: '', type: '' });
		} else {
			setModalProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const handleSectionClick = (e: any, sectionIndex: number, sectionId: any) => {
		e.preventDefault();
		e.stopPropagation();
		let element = document.getElementById(sectionId);
		let currentTop = 0;
		if (element) {
			currentTop = element.getBoundingClientRect().top;
		}
		const updatedAssessmentFormDetails = { ...assessmentFormDetails };
		const expandDetails = updatedAssessmentFormDetails.ratingFormSectionRequest[sectionIndex].isExpand;
		updatedAssessmentFormDetails.ratingFormSectionRequest[sectionIndex].isExpand =
			!updatedAssessmentFormDetails.ratingFormSectionRequest[sectionIndex].isExpand;
		setAssessmentFormDetails({ ...updatedAssessmentFormDetails });
		if (!expandDetails) {
			setTimeout(() => {
				if (element) {
					const newTop = element.getBoundingClientRect().top;
					const scrollDifference = newTop - currentTop;

					window.scrollBy(0, scrollDifference);
				}
			}, 400);
		}
		//setOpen(!open);
	};

	const handleEditFormName = (event: any) => {
		// const updatedAssessmentFormDetails = { ...assessmentFormDetails };
		setAssessmentFormDetails({ ...assessmentFormDetails, isEditEnable: true });
	};
	const handleDeleteSection = (event: any, section: RatingFormSectionRequest, sectionIndex: number) => {
		event.preventDefault();
		event.stopPropagation();
		setOpenDeleteDialog({
			open: true,
			message: t('deleteRatingScale'),
			details: { section, sectionIndex },
			type: 'Section',
		});
	};
	const handleDeleteSectionDetails = (section: RatingFormSectionRequest, sectionIndex: number) => {
		try {
			const updatedAssessmentFormDetails = { ...assessmentFormDetails };
			const { ratingFormSectionRequest } = updatedAssessmentFormDetails || {};
			let copyRatingFormSectionRequest =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			copyRatingFormSectionRequest = copyRatingFormSectionRequest
				.filter((itemX: RatingFormSectionRequest, indexVal: number) => indexVal !== sectionIndex)
				?.map((item: RatingFormSectionRequest, indexVal: number) => {
					item.sequence = indexVal + 1;
					return item;
				});
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
			setIsAssessmentFormEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const handleAddNewQuestionSection = (event: any, section: RatingFormSectionRequest, sectionIndex: number) => {
		try {
			const updatedAssessmentFormDetails = { ...assessmentFormDetails };
			const { ratingFormSectionRequest } = updatedAssessmentFormDetails || {};
			let copyRatingFormSectionRequest =
				ratingFormSectionRequest && ratingFormSectionRequest?.length
					? ratingFormSectionRequest.map((item: RatingFormSectionRequest, indexVal: number) => {
							let copyRatingFormDetailRequest =
								item && item?.ratingFormDetailRequest && item?.ratingFormDetailRequest?.length
									? [...item?.ratingFormDetailRequest]
									: [];
							if (indexVal === sectionIndex) {
								const lastIndex =
									copyRatingFormDetailRequest && copyRatingFormDetailRequest?.length > 0
										? copyRatingFormDetailRequest[copyRatingFormDetailRequest.length - 1]
										: null;
								copyRatingFormDetailRequest?.push({
									ratingFormDetailsId: 0,
									questionsName: '',
									questionsType: 0,
									questionsTypeName: '',
									performanceScaleId: 0,
									isQuestionsMandatory: false,
									isCommentBoxAvailable: false,
									isCommentBoxMandatory: false,
									sequence: lastIndex && lastIndex?.sequence ? lastIndex?.sequence + 1 : 1,
									isSectionQuestionNameError: false,
									sectionQuestionNameErrorMessage: '',
									isSectionQuestionTypeError: false,
									sectionQuestionTypeErrorMessage: '',
								});
							}
							return { ...item, ratingFormDetailRequest: [...copyRatingFormDetailRequest] };
					  })
					: null;
			if (copyRatingFormSectionRequest && copyRatingFormSectionRequest?.length) {
				setAssessmentFormDetails({
					...assessmentFormDetails,
					ratingFormSectionRequest: [...copyRatingFormSectionRequest],
				});
			}
			setIsAssessmentFormEdited(true);
		} catch (error) {
			// setIsAssessmentFormEdited(true);
			console.error(error);
		}
	};
	const handleAddNewSection = (event: any, section: RatingFormSectionRequest, sectionIndex: number) => {
		try {
			const sectionObjectToInsert = {
				ratingFormSectionId: 0,
				name: '',
				description: '',
				sequence: 1,
				isSectionEditable: true,
				isDescriptionEditable: true,
				isSectionNameError: false,
				sectionNameErrorMessage: '',
				isSectionDescriptionError: false,
				sectionDescriptionErrorMessage: '',
				isExpand: true,
				ratingFormDetailRequest: [
					{
						ratingFormDetailsId: 0,
						questionsName: '',
						questionsType: 0,
						questionsTypeName: '',
						performanceScaleId: 0,
						isQuestionsMandatory: false,
						isCommentBoxAvailable: false,
						isCommentBoxMandatory: false,
						sequence: 1,
						isSectionQuestionNameError: false,
						sectionQuestionNameErrorMessage: '',
						isSectionQuestionTypeError: false,
						sectionQuestionTypeErrorMessage: '',
					},
				],
			};
			const updatedAssessmentFormDetails = { ...assessmentFormDetails };
			const { ratingFormSectionRequest } = updatedAssessmentFormDetails || {};
			let copyRatingFormSectionRequest: any[] = [];
			if (ratingFormSectionRequest && ratingFormSectionRequest?.length) {
				copyRatingFormSectionRequest = [...ratingFormSectionRequest];
				copyRatingFormSectionRequest.splice(sectionIndex + 1, 0, sectionObjectToInsert);
				copyRatingFormSectionRequest = copyRatingFormSectionRequest?.map(
					(item: RatingFormSectionRequest, indexVal: number) => {
						item.sequence = indexVal + 1;
						return item;
					}
				);
			}
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
			setIsAssessmentFormEdited(true);
		} catch (error) {
			// setIsAssessmentFormEdited(true);
			console.error(error);
		}
	};
	const handleEditSectionName = (event: any, section: RatingFormSectionRequest, sectionIndex: number) => {
		event.preventDefault();
		event.stopPropagation();
		const updatedAssessmentFormDetails = { ...assessmentFormDetails };
		updatedAssessmentFormDetails.ratingFormSectionRequest[sectionIndex].isSectionEditable = true;
		setAssessmentFormDetails({ ...updatedAssessmentFormDetails });
		setIsAssessmentFormEdited(true);
	};
	const handleEditSectionDescription = (event: any, section: RatingFormSectionRequest, sectionIndex: number) => {
		event.preventDefault();
		event.stopPropagation();
		const updatedAssessmentFormDetails = { ...assessmentFormDetails };
		updatedAssessmentFormDetails.ratingFormSectionRequest[sectionIndex].isDescriptionEditable = true;
		setAssessmentFormDetails({ ...updatedAssessmentFormDetails });
		setIsAssessmentFormEdited(true);
	};
	const handleAssessmentFormNameChange = (event: any) => {
		try {
			const { name, value } = event?.target || {};
			setAssessmentFormDetails({
				...assessmentFormDetails,
				[name]: value,
				formNameError: '',
				isFormNameError: false,
				// ratingFormSectionRequest: assessmentFormDetails?.formName
				// 	? assessmentFormDetails?.ratingFormSectionRequest
				// 	: assessmentFormDetails?.ratingFormSectionRequest?.length
				// 	? assessmentFormDetails?.ratingFormSectionRequest.map((rec: RatingFormSectionRequest) => {
				// 			rec.isExpand = true;
				// 			return rec;
				// 	  })
				// 	: assessmentFormDetails?.ratingFormSectionRequest,
			});
			setIsAssessmentFormEdited(true);
		} catch (e) {
			setAssessmentFormDetails({ ...assessmentFormDetails, formName: '' });
			setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const updateFormSection = (section: RatingFormSectionRequest, key: string, value: any, updateType: any) => {
		try {
			const { ratingFormSectionRequest } = assessmentFormDetails;
			let copyRatingFormSectionRequest =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			if (updateType === 'section') {
				copyRatingFormSectionRequest = copyRatingFormSectionRequest.map((rec: RatingFormSectionRequest) => {
					if (rec?.sequence === section?.sequence) {
						if (key === 'name') {
							rec.name = value;
							rec.isSectionNameError = false;
							rec.sectionNameErrorMessage = '';
						}
						if (key === 'description') {
							rec.description = value;
							rec.isSectionDescriptionError = false;
							rec.sectionDescriptionErrorMessage = '';
						}
						rec.isExpand = true;
					}
					return rec;
				});
			}
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
			setIsAssessmentFormEdited(true);
		} catch (error) {
			// setIsAssessmentFormEdited(true);
			console.error(error);
		}
	};
	const handleFormSectionNameChange = (event: any, section: RatingFormSectionRequest) => {
		event.preventDefault();
		event.stopPropagation();
		const { value } = event?.target || {};
		updateFormSection(section, 'name', value, 'section');
		// setOpen(true);
	};
	const handleFormSectionDescriptionChange = (event: any, section: RatingFormSectionRequest) => {
		event.preventDefault();
		event.stopPropagation();
		const { value } = event?.target || {};
		updateFormSection(section, 'description', value, 'section');
		// setOpen(true);
	};
	// =================================================================
	const updateQuestionDetails = (
		section: RatingFormSectionRequest,
		key: string,
		value: any,
		updateType: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		sectionIndex: number
	) => {
		try {
			const { ratingFormSectionRequest } = assessmentFormDetails;
			let copyRatingFormSectionRequest =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? ratingFormSectionRequest : [];
			if (updateType === 'question') {
				const sectionDetails = copyRatingFormSectionRequest[sectionIndex];
				const { ratingFormDetailRequest } = sectionDetails || {};
				const questionDetails =
					ratingFormDetailRequest && ratingFormDetailRequest?.length ? ratingFormDetailRequest[questionIndex] : null;
				if (questionDetails) {
					if (key === 'questionsName') {
						questionDetails.questionsName = value;
						questionDetails.isSectionQuestionNameError = false;
						questionDetails.sectionQuestionNameErrorMessage = '';
					}
					if (key === 'questionsType') {
						questionDetails.questionsType = value;
						questionDetails.isSectionQuestionTypeError = false;
						questionDetails.sectionQuestionTypeErrorMessage = '';
					}
					if (key === 'questionsTypeName') {
						questionDetails.questionsTypeName = value;
					}
					if (key === 'performanceScaleId') {
						questionDetails.performanceScaleId = value;
					}
					if (key === 'isQuestionsMandatory') {
						questionDetails.isQuestionsMandatory = value;
						sectionDetails.isQuestionMandatoryError = false;
					}
					if (key === 'isCommentBoxAvailable') {
						questionDetails.isCommentBoxAvailable = value;
					}
					if (key === 'isCommentBoxMandatory') {
						questionDetails.isCommentBoxMandatory = value;
					}
				}
			}
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
		} catch (error) {
			console.error(error);
		}
	};
	const handleDeleteQuestion = (
		e: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		e.preventDefault();
		e.stopPropagation();
		setOpenDeleteDialog({
			open: true,
			message: t('deleteRatingScale'),
			details: { question, questionIndex, section, sectionIndex },
			type: 'Question',
		});
	};
	const handleDeleteQuestionDetails = (
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		try {
			const updatedAssessmentFormDetails = { ...assessmentFormDetails };
			const { ratingFormSectionRequest } = updatedAssessmentFormDetails || {};
			let copyRatingFormSectionRequest =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			copyRatingFormSectionRequest = copyRatingFormSectionRequest?.map(
				(item: RatingFormSectionRequest, secIndex: number) => {
					if (secIndex === sectionIndex) {
						item.ratingFormDetailRequest = item?.ratingFormDetailRequest
							?.filter((itemX: RatingFormDetailRequest, indexVal: number) => indexVal !== questionIndex)
							?.map((item: RatingFormDetailRequest, indexVal: number) => {
								item.sequence = indexVal + 1;
								return item;
							});
					}
					return item;
				}
			);
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
			setIsAssessmentFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleFormSectionQuestionNameChange = (
		e: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		try {
			const { value } = e?.target || {};
			updateQuestionDetails(section, 'questionsName', value, 'question', question, questionIndex, sectionIndex);
			setIsAssessmentFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleFormSectionQuestionScaleTypeChange = (
		id = 0,
		name: '',
		scaleType: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		try {
			updateQuestionDetails(section, 'questionsType', id, 'question', question, questionIndex, sectionIndex);
			updateQuestionDetails(section, 'questionsTypeName', name, 'question', question, questionIndex, sectionIndex);
			const selectedItem = getScaleListByType(name, id);
			const firstItemDetails = selectedItem && selectedItem?.length > 0 ? selectedItem[0] : null;
			updateQuestionDetails(
				section,
				'performanceScaleId',
				firstItemDetails?.performanceScaleId || 0,
				'question',
				question,
				questionIndex,
				sectionIndex
			);
			updateQuestionDetails(section, 'isQuestionsMandatory', false, 'question', question, questionIndex, sectionIndex);
			updateQuestionDetails(section, 'isCommentBoxAvailable', false, 'question', question, questionIndex, sectionIndex);
			updateQuestionDetails(section, 'isCommentBoxMandatory', false, 'question', question, questionIndex, sectionIndex);
			setIsAssessmentFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleIsQuestionMandatory = (
		e: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		try {
			const { checked } = e?.target || {};
			updateQuestionDetails(
				section,
				'isQuestionsMandatory',
				checked,
				'question',
				question,
				questionIndex,
				sectionIndex
			);
			if (!checked) {
				// updateQuestionDetails(
				// 	section,
				// 	'isCommentBoxAvailable',
				// 	false,
				// 	'question',
				// 	question,
				// 	questionIndex,
				// 	sectionIndex
				// );
				// updateQuestionDetails(
				// 	section,
				// 	'isCommentBoxMandatory',
				// 	false,
				// 	'question',
				// 	question,
				// 	questionIndex,
				// 	sectionIndex
				// );
			}
			setIsAssessmentFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleIsCommentBoxAvailable = (
		e: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		try {
			const { checked } = e?.target || {};
			updateQuestionDetails(
				section,
				'isCommentBoxAvailable',
				checked,
				'question',
				question,
				questionIndex,
				sectionIndex
			);
			if (!checked) {
				updateQuestionDetails(
					section,
					'isCommentBoxMandatory',
					false,
					'question',
					question,
					questionIndex,
					sectionIndex
				);
			}
			setIsAssessmentFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleIsCommentBoxMandatory = (
		e: any,
		question: RatingFormDetailRequest,
		questionIndex: number,
		section: RatingFormSectionRequest,
		sectionIndex: number
	) => {
		try {
			const { checked } = e?.target || {};
			updateQuestionDetails(
				section,
				'isCommentBoxMandatory',
				checked,
				'question',
				question,
				questionIndex,
				sectionIndex
			);
			setIsAssessmentFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleScaleNameChange = (
		e: any,
		item: any,
		questionIndex: number,
		sectionIndex: number,
		question: RatingFormDetailRequest,
		section: RatingFormSectionRequest
	) => {
		try {
			updateQuestionDetails(
				section,
				'performanceScaleId',
				item?.performanceScaleId || 0,
				'question',
				question,
				questionIndex,
				sectionIndex
			);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	// =========================================================== \\
	// ======== Question Draggable Method Start here ============= \\
	// =========================================================== \\
	const getItemStyle = (isDragging: any, draggableStyle: any) => ({
		// styles we need to apply on draggable
		...draggableStyle,
		...(isDragging && {
			background: 'rgb(235,235,235)',
		}),
	});
	const reorder = (list: any, startIndex: number, endIndex: number) => {
		try {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		} catch (e) {
			console.error(e);
			return [];
		}
	};
	const onDragEnd = (result: any, questionList: any, sectionIndex: number) => {
		try {
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}

			const items = reorder(questionList, result.source.index, result.destination.index);
			const data = items.map((ele: any, indexVal: number) => {
				ele.sequence = indexVal + 1;
				return ele;
			});

			const updatedAssessmentFormDetails = { ...assessmentFormDetails };
			const { ratingFormSectionRequest } = updatedAssessmentFormDetails || {};
			let copyRatingFormSectionRequest =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			copyRatingFormSectionRequest = copyRatingFormSectionRequest?.map(
				(item: RatingFormSectionRequest, indexVal: number) => {
					if (indexVal === sectionIndex) {
						item.ratingFormDetailRequest = data && data?.length ? data : item.ratingFormDetailRequest;
					}

					return item;
				}
			);
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
			setIsAssessmentFormEdited(true);
		} catch (e) {
			console.error(e);
		}
	};
	// =========================================================== \\
	// ======== Question Draggable Method End here =============== \\
	// =========================================================== \\
	const onDragEndSection = (result: any, sectionList: RatingFormSectionRequest[]) => {
		try {
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}

			const items = reorder(sectionList, result.source.index, result.destination.index);
			const data = items.map((ele: any, indexVal: number) => {
				ele.sequence = indexVal + 1;
				return ele;
			});

			const updatedAssessmentFormDetails = { ...assessmentFormDetails };
			const { ratingFormSectionRequest } = updatedAssessmentFormDetails || {};
			let copyRatingFormSectionRequest = data && data?.length ? data : ratingFormSectionRequest;
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: [...copyRatingFormSectionRequest],
			});
			setIsAssessmentFormEdited(true);
		} catch (e) {
			console.error(e);
		}
	};
	const validateAssessmentForm = () => {
		if (!assessmentFormDetails?.formName) {
			setAssessmentFormDetails({
				...assessmentFormDetails,
				isFormNameError: true,
				formNameError: 'Add a form name',
			});

			return false;
		} else if (assessmentFormDetails?.formName && assessmentFormDetails?.formName?.trim() === '') {
			setAssessmentFormDetails({
				...assessmentFormDetails,
				isFormNameError: true,
				formNameError: 'Add a form name',
			});

			return false;
		} else {
			return true;
		}
	};
	const validateFormSection = () => {
		try {
			const { ratingFormSectionRequest } = assessmentFormDetails;
			const ratingScaleDetailsData =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			let updatedRatingScaleDetailsData = ratingScaleDetailsData;
			updatedRatingScaleDetailsData = ratingScaleDetailsData?.map((rating: RatingFormSectionRequest) => {
				const data = { ...rating };
				if (!data?.name || (data?.name && data?.name?.trim() === '')) {
					data.isSectionNameError = true;
					data.sectionNameErrorMessage = null;
				}
				// Added check for Section Description
				// if (!data?.description || (data?.description && data?.description?.trim() === '')) {
				// 	data.isSectionDescriptionError = true;
				// 	data.sectionDescriptionErrorMessage = null;
				// }

				return data;
			});
			const firstErrorIndex = updatedRatingScaleDetailsData?.findIndex(
				(rec: RatingFormSectionRequest) => rec?.isSectionNameError
			);
			const firstDescriptionErrorIndex = updatedRatingScaleDetailsData?.findIndex(
				(rec: RatingFormSectionRequest) => rec?.isSectionDescriptionError
			);
			updatedRatingScaleDetailsData = updatedRatingScaleDetailsData?.map((rating: any, index: number) => {
				const data = { ...rating };
				if (data?.isSectionNameError && index === firstErrorIndex) {
					data.sectionNameErrorMessage = 'Section name is mandatory';
					moveScrollToBox(`form_section_item_index_${index}`);
				}
				// Added check for Section Description
				// if (data?.isSectionDescriptionError && index === firstDescriptionErrorIndex) {
				// 	data.sectionDescriptionErrorMessage = 'Section description is mandatory';
				// 	moveScrollToBox(`form_section_item_index_${index}`);
				// }
				// Duplicate label description

				return data;
			});
			const isErrorAvailable = updatedRatingScaleDetailsData.find(
				(rec: RatingFormSectionRequest) => rec.isSectionNameError || rec.isSectionDescriptionError
			);
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: updatedRatingScaleDetailsData,
			});
			return !Boolean(isErrorAvailable);
		} catch (error) {
			return false;
		}
	};
	const validateSectionQuestion = () => {
		try {
			let isErrorAvailable = true;
			let errorCount = 0;
			let errorTypeCount = 0;
			const { ratingFormSectionRequest } = assessmentFormDetails;
			const ratingScaleDetailsData =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			let updatedRatingScaleDetailsData = ratingScaleDetailsData;
			updatedRatingScaleDetailsData = ratingScaleDetailsData?.map(
				(rating: RatingFormSectionRequest, sectionIndex: number) => {
					const data = { ...rating };
					data.ratingFormDetailRequest.map((question: RatingFormDetailRequest, questionIndex: number) => {
						if (!question?.questionsName || (question?.questionsName && question?.questionsName?.trim() === '')) {
							question.isSectionQuestionNameError = true;
							if (errorCount === 0) {
								question.sectionQuestionNameErrorMessage = 'Question name is mandatory';
								moveScrollToBox(`form_section_item_index_${sectionIndex}_question_index_${questionIndex}`);
							} else {
								question.sectionQuestionNameErrorMessage = null;
							}
							isErrorAvailable = false;
							errorCount = errorCount + 1;
						}
						if (!question?.questionsType) {
							question.isSectionQuestionTypeError = true;
							if (errorTypeCount === 0) {
								question.sectionQuestionTypeErrorMessage = 'Question type is mandatory';
								moveScrollToBox(`form_section_item_index_${sectionIndex}_question_index_${questionIndex}`);
							} else {
								question.sectionQuestionTypeErrorMessage = null;
							}
							isErrorAvailable = false;
							errorTypeCount = errorTypeCount + 1;
						}
						return question;
					});

					return data;
				}
			);
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: updatedRatingScaleDetailsData,
			});

			return isErrorAvailable;
		} catch (error) {
			return false;
		}
	};
	const validateSectionRatingQuestionMandatory = () => {
		try {
			let isErrorAvailable = null;
			const { ratingFormSectionRequest } = assessmentFormDetails;
			const ratingScaleDetailsData =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			let updatedRatingScaleDetailsData = ratingScaleDetailsData;
			const allQuestions: RatingFormDetailRequest[] = [];

			updatedRatingScaleDetailsData = updatedRatingScaleDetailsData?.map(
				(rating: RatingFormSectionRequest, sectionIndex: number) => {
					const data = { ...rating };
					data.ratingFormDetailRequest?.map((question: RatingFormDetailRequest) => {
						allQuestions.push({ ...question, sectionIndexValue: sectionIndex });
					});
					const isQuesMandatory = data.ratingFormDetailRequest?.find(
						(question: RatingFormDetailRequest) =>
							(question.questionsType === Enums.ONE ||
								question.questionsType === Enums.TWO ||
								question.questionsType === Enums.THREE) &&
							question?.isQuestionsMandatory
					);
					if (isQuesMandatory) {
						data.isQuestionMandatoryError = false;
					} else {
						data.isQuestionMandatoryError = true;
						// isErrorAvailable = true;
					}
					return data;
				}
			);

			const checkAnyoneMandatoryQuestion = allQuestions?.find(
				(question: RatingFormDetailRequest) =>
					(question.questionsType === Enums.ONE ||
						question.questionsType === Enums.TWO ||
						question.questionsType === Enums.THREE) &&
					question.isQuestionsMandatory
			);
			if (!checkAnyoneMandatoryQuestion) {
				isErrorAvailable = true;
			}
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: updatedRatingScaleDetailsData,
			});
			// ==============================================================================================
			// ==============================================================================================
			// updatedRatingScaleDetailsData?.forEach((rating: RatingFormSectionRequest, sectionIndex: number) => {
			// 	const data = { ...rating };
			// 	data.ratingFormDetailRequest?.map((question: RatingFormDetailRequest) => {
			// 		allQuestions.push({ ...question, sectionIndexValue: sectionIndex });
			// 	});
			// });
			// const nonMandatoryQuestion = allQuestions?.find(
			// 	(question: RatingFormDetailRequest) =>
			// 		(question.questionsType === Enums.ONE ||
			// 			question.questionsType === Enums.TWO ||
			// 			question.questionsType === Enums.THREE) &&
			// 		question.isQuestionsMandatory
			// );
			// updatedRatingScaleDetailsData = ratingScaleDetailsData?.map(
			// 	(rating: RatingFormSectionRequest, sectionIndex: number) => {
			// 		const data = { ...rating };
			// 		if (nonMandatoryQuestion) {
			// 			data.isQuestionMandatoryError = false;
			// 		} else {
			// 			data.isQuestionMandatoryError = true;
			// 		}
			// 		return data;
			// 	}
			// );
			// isErrorAvailable = updatedRatingScaleDetailsData?.find(
			// 	(rating: RatingFormSectionRequest) => rating.isQuestionMandatoryError
			// );

			return !Boolean(isErrorAvailable);
		} catch (error) {
			return false;
		}
	};
	const validateSectionQuestionMandatory = () => {
		try {
			let isErrorAvailable = null;
			const { ratingFormSectionRequest } = assessmentFormDetails;
			const ratingScaleDetailsData =
				ratingFormSectionRequest && ratingFormSectionRequest?.length ? [...ratingFormSectionRequest] : [];
			let updatedRatingScaleDetailsData = ratingScaleDetailsData;
			const allQuestions: RatingFormDetailRequest[] = [];
			ratingScaleDetailsData?.forEach((rating: RatingFormSectionRequest, sectionIndex: number) => {
				const data = { ...rating };
				data.ratingFormDetailRequest?.map((question: RatingFormDetailRequest) => {
					allQuestions.push({ ...question, sectionIndexValue: sectionIndex });
				});
			});
			const nonMandatoryQuestion = allQuestions?.filter(
				(question: RatingFormDetailRequest) =>
					(question.questionsType === Enums.ONE ||
						question.questionsType === Enums.TWO ||
						question.questionsType === Enums.THREE) &&
					!question.isQuestionsMandatory
			);

			updatedRatingScaleDetailsData = ratingScaleDetailsData?.map(
				(rating: RatingFormSectionRequest, sectionIndex: number) => {
					const data = { ...rating };
					const asd = nonMandatoryQuestion.find(
						(question: RatingFormDetailRequest) => question.sectionIndexValue === sectionIndex
					);
					if (asd && asd.sectionIndexValue === sectionIndex) {
						data.isQuestionMandatoryError = true;
					} else {
						data.isQuestionMandatoryError = false;
					}
					return data;
				}
			);
			isErrorAvailable = updatedRatingScaleDetailsData?.find(
				(rating: RatingFormSectionRequest) => rating.isQuestionMandatoryError
			);
			setAssessmentFormDetails({
				...assessmentFormDetails,
				ratingFormSectionRequest: updatedRatingScaleDetailsData,
			});

			return !Boolean(isErrorAvailable);
		} catch (error) {
			return false;
		}
	};
	const handleAssessmentFormSaveAsDraft = async (e: any) => {
		try {
			if (validateAssessmentForm()) {
				setLoader(true);
				const requestPayload = { ...assessmentFormDetails, isPublish: false };
				if (assessmentFormDetails && assessmentFormDetails?.ratingFormId) {
					const response: any = await dispatch(ratingFormUpdate(requestPayload));
					createUpdateAPIResponse(response, 'Draft');
				} else {
					const response: any = await dispatch(ratingFormCreate(requestPayload));
					createUpdateAPIResponse(response, 'Draft');
				}
			} else {
				moveScrollToBox('assessment_form_name_header');
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleAssessmentFormPublish = async (e: any) => {
		try {
			if (validateAssessmentForm()) {
				if (validateFormSection()) {
					if (validateSectionQuestion()) {
						if (validateSectionRatingQuestionMandatory()) {
							// if (validateSectionQuestionMandatory()) {
							setLoader(true);
							const requestPayload = { ...assessmentFormDetails, isPublish: true };
							if (assessmentFormDetails && assessmentFormDetails?.ratingFormId) {
								const response: any = await dispatch(ratingFormUpdate(requestPayload));
								createUpdateAPIResponse(response, 'Publish');
							} else {
								const response: any = await dispatch(ratingFormCreate(requestPayload));
								createUpdateAPIResponse(response, 'Publish');
							}
							// } else {
							// 	setModalProps({
							// 		open: true,
							// 		message: 'Question needs to be mandatory',
							// 		details: '',
							// 		type: 'QuestionError',
							// 	});
							// }
						} else {
							setModalProps({
								open: true,
								// message: 'There should be at least one mandatory rating question',
								message: 'There should be at least one mandatory rating question in the form.',
								details: '',
								type: 'QuestionError',
							});
						}
					}
				}
			} else {
				moveScrollToBox('assessment_form_name_header');
			}
		} catch (error) {
			console.error(error);
		}
	};
	const createUpdateAPIResponse = (response: any, type: string) => {
		if (response) {
			setLoader(false);
			if (response?.data && response?.data?.status === 200) {
				setIsAssessmentFormEdited(false);
				handleBackButtonOnClick(true);
				if (type !== 'Edit' && response?.data?.entity) {
					setHighlightRecordId(response?.data?.entity);
				}
				// const { messageList } = response.data;
				// const keys = Object.keys(messageList);
				// const messages = keys.map((item) => messageList[item]);
				// enqueueSnackbar(messages || 'Rating form created successfully', {
				// 	variant: 'success',
				// 	autoHideDuration: 3000,
				// });
				// setAssessmentTabSelected({ activeTab: 0 });
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || 'Error occurred while adding assessment form', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		} else {
			setLoader(false);
		}
	};

	const handleDialogDeleteClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			if (openDeleteDialog?.type === 'Section') {
				const { section, sectionIndex } = openDeleteDialog?.details || {};
				if (section) {
					setOpenDeleteDialog({ open: false, message: '', details: null, type: '' });
					handleDeleteSectionDetails(section, sectionIndex);
				}
			} else if (openDeleteDialog?.type === 'Question') {
				const { question, questionIndex, section, sectionIndex } = openDeleteDialog?.details || {};
				if (question && section) {
					setOpenDeleteDialog({ open: false, message: '', details: null, type: '' });
					handleDeleteQuestionDetails(question, questionIndex, section, sectionIndex);
				}
			}
		} else {
			setOpenDeleteDialog({ open: false, message: '', details: null, type: '' });
		}
	};
	const moveScrollToBox = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	return (
		<>
			<div ref={refObjectAssessmentForm}>
				<Box className='add-assessment-form'>
					<Box className='admin-tabpanel-inner' id='assessment_form_name_header'>
						<Box className='admin-tabpanel-head'>
							<Box className='back-to-main'>
								<Typography variant='body2'>
									<Link id='back-btn' onClick={(e) => handleBackButtonOnClick(false)}>
										<BackArrow /> {t('back')}
									</Link>
								</Typography>
							</Box>
							<Typography variant='h2'>{'Assessment Form'}</Typography>
						</Box>
					</Box>
					{/* assessment title start */}
					<Box className='assessment-card'>
						<Box className='assessment-form-title'>
							{!assessmentFormDetails?.isEditEnable ? (
								<Box className='title-read-mode' onClick={handleEditFormName}>
									<Typography variant='h4'>{assessmentFormDetails?.formName || ''}</Typography>
								</Box>
							) : (
								<></>
							)}
							{assessmentFormDetails?.isEditEnable ? (
								<Box className='title-edit-mode'>
									<TextField
										id='add-assessment-form-name'
										className='error-top-pos'
										maxRows={3}
										multiline
										placeholder={t('addAssessmentFormName')}
										value={assessmentFormDetails?.formName || ''}
										name={'formName'}
										error={assessmentFormDetails?.isFormNameError || false}
										helperText={
											assessmentFormDetails?.isFormNameError && assessmentFormDetails?.formNameError ? (
												<>
													<ErrorIcon /> {assessmentFormDetails?.formNameError || ''}
												</>
											) : null
										}
										onChange={handleAssessmentFormNameChange}
										inputProps={{ maxLength: 50 }}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{ 'aria-label': t('addAssessmentFormName'), disableUnderline: true }}
									/>
									<Typography variant='h6' className='input-label-pad'>
										{t('charactersRemaining')}: {getRemainingCharacter(assessmentFormDetails?.formName || '', 50)}
									</Typography>
								</Box>
							) : (
								<></>
							)}
						</Box>
					</Box>
					{/* assessment title end */}

					{/* assessment section start */}
					{assessmentFormDetails &&
					assessmentFormDetails?.ratingFormSectionRequest &&
					assessmentFormDetails?.ratingFormSectionRequest?.length ? (
						<Fragment>
							<DragDropContext
								onDragEnd={(result: any) => onDragEndSection(result, assessmentFormDetails?.ratingFormSectionRequest)}
							>
								<Droppable droppableId='list'>
									{(provided: any) => (
										<div ref={provided.innerRef} className='' {...provided.droppableProps}>
											{assessmentFormDetails &&
											assessmentFormDetails?.ratingFormSectionRequest &&
											assessmentFormDetails?.ratingFormSectionRequest?.length ? (
												assessmentFormDetails?.ratingFormSectionRequest.map(
													(section: RatingFormSectionRequest, indexVal: number) => {
														return (
															<Draggable
																key={`draggable_${section.sequence}_index_${indexVal}_section_`}
																draggableId={`draggable_${section.sequence}_questionIndex_`}
																index={indexVal}
																isDragDisabled={false}
															>
																{(provided: any, snapshot: any) => (
																	<Fragment>
																		<Box
																			ref={provided.innerRef}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
																			className='assessment-section-list-row'
																			id={`form_section_item_index_${indexVal}`}
																		>
																			<Box
																				className={`assessment-form-collapse full-rectangular-card ${
																					section.isExpand ? 'expand-row' : ''
																				} ${section.isQuestionMandatoryError ? 'show-error' : ''}`}
																			>
																				<Tooltip arrow title={t('dragDropLabel')}>
																					<span className='drag-icon'>
																						<DragIndicator />
																					</span>
																				</Tooltip>
																				<Box
																					className={`assessment-section-head`}
																					onClick={(e: any) =>
																						handleSectionClick(e, indexVal, `form_section_item_index_${indexVal}`)
																					}
																				>
																					<Box className='assessment-section-inner'>
																						<Tooltip
																							arrow
																							title={section.isExpand ? t('collapseLabel') : t('expendLabel')}
																							placement={'top'}
																						>
																							<Box
																								className={`rotating-arrow`}
																								onClick={(e: any) =>
																									handleSectionClick(e, indexVal, `form_section_item_index_${indexVal}`)
																								}
																							>
																								<RightArrowIcon />
																							</Box>
																						</Tooltip>
																						<Box className='assessment-section-head-inner'>
																							<Box className='assessment-section-title'>
																								<Typography
																									variant='subtitle2'
																									className='section-count input-label-pad'
																								>
																									{`Section ${section.sequence} of ${assessmentFormDetails?.ratingFormSectionRequest?.length}`}
																								</Typography>
																								{!section?.isSectionEditable ? (
																									<Box className='section-title-wrap'>
																										<Box
																											className='title-read-mode'
																											onClick={(e: any) => handleEditSectionName(e, section, indexVal)}
																										>
																											<Typography>{section?.name || ''}</Typography>
																										</Box>
																										{/* <OKRButton icon={<DeleteIcon />} className={'editIcon'} title={t('delete')} /> */}
																									</Box>
																								) : (
																									<></>
																								)}

																								{section?.isSectionEditable ? (
																									<Box className='section-title-wrap'>
																										<Box className='title-edit-mode'>
																											<TextField
																												id='section-title'
																												className='error-top-pos'
																												maxRows={3}
																												multiline
																												placeholder={t(
																													'Section Name - E.g. Growth and development/Company Value'
																												)}
																												value={section?.name || ''}
																												name={t('growthDevelopment')}
																												error={section?.isSectionNameError || false}
																												helperText={
																													section?.isSectionNameError &&
																													section?.sectionNameErrorMessage ? (
																														<>
																															<ErrorIcon /> {section?.sectionNameErrorMessage || ''}
																														</>
																													) : null
																												}
																												onClick={(event: any) => {
																													event.preventDefault();
																													event.stopPropagation();
																												}}
																												onChange={(e: any) => handleFormSectionNameChange(e, section)}
																												inputProps={{ maxLength: 50 }}
																												InputLabelProps={{
																													shrink: true,
																												}}
																												InputProps={{
																													'aria-label': t('growthDevelopment'),
																													disableUnderline: true,
																												}}
																											/>
																											<Typography variant='h6' className='input-label-pad'>
																												{t('charactersRemaining')}:{' '}
																												{getRemainingCharacter(section?.name || '', 50)}
																											</Typography>
																										</Box>
																										{assessmentFormDetails?.ratingFormSectionRequest?.length > 1 ? (
																											<OKRButton
																												icon={<DeleteIcon />}
																												className={'editIcon'}
																												title={t('delete')}
																												handleClick={(e: any) =>
																													handleDeleteSection(e, section, indexVal)
																												}
																											/>
																										) : (
																											<></>
																										)}
																									</Box>
																								) : (
																									<></>
																								)}
																							</Box>
																							<Box className='assessment-section-des'>
																								{!section?.isDescriptionEditable ? (
																									<Box
																										className='title-read-mode'
																										onClick={(e: any) =>
																											handleEditSectionDescription(e, section, indexVal)
																										}
																									>
																										<Typography variant='h5'>{section?.description || ''}</Typography>
																									</Box>
																								) : (
																									<></>
																								)}
																								{section?.isDescriptionEditable ? (
																									<Box className='title-edit-mode'>
																										<TextField
																											id='section-des'
																											className='helperText-bottom'
																											maxRows={3}
																											multiline
																											placeholder={t(
																												`Description - E.g. Reflection on users' growth and development/company values since their last assessment`
																											)}
																											value={section?.description || ''}
																											// name={t('sectionDes')}
																											// error={section?.isSectionDescriptionError || false}
																											// helperText={
																											// 	section?.isSectionDescriptionError &&
																											// 	section?.sectionDescriptionErrorMessage ? (
																											// 		<>
																											// 			<ErrorIcon />{' '}
																											// 			{section?.sectionDescriptionErrorMessage || ''}
																											// 		</>
																											// 	) : null
																											// }
																											onChange={(e: any) =>
																												handleFormSectionDescriptionChange(e, section)
																											}
																											onClick={(event: any) => {
																												event.preventDefault();
																												event.stopPropagation();
																											}}
																											inputProps={{ maxLength: 150 }}
																											InputLabelProps={{
																												shrink: true,
																											}}
																											InputProps={{
																												'aria-label': t('sectionDes'),
																												disableUnderline: true,
																											}}
																										/>
																										<Typography variant='h6' className='input-label-pad'>
																											{t('charactersRemaining')}:{' '}
																											{getRemainingCharacter(section?.description || '', 150)}
																										</Typography>
																									</Box>
																								) : (
																									<></>
																								)}
																							</Box>
																						</Box>
																					</Box>
																				</Box>
																				<Collapse
																					className='assessment-section-content'
																					in={section.isExpand}
																					timeout='auto'
																				>
																					<Box className='assessment-section-content-inner'>
																						<QuestionListWrapper
																							onDragEnd={onDragEnd}
																							getItemStyle={getItemStyle}
																							section={section}
																							sectionIndex={indexVal}
																							questionTypeMasterData={questionTypeMasterData}
																							handleFormSectionQuestionNameChange={handleFormSectionQuestionNameChange}
																							handleFormSectionQuestionScaleTypeChange={
																								handleFormSectionQuestionScaleTypeChange
																							}
																							getScaleListByType={getScaleListByType}
																							handleScaleNameChange={handleScaleNameChange}
																							handleDeleteQuestion={handleDeleteQuestion}
																							handleIsQuestionMandatory={handleIsQuestionMandatory}
																							handleIsCommentBoxAvailable={handleIsCommentBoxAvailable}
																							handleIsCommentBoxMandatory={handleIsCommentBoxMandatory}
																							handleManageScale={handleManageScale}
																						/>
																						<Box className='section-actions'>
																							<OKRButton
																								className='btn-add-key'
																								icon={<AddIconSvg />}
																								text={t('addQuestion')}
																								handleClick={(e: any) =>
																									handleAddNewQuestionSection(e, section, indexVal)
																								}
																							/>
																							<OKRButton
																								className='btn-text'
																								text={t('addSectionLevel')}
																								id={'add-section-btn'}
																								handleClick={(e: any) => handleAddNewSection(e, section, indexVal)}
																							/>
																						</Box>
																					</Box>
																				</Collapse>
																			</Box>
																		</Box>
																	</Fragment>
																)}
															</Draggable>
														);
													}
												)
											) : (
												<></>
											)}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</Fragment>
					) : (
						<></>
					)}

					{/* assessment section start */}

					{/* assessment form action */}
					<Box className='add-assessment-action'>
						{/* <Box className='dot-wrap'>
							<OKRButton disabled={true} className={'btn-link btn-preview'} id={'btn-preview'} text={t('preview')} />
						</Box> */}
						{assessmentFormDetails?.isPublish ? (
							<></>
						) : (
							<OKRButton
								disabled={loader}
								className={'btn-link'}
								id={'btn-save-draft'}
								text={t('saveAsDraft')}
								handleClick={handleAssessmentFormSaveAsDraft}
							/>
						)}
						<OKRButton
							disabled={loader}
							className={'btn-primary'}
							id={'btn-publish'}
							text={t('submit')}
							handleClick={handleAssessmentFormPublish}
						/>
					</Box>
				</Box>
			</div>

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={modalProps?.type === 'QuestionError' ? true : false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={openDeleteDialog?.message || t('deleteRatingScale')}
					handleCloseModal={handleDialogDeleteClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</>
	);
};
