import React, { FC, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { Box, FormControlLabel, Switch, Typography, Tooltip, Avatar } from '@material-ui/core';
import { AddIconSvg, AutoRollUp, ManualUpdate, UserCirclePlaceholderIcon } from '../../../../config/svg/CommonSvg';
import { OKRButton } from '../../../Common/OKRButton';
import { NoUnitsIcon, TeamIcon } from '../../../../config/svg/MyGoalSvg';
import { UserIcon } from '../../../../config/svg/CommonSvgIcon';
import { SearchUserPopover } from '../../../Common/SearchUserPopover';
import { useTranslation } from 'react-i18next';
import LinearLoader from '../../../Common/Loader';
import { getUserName } from '../../../../config/utils';
import {
	CreateUpdateAdminSOSettings,
	getAdminSOSettings,
	getAllSoPermission,
} from '../../../../action/strategicObjective';
import { Enums } from '../../../../config/enums';
import { UserAvatarGroup } from '../../CommonComponent/UserAvatarGroup';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { SoAddUserDrawer } from './SoAddUserDrawer';
import AlertDialog from '../../../Common/Dialog';

export const StrategicObjectiveSettingDetails: FC<any> = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const staticData = {
		selectedUser: [],
		raisedForId: [],
	};

	const [initialSoSettings, setInitialSoSettings] = useState<any>(null);
	const [initialDefaultSoSettings, setInitialDefaultSoSettings] = useState<any>(null);
	const [loader, setLoader] = useState<boolean>(false);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [addCreatorAnchorEl, setAddCreatorAnchorEl] = useState<HTMLElement | null>(null);
	const [addCreatorOpen, setAddCreatorOpen] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [openUserDrawer, setOpenUserDrawer] = useState<any>({ open: false, type: '', selectedUserDetails: null });
	const [includedUseList, setIncludedUseList] = useState<any>(staticData);
	const [localArrList, setLocalArrList] = useState<any>({ selectedUser: [], raisedForId: [] });
	const [isSoUsersEdited, setIsSoUsersEdited] = useState<boolean>(false);
	const [isProgressUsersEdited, setIsProgressUsersEdited] = useState<boolean>(false);
	const [openProgressUserDrawer, setOpenProgressUserDrawer] = useState<any>({
		open: false,
		type: '',
		selectedUserDetails: null,
	});
	const [includedProgressUseList, setIncludedProgressUseList] = useState<any>(staticData);
	const [localArrProgressList, setLocalArrProgressArrList] = useState<any>({ selectedUser: [], raisedForId: [] });
	const [showDialog, setShowDialog] = useState<any>({
		open: false,
		message: '',
		isCancel: true,
		tagData: null,
		type: '',
	});

	useEffect(() => {
		getSoBaseSettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (initialSoSettings?.soVisibilityTypeId === Enums.THREE) {
			setIncludedProgressUseList(includedUseList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [includedUseList]);

	const getSoBaseSettings = async () => {
		try {
			setLoader(true);
			const response: any = await dispatch(getAdminSOSettings());

			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data || {};
				const {
					firstName,
					lastName,
					fullName,
					emailId,
					imagePath,
					soCreatorEmployeeId,
					soVisibilityTypeId,
					soVisibilityUsers,
					progressVisibilityTypeId,
					progressVisibilityUsers,
				} = entity || {};
				const selectedUserDetails = soVisibilityTypeId === Enums.THREE ? soVisibilityUsers : [];
				const selectedProgressVisibilityUsers =
					progressVisibilityTypeId === Enums.THREE
						? progressVisibilityUsers && progressVisibilityUsers.length
							? progressVisibilityUsers.map((item: any) => {
									if (!item?.designation) {
										item.designation = '';
									}
									return item;
							  })
							: []
						: [];
				setInitialSoSettings(entity || null);
				setInitialDefaultSoSettings(entity || null);
				setSelectedUser([
					{
						firstName: firstName || '',
						lastName: lastName || '',
						fullName: fullName || '',
						emailId: emailId || '',
						imagePath: imagePath || '',
						employeeId: soCreatorEmployeeId || 0,
					},
				]);
				setIncludedUseList({
					selectedUser: [...selectedUserDetails],
					raisedForId: [...selectedUserDetails.map((item: any) => item.employeeId)],
				});
				setIncludedProgressUseList({
					selectedUser: [...selectedProgressVisibilityUsers],
					raisedForId: [...selectedProgressVisibilityUsers.map((item: any) => item.employeeId)],
				});
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating perspective.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleAddCreatorClick = (event: any) => {
		setAddCreatorAnchorEl(addCreatorAnchorEl ? null : event.currentTarget);
		setAddCreatorOpen(true);
	};

	const onSelectedUser = async (selected: any) => {
		if (Boolean(selected) && selected.length) {
			const selectedUserDetails = selected[0];
			const contributors: any = selectedUser && selectedUser.length ? selectedUser : [];
			const isExist =
				contributors && contributors.length
					? contributors.find((item: any) => item.employeeId === selectedUser.employeeId)
					: null;
			if (!isExist) {
				setSelectedUser([{ ...selectedUserDetails }]);
			}
			setAddCreatorOpen(false);
			setAddCreatorAnchorEl(null);
			setCustomStyle(false);
		}
	};
	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};
	const updateInitialSetting = (keyName: string, value: any) => {
		let updatedInitialSoSettings = { ...initialSoSettings };
		updatedInitialSoSettings = { ...updatedInitialSoSettings, [keyName]: value };
		if (keyName === 'soVisibilityTypeId' && value === Enums.THREE) {
			updatedInitialSoSettings = { ...updatedInitialSoSettings, progressVisibilityTypeId: Enums.THREE };
		} else if (keyName === 'soVisibilityTypeId' && value === Enums.ONE) {
			updatedInitialSoSettings = { ...updatedInitialSoSettings, progressVisibilityTypeId: Enums.ONE };
		}
		setInitialSoSettings(updatedInitialSoSettings);
	};
	const handleChangeSoVisibility = (event: any, keyName: string, value: any) => {
		updateInitialSetting(keyName, value);
	};
	const handleChangeSoVisibilityForUser = (event: any, keyName: string, value: number) => {
		if (keyName) {
			updateInitialSetting(keyName, value);
		}
	};
	const handleChangeSoProgressForUser = (event: any, keyName: string, value: number) => {
		if (keyName) {
			updateInitialSetting(keyName, value);
		}
	};
	const handleChangeSoVisibilityProgressForUser = (event: any, keyName: string, value: number) => {
		if (keyName) {
			updateInitialSetting(keyName, value);
		}
	};
	const handleResetSoSettingsForUser = (event: any) => {
		const { firstName, lastName, fullName, emailId, imagePath, soCreatorEmployeeId } = initialDefaultSoSettings || {};
		setInitialSoSettings({ ...initialDefaultSoSettings });
		setSelectedUser([
			{
				firstName: firstName || '',
				lastName: lastName || '',
				fullName: fullName || '',
				emailId: emailId || '',
				imagePath: imagePath || '',
				employeeId: soCreatorEmployeeId || 0,
			},
		]);
	};
	const handleSaveSoSettingsForUser = async (event: any) => {
		try {
			setLoader(true);
			const soVisibilityUsersList =
				includedUseList && includedUseList?.raisedForId && includedUseList?.raisedForId.length
					? [...includedUseList?.raisedForId]
					: [];
			const soProgressVisibilityUsersList =
				includedProgressUseList && includedProgressUseList?.raisedForId && includedProgressUseList?.raisedForId.length
					? [...includedProgressUseList?.raisedForId]
					: [];
			const requestPayload = {
				isSofeature: initialSoSettings?.isSofeature || false,
				soCreatorEmployeeId:
					selectedUser && selectedUser.length ? selectedUser[0]?.employeeId : initialSoSettings?.soCreatorEmployeeId,
				progressVisibilityTypeId: initialSoSettings?.progressVisibilityTypeId || Enums.TWO,
				progressVisibilityUsers:
					initialSoSettings?.progressVisibilityTypeId === Enums.THREE ? soProgressVisibilityUsersList : [],
				notesVisibilityTypeId: initialSoSettings?.notesVisibilityTypeId || Enums.ONE,
				notesVisibilityUsers:
					initialSoSettings?.notesVisibilityUsers && initialSoSettings?.notesVisibilityUsers?.length
						? [...initialSoSettings?.notesVisibilityUsers.map((item: any) => item.employeeId)]
						: [],
				soVisibilityTypeId: initialSoSettings?.soVisibilityTypeId || Enums.TWO,
				soVisibilityUsers: initialSoSettings?.soVisibilityTypeId === Enums.THREE ? soVisibilityUsersList : [],
				calculationTechniqueTypeId: initialSoSettings?.calculationTechniqueTypeId,
			};
			const response: any = await dispatch(CreateUpdateAdminSOSettings(requestPayload));

			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Strategic Objectives permission updated successfully.`, {
					variant: 'success',
					autoHideDuration: 3000,
				});

				getSoBaseSettings();
				dispatch(getAllSoPermission());
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating strategic objective settings.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleAddProgressUserClick = () => {
		const selectedUserDetails =
			initialSoSettings?.progressVisibilityTypeId === Enums.THREE ? initialSoSettings?.progressVisibilityUsers : [];
		setOpenProgressUserDrawer({ open: true, type: 'progressVisibilitySpecificUsers', selectedUserDetails });
	};
	const handleAddUserClick = (event: any) => {
		const selectedUserDetails =
			initialSoSettings?.soVisibilityTypeId === Enums.THREE ? initialSoSettings?.soVisibilityUsers : [];
		setOpenUserDrawer({ open: true, type: 'SoVisibilitySpecificUsers', selectedUserDetails });
	};
	const handleProgressDrawerClose = (event: any, type: string) => {
		if (type === 'include') {
			let updatedData = {
				raisedForId: [...includedProgressUseList?.raisedForId, ...localArrProgressList?.raisedForId],
				selectedUser: [...includedProgressUseList?.selectedUser, ...localArrProgressList?.selectedUser],
			};
			const uniqueValue =
				updatedData?.raisedForId && updatedData?.raisedForId.length
					? Array.from(new Set([...updatedData.raisedForId]))
					: [];
			const finalData: any = [];
			uniqueValue &&
				uniqueValue.length &&
				uniqueValue.forEach((item: any) => {
					const data =
						updatedData.selectedUser && updatedData.selectedUser.length
							? updatedData.selectedUser.find((rec: any) => rec?.employeeId === item)
							: null;
					if (data) {
						finalData.push({ ...data });
					}
				});
			updatedData = {
				raisedForId: [...uniqueValue],
				selectedUser: [...finalData],
			};
			setIncludedProgressUseList(updatedData);
			setOpenProgressUserDrawer({ open: false, type: '', selectedUserDetails: null });
			setIsProgressUsersEdited(false);
		} else if (type === 'cancel') {
			if (isProgressUsersEdited && openProgressUserDrawer?.type !== 'viewProgressVisibilitySpecificUsers') {
				setShowDialog({
					open: true,
					type: 'ProgressVisibility',
					message: t('unSavedItemAlert'),
					isCancel: false,
					tagData: null,
				});
			} else {
				setIncludedProgressUseList(includedProgressUseList);
				setOpenProgressUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsProgressUsersEdited(false);
			}
		}
	};
	const handleDrawerClose = (event: any, type: string) => {
		if (type === 'include') {
			let updatedData = {
				raisedForId: [...includedUseList?.raisedForId, ...localArrList?.raisedForId],
				selectedUser: [...includedUseList?.selectedUser, ...localArrList?.selectedUser],
			};
			const uniqueValue =
				updatedData?.raisedForId && updatedData?.raisedForId.length
					? Array.from(new Set([...updatedData.raisedForId]))
					: [];
			const finalData: any = [];
			uniqueValue &&
				uniqueValue.length &&
				uniqueValue.forEach((item: any) => {
					const data =
						updatedData.selectedUser && updatedData.selectedUser.length
							? updatedData.selectedUser.find((rec: any) => rec?.employeeId === item)
							: null;
					if (data) {
						finalData.push({ ...data });
					}
				});
			updatedData = {
				raisedForId: [...uniqueValue],
				selectedUser: [...finalData],
			};
			setIncludedUseList(updatedData);
			setOpenUserDrawer({ open: false, type: '', selectedUserDetails: null });
			setIsSoUsersEdited(false);
		} else if (type === 'cancel') {
			if (isSoUsersEdited && openUserDrawer?.type !== 'viewSoVisibilitySpecificUsers') {
				setShowDialog({
					open: true,
					type: 'SoVisibility',
					message: t('unSavedItemAlert'),
					isCancel: false,
					tagData: null,
				});
			} else {
				setIncludedUseList(includedUseList);
				setOpenUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsSoUsersEdited(false);
			}
		}
	};
	const handleProgressUserAvatarClick = (event: any) => {
		const selectedUserDetails =
			initialSoSettings?.progressVisibilityTypeId === Enums.THREE ? initialSoSettings?.progressVisibilityUsers : [];
		setOpenProgressUserDrawer({ open: true, type: 'progressVisibilitySpecificUsers', selectedUserDetails });
	};
	const handleUserAvatarClick = (event: any) => {
		const selectedUserDetails =
			initialSoSettings?.soVisibilityTypeId === Enums.THREE ? initialSoSettings?.soVisibilityUsers : [];
		setOpenUserDrawer({ open: true, type: 'SoVisibilitySpecificUsers', selectedUserDetails });
	};
	const handleSOProgressCheckDisabled = () => {
		if (!initialSoSettings?.isSofeature) {
			return true;
		} else {
			return initialSoSettings?.calculationTechniqueTypeId === Enums.ZERO;
		}
	};
	const handleCloseModal = (e: any, type: number) => {
		if (type === 1) {
			if (showDialog && showDialog?.type === 'SoVisibility') {
				setIncludedUseList(includedUseList);
				setOpenUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsSoUsersEdited(false);
			} else if (showDialog && showDialog?.type === 'ProgressVisibility') {
				setIncludedProgressUseList(includedProgressUseList);
				setOpenProgressUserDrawer({ open: false, type: '', selectedUserDetails: null });
				setIsProgressUsersEdited(false);
			}
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
		} else {
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
			setIsSoUsersEdited(false);
			setIsProgressUsersEdited(false);
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='so-setting-card full-rectangular-card'>
				<Box className='so-setting-head'>
					<Box>
						<Typography variant='h4' className='font-weight-normal'>
							{t('strategicObjectiveLabel')}
						</Typography>
						<Typography variant='subtitle2' className='subtitle3'>
							{t('soSettingSubHead')}
						</Typography>
					</Box>
				</Box>
				<Box className='so-setting-switch'>
					<FormControlLabel
						control={
							<Switch
								name='isSofeature'
								checked={initialSoSettings?.isSofeature || false}
								onChange={(event) => handleChangeSoVisibility(event, 'isSofeature', event?.target?.checked)}
							/>
						}
						label={t('setStrategicObjectiveLabel')}
						labelPlacement='end'
						className='toggle-label'
					/>
				</Box>
				<Box className='so-setting-card-row'>
					<Box className='so-setting-card-col so-creator-col'>
						<Typography variant='h5' className='font-weight-normal'>
							{t('addCreatorSoLabel')}
						</Typography>
						<Box className='creator-info'>
							{selectedUser && selectedUser.length ? (
								<Box className='user-info'>
									<Box className='user-img'>
										<Tooltip arrow title={`${selectedUser[0]?.firstName} ${selectedUser[0]?.lastName}`}>
											{selectedUser[0]?.imagePath && selectedUser[0]?.imagePath.trim() !== '' ? (
												<Avatar className='avatar-default' src={selectedUser[0].imagePath} />
											) : selectedUser[0].firstName ? (
												<Avatar className='avatar-default'>{getUserName(selectedUser[0])}</Avatar>
											) : (
												<></>
											)}
										</Tooltip>
									</Box>
								</Box>
							) : (
								<UserCirclePlaceholderIcon />
							)}
							<OKRButton
								id='add-creator'
								className='add-btn2'
								icon={<AddIconSvg />}
								disabled={!initialSoSettings?.isSofeature}
								handleClick={handleAddCreatorClick}
							/>
							{addCreatorOpen && (
								<SearchUserPopover
									{...props}
									addContributorAnchorEl={addCreatorAnchorEl}
									addContributorOpen={addCreatorOpen}
									setAddContributorOpen={setAddCreatorOpen}
									setAddContributorAnchorEl={setAddCreatorAnchorEl}
									isCustomStyle={customStyle}
									onSelectedUser={onSelectedUser}
									handleSearchUserInputChange={handleSearchUserInputChange}
									popperAddClassName={'user-quick-search-popover check-ins-quick-search'}
									customId={'role-card-src-field'}
									isOwnerSearch={true}
								/>
							)}
						</Box>
						<Typography variant='subtitle1'>{t('addCreatorSoNotes')}</Typography>
					</Box>
					<Box className='so-setting-card-col'>
						<Typography variant='h5' className='font-weight-normal'>
							{t('visibiltySoSettingLabel')}
						</Typography>
						<Box className='setting-box-list'>
							<OKRButton
								className={`setting-box ${initialSoSettings?.soVisibilityTypeId === Enums.ONE ? 'active' : ''}`}
								icon={<NoUnitsIcon />}
								text={'None'}
								disabled={!initialSoSettings?.isSofeature}
								handleClick={(event) => handleChangeSoVisibilityForUser(event, 'soVisibilityTypeId', 1)}
							/>
							<OKRButton
								className={`setting-box ${initialSoSettings?.soVisibilityTypeId === Enums.TWO ? 'active' : ''}`}
								icon={<TeamIcon />}
								text={'All Users'}
								disabled={!initialSoSettings?.isSofeature}
								handleClick={(event) => handleChangeSoVisibilityForUser(event, 'soVisibilityTypeId', 2)}
							/>
							<OKRButton
								className={`setting-box ${initialSoSettings?.soVisibilityTypeId === Enums.THREE ? 'active' : ''}`}
								icon={<UserIcon />}
								text={'Specific Users'}
								disabled={!initialSoSettings?.isSofeature}
								handleClick={(event) => handleChangeSoVisibilityForUser(event, 'soVisibilityTypeId', 3)}
							/>
						</Box>
						{initialSoSettings?.soVisibilityTypeId === Enums.THREE ? (
							<UserAvatarGroup
								{...props}
								assignedUserDetails={
									includedUseList && includedUseList?.selectedUser && includedUseList?.selectedUser.length > 0
										? includedUseList?.selectedUser.map((item: any) => item)
										: []
								}
								isUserPlaceholder={true}
								subTitle={' '}
								helpText={' '}
								max={6}
								handleAddUserClick={handleAddUserClick}
								// handleAddUserClick={handleExcludeUserClick}
								isOnHoverVisible={false}
								isUserAvatarClick={true}
								handleUserAvatarClick={handleUserAvatarClick}
								isDisabled={!initialSoSettings?.isSofeature}
							/>
						) : (
							<></>
						)}
						{openUserDrawer && openUserDrawer?.open && (
							<MainDrawer
								open={openUserDrawer?.open || false}
								loader={loader}
								transitionDuration={{ enter: 500, exit: 1000 }}
								headerTitle={
									openUserDrawer?.type === 'viewSoVisibilitySpecificUsers' ? 'Selected Users' : t('addUserLabel')
								}
								children={
									<Box className='drawer-inner-content'>
										{openUserDrawer?.type === 'SoVisibilitySpecificUsers' ||
										openUserDrawer?.type === 'viewSoVisibilitySpecificUsers' ? (
											<SoAddUserDrawer
												settingType={'soVisibility'}
												type={openUserDrawer?.type || ''}
												selectedUserDetails={openUserDrawer?.selectedUserDetails}
												includedUseList={includedUseList}
												setIncludedUseList={setIncludedUseList}
												localArrList={localArrList}
												setLocalArrList={setLocalArrList}
												setLoader={setLoader}
												isEdited={isSoUsersEdited}
												setIsEdited={setIsSoUsersEdited}
												isSoVisibilitySpecificUserSelected={true}
											/>
										) : (
											<></>
										)}
									</Box>
								}
								saveButtonText={t('saveBtn')}
								rightCloseBtn={false}
								drawerClassName={'main-drawer-panel '}
								isSaveButtonVisible={openUserDrawer?.type !== 'viewSoVisibilitySpecificUsers' ? true : false}
								handleDrawerClose={(event: any) => handleDrawerClose(event, 'cancel')}
								handleSaveClick={(event: any) => handleDrawerClose(event, 'include')}
								handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
							/>
						)}
					</Box>
				</Box>
				<Box className='so-setting-card-separator'></Box>
				<Box className='progress-setting-area'>
					<Box className='so-setting-head'>
						<Typography variant='h4' className='font-weight-normal'>
							{t('progress')}
						</Typography>
					</Box>
					<Box className='so-setting-switch'>
						<FormControlLabel
							control={
								<Switch
									disabled={!initialSoSettings?.isSofeature}
									name='Set Strategic Objective'
									checked={initialSoSettings?.calculationTechniqueTypeId === Enums.ZERO ? false : true}
									onChange={(event) =>
										handleChangeSoProgressForUser(event, 'calculationTechniqueTypeId', event?.target?.checked ? 1 : 0)
									}
								/>
							}
							label={t('setProgressLabel')}
							labelPlacement='end'
							className='toggle-label'
						/>
					</Box>
					<Box className='so-setting-card-row'>
						<Box className='so-setting-card-col so-creator-col'>
							<Box className='setting-box-list'>
								<OKRButton
									className={`setting-box ${
										initialSoSettings?.calculationTechniqueTypeId === Enums.ONE ? 'active' : ''
									}`}
									icon={<AutoRollUp />}
									text={'Auto Roll-up'}
									disabled={handleSOProgressCheckDisabled()}
									handleClick={(event) => handleChangeSoProgressForUser(event, 'calculationTechniqueTypeId', 1)}
								/>
								<OKRButton
									className={`setting-box ${
										initialSoSettings?.calculationTechniqueTypeId === Enums.TWO ? 'active' : ''
									}`}
									icon={<ManualUpdate />}
									text={'Manual Update'}
									disabled={handleSOProgressCheckDisabled()}
									handleClick={(event) => handleChangeSoProgressForUser(event, 'calculationTechniqueTypeId', 2)}
								/>
							</Box>
						</Box>
						<Box className='so-setting-card-col'>
							<Typography variant='h5' className='font-weight-normal'>
								{t('visibiltyProgresLabel')}
							</Typography>
							<Box className='setting-box-list'>
								<OKRButton
									className={`setting-box ${initialSoSettings?.progressVisibilityTypeId === Enums.ONE ? 'active' : ''}`}
									icon={<NoUnitsIcon />}
									text={'None'}
									disabled={!initialSoSettings?.isSofeature}
									handleClick={(event) => handleChangeSoVisibilityProgressForUser(event, 'progressVisibilityTypeId', 1)}
								/>
								<OKRButton
									className={`setting-box  ${
										initialSoSettings?.progressVisibilityTypeId === Enums.TWO ? 'active' : ''
									}`}
									icon={<TeamIcon />}
									text={'All Users'}
									disabled={
										!initialSoSettings?.isSofeature ||
										initialSoSettings?.soVisibilityTypeId === Enums.THREE ||
										initialSoSettings?.soVisibilityTypeId === Enums.ONE
									}
									handleClick={(event) => handleChangeSoVisibilityProgressForUser(event, 'progressVisibilityTypeId', 2)}
								/>
								<OKRButton
									className={`setting-box  ${
										initialSoSettings?.progressVisibilityTypeId === Enums.THREE ? 'active' : ''
									}`}
									icon={<UserIcon />}
									text={'Specific Users'}
									disabled={!initialSoSettings?.isSofeature || initialSoSettings?.soVisibilityTypeId === Enums.ONE}
									handleClick={(event) => handleChangeSoVisibilityProgressForUser(event, 'progressVisibilityTypeId', 3)}
								/>
							</Box>

							{initialSoSettings?.progressVisibilityTypeId === Enums.THREE ? (
								<UserAvatarGroup
									{...props}
									assignedUserDetails={
										includedProgressUseList &&
										includedProgressUseList?.selectedUser &&
										includedProgressUseList?.selectedUser.length > 0
											? includedProgressUseList?.selectedUser.map((item: any) => item)
											: []
									}
									isUserPlaceholder={true}
									subTitle={' '}
									helpText={' '}
									max={6}
									handleAddUserClick={handleAddProgressUserClick}
									isOnHoverVisible={false}
									isUserAvatarClick={true}
									handleUserAvatarClick={handleProgressUserAvatarClick}
									isDisabled={!initialSoSettings?.isSofeature || initialSoSettings?.soVisibilityTypeId === Enums.THREE}
								/>
							) : (
								<></>
							)}
							{openProgressUserDrawer && openProgressUserDrawer?.open && (
								<MainDrawer
									open={openProgressUserDrawer?.open || false}
									loader={loader}
									transitionDuration={{ enter: 500, exit: 1000 }}
									headerTitle={
										openProgressUserDrawer?.type === 'viewProgressVisibilitySpecificUsers'
											? 'Selected Users'
											: t('addUserLabel')
									}
									children={
										<Box className='drawer-inner-content'>
											{openProgressUserDrawer?.type === 'progressVisibilitySpecificUsers' ||
											openProgressUserDrawer?.type === 'viewProgressVisibilitySpecificUsers' ? (
												<SoAddUserDrawer
													settingType={'progressVisibility'}
													type={openProgressUserDrawer?.type || ''}
													selectedUserDetails={openProgressUserDrawer?.selectedUserDetails}
													includedUseList={includedProgressUseList}
													setIncludedUseList={setIncludedProgressUseList}
													localArrList={localArrProgressList}
													setLocalArrList={setLocalArrProgressArrList}
													setLoader={setLoader}
													isEdited={isProgressUsersEdited}
													setIsEdited={setIsProgressUsersEdited}
													isSoVisibilitySpecificUserSelected={
														initialSoSettings?.soVisibilityTypeId === Enums.THREE ? false : true
													}
												/>
											) : (
												<></>
											)}
										</Box>
									}
									saveButtonText={t('saveBtn')}
									rightCloseBtn={false}
									drawerClassName={'main-drawer-panel '}
									isSaveButtonVisible={
										openProgressUserDrawer?.type !== 'viewProgressVisibilitySpecificUsers'
											? initialSoSettings?.soVisibilityTypeId === Enums.THREE
												? false
												: true
											: false
									}
									handleDrawerClose={(event: any) => handleProgressDrawerClose(event, 'cancel')}
									handleSaveClick={(event: any) => handleProgressDrawerClose(event, 'include')}
									handleCancelClick={(event: any) => handleProgressDrawerClose(event, 'cancel')}
								/>
							)}
						</Box>
					</Box>
				</Box>
				<Box className='so-setting-card-actions'>
					<Box className='so-setting-card-actions-left'></Box>
					<Box className='so-setting-card-actions-right'>
						<OKRButton className='btn-link' text={t('resetLabel')} handleClick={handleResetSoSettingsForUser} />
						<OKRButton className='btn-primary' text={t('saveBtn')} handleClick={handleSaveSoSettingsForUser} />
					</Box>
				</Box>
			</Box>
			{showDialog && showDialog?.open ? (
				<AlertDialog
					message={showDialog?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={showDialog?.open}
					isCancel={showDialog?.isCancel}
				/>
			) : (
				<></>
			)}
		</>
	);
};
