import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AdminTabPanel } from './AdminListingTabs';
import { SubHeader } from './SubHeader';
import { SystemRolesPermissions } from './RolesAndPermissions/SystemRolesPermissions';
import { checkPermission } from '../../config/rolePermission';
import { viewOrganizationManagementPage, viewRoleManagement, viewUserManagementPage } from './AdminConstant';
import { User } from './User';
import { OrgChartWrapper } from './d3OrgChart/OrgChartWrapper';
import { ManageOrganization } from './Organizations/ManageOrganization';
import { PerformanceReview } from './Settings/PerformanceReview';
import { AddPerformanceReview } from './Settings/PerformanceReview/AddPerformanceReview';
import { Settings } from './Settings';
import { updateCurrenTabSelection } from '../../action/adminSettings';
import { useDispatch, useSelector } from 'react-redux';
import { currentOrgTabs, currentRolePermissionsTabs, currentSettingTabs } from '../../action/common';
import { AddBulkFormDrawer } from './Settings/AlignGoalsSetting/AddBulkForm';
import { RolesAndPermissions } from './RolesAndPermissions';

interface SettingDropdownSelected {
	open: boolean;
	formType?: string;
}

export const Admin: React.FC<any> = (props: any) => {
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const dispatch = useDispatch();
	const [openRolePermissionDrawer, setOpenRolePermissionDrawer] = useState<boolean>(false);
	const [openCreateUserDrawer, setOpenCreateUserDrawer] = useState<boolean>(false);
	const [openAddTeamDrawer, setOpenAddTeamDrawer] = useState<boolean>(false);
	const [orgDropdownSelected, setOrgDropdownSelected] = useState<any>();
	const [settingDropdownSelected, setSettingDropdownSelected] = useState<SettingDropdownSelected>({
		open: false,
		formType: '',
	});
	const [openRecognizeDrawer, setOpenRecognizeDrawer] = useState<boolean>(false);
	const [openAssessmentForm, setOpenAssessmentForm] = useState<any>({
		open: false,
	});

	useEffect(() => {
		if (!Boolean(props?.okrMasterData)) {
			props.getOkrMasterData();
		}
	}, []);
	const [assessmentTabSelected, setAssessmentTabSelected] = useState<any>({ activeTab: 0 });
	const [assessmentSettingTabSelected, setAssessmentSettingTabSelected] = useState<any>({ activeTab: 0 });
	const [performanceReviewFormType, setPerformanceReviewFormType] = useState<string>('Add');
	const [assessmentFormType, setAssessmentFormType] = useState<any>({ type: 'Add', details: null });
	const [settingAssessmentFormSelected, setSettingAssessmentFormSelected] = useState<SettingDropdownSelected>({
		open: false,
		formType: '',
	});

	const [defaultOkrOpen, setDefaultOkrOpen] = useState<boolean>(false);
	const [goalFormOpen, setGoalFormOpen] = useState<any>({ open: false, data: {} });
	const [goalCycleOpen, setGoalCycleOpen] = useState<any>({ open: false, data: {} });
	const [openImportOkr, setOpenImportOkr] = useState<boolean>(false);
	const [goalBulkFormOpen, setGoalBulkFormOpen] = useState<any>({ open: false, data: {} });
	const [workflowReversalOpen, setWorkflowReversalOpen] = useState<any>({ open: false, data: {} });
	const [goalReviewOpen, setGoalReviewOpen] = useState<any>({ open: false, data: {} });

	const { currentOrgTab } = useSelector((state: any) => state.commonReducer);
	useEffect(() => {
		const tabValue = checkPermission(props.getPermissionResult?.employeePermissions, viewUserManagementPage)
			? 0
			: checkPermission(props.getPermissionResult?.employeePermissions, viewRoleManagement)
			? 1
			: checkPermission(props.getPermissionResult?.employeePermissions, viewOrganizationManagementPage)
			? 2
			: 3;

		dispatch(updateCurrenTabSelection(tabValue));
		setTabSelected(tabValue); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.getPermissionResult?.employeePermissions]);

	const handleTabChange = (event: any, newValue: Number) => {
		if (newValue === 1 || newValue === 2 || newValue === 3) {
			return false;
		} else {
			setTabSelected(newValue);
		}
		props.refetchUserDetails();
		//setOrgDropdownSelected(null);
		props.currentDashboardTab(newValue);
		handleAddPerformanceReviewBtnClick(event, false, performanceReviewFormType);
		handleAddAssessmentFormBtnClick(event, false, assessmentFormType?.type);
		dispatch(updateCurrenTabSelection(newValue));
		dispatch(currentOrgTabs(null));
		dispatch(currentSettingTabs(null));
		dispatch(currentRolePermissionsTabs(null));
		setDefaultOkrOpen(false);
		setGoalFormOpen({ open: false, data: {} });
		setGoalCycleOpen({ open: false, data: {} });
	};
	const handleTabMenuChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
		props.refetchUserDetails();
		props.currentDashboardTab(newValue);
		handleAddPerformanceReviewBtnClick(event, false, performanceReviewFormType);
		handleAddAssessmentFormBtnClick(event, false, assessmentFormType?.type);
		dispatch(updateCurrenTabSelection(newValue));
	};
	useEffect(() => {
		setTabSelected(props.dashboardCurrentTab);
	}, [props.dashboardCurrentTab]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabSelected]);

	useEffect(() => {
		if (props.currentRoute === '/admin') {
			document.body.classList.add('admin-body-class');
			document.body.classList.remove('user-body-class');
		}
	}, [props.currentRoute]);
	/**
	 *
	 * @param event HTMLEvent
	 * @param newValue number
	 */
	const handleMenuClick = (e: any, type: any) => {
		if (type === 'addNewUsers') {
			setOpenCreateUserDrawer(!openCreateUserDrawer);
		} else if (type === 'addNewTeam') {
			setOpenAddTeamDrawer(!openAddTeamDrawer);
		} else {
			setOpenRolePermissionDrawer((prevState) => !prevState);
		}
	};
	const handleAddPerformanceReviewBtnClick = (e: any, flag: boolean, formType: string) => {
		if (settingDropdownSelected?.open && settingDropdownSelected?.formType !== 'Add') {
			setSettingDropdownSelected({ open: flag, formType: 'Add' });
			setSettingAssessmentFormSelected({ open: false, formType: 'Add' });
		} else {
			setSettingDropdownSelected({ open: flag, formType: flag ? formType : '' });
			setSettingAssessmentFormSelected({ open: false, formType: 'Add' });
		}
	};
	const handleAddAssessmentFormBtnClick = (e: any, flag: boolean, formType: string) => {
		if (settingAssessmentFormSelected?.open && settingAssessmentFormSelected?.formType !== 'Add') {
			setSettingAssessmentFormSelected({ open: flag, formType: 'Add' });
			setSettingDropdownSelected({ open: false, formType: 'Add' });
		} else {
			setSettingAssessmentFormSelected({ open: flag, formType: flag ? formType : '' });
			setSettingDropdownSelected({ open: false, formType: 'Add' });
		}
	};
	const handleClickDefaultOkr = (event: any) => {
		setDefaultOkrOpen(true);
	};
	const handleImportDrawerOpen = () => {
		setOpenImportOkr(true);
	};
	const handleGoalFormOpen = (event: any) => {
		setGoalFormOpen({ open: true, data: {} });
	};
	const handleGoalCycleOpen = (event: any) => {
		setGoalCycleOpen({ open: true, data: {} });
	};
	const handleBulkGoalFormOpen = (event: any) => {
		setGoalBulkFormOpen({ open: true, data: {} });
	};
	const handleWorkflowReversalOpen = (event: any) => {
		setWorkflowReversalOpen({ open: true, data: {} });
	};
	const handleGoalReviewOpen = (event: any) => {
		setGoalReviewOpen({ open: true, data: {} });
	};

	return (
		<Fragment>
			<SubHeader
				handleTabChange={handleTabChange}
				handleMenuClick={handleMenuClick}
				tabSelected={tabSelected}
				openCreateUserDrawer={openCreateUserDrawer}
				setOrgDropdownSelected={setOrgDropdownSelected}
				orgDropdownSelected={orgDropdownSelected}
				handleAddPerformanceReviewBtnClick={handleAddPerformanceReviewBtnClick}
				setOpenAssessmentForm={setOpenAssessmentForm}
				openAssessmentForm={openAssessmentForm}
				setAssessmentTabSelected={setAssessmentTabSelected}
				setOpenRecognizeDrawer={setOpenRecognizeDrawer}
				openRecognizeDrawer={openRecognizeDrawer}
				handleTabMenuChange={handleTabMenuChange}
				handleAddAssessmentFormBtnClick={handleAddAssessmentFormBtnClick}
				handleClickDefaultOkr={handleClickDefaultOkr}
				setDefaultOkrOpen={setDefaultOkrOpen}
				defaultOkrOpen={defaultOkrOpen}
				handleImportDrawerOpen={handleImportDrawerOpen}
				goalFormOpen={goalFormOpen}
				setGoalFormOpen={setGoalFormOpen}
				handleGoalFormOpen={handleGoalFormOpen}
				goalCycleOpen={goalCycleOpen}
				setGoalCycleOpen={setGoalCycleOpen}
				handleGoalCycleOpen={handleGoalCycleOpen}
				handleBulkGoalFormOpen={handleBulkGoalFormOpen}
				handleWorkflowReversalOpen={handleWorkflowReversalOpen}
				setGoalReviewOpen={setGoalReviewOpen}
				handleGoalReviewOpen={handleGoalReviewOpen}
				{...props}
			/>
			<Box className='page-content-wrapper'>
				<AdminTabPanel value={tabSelected} index={0} className='admin-user-tab'>
					<User
						{...props}
						tabSelected={tabSelected}
						addUserClick={handleMenuClick}
						openCreateUserDrawer={openCreateUserDrawer}
						setOpenCreateUserDrawer={setOpenCreateUserDrawer}
						openAddTeamDrawer={openAddTeamDrawer}
						handleMenuClick={handleMenuClick}
					/>
				</AdminTabPanel>
				<AdminTabPanel value={tabSelected} index={1} className='admin-role-tab'>
					<RolesAndPermissions
						{...props}
						openRolePermissionDrawer={openRolePermissionDrawer}
						handleMenuClick={handleMenuClick}
						openAddTeamDrawer={openAddTeamDrawer}
					/>
				</AdminTabPanel>
				<AdminTabPanel value={tabSelected} index={2} className='admin-org-tab'>
					{/* <Organizations
						{...props}
						tabSelected={tabSelected}
						handleMenuClick={handleMenuClick}
						openAddTeamDrawer={openAddTeamDrawer}
					/> */}
					{currentOrgTab === 0 ? (
						<OrgChartWrapper
							{...props}
							tabSelected={tabSelected}
							isOrgChart={false}
							handleMenuClick={handleMenuClick}
							openAddTeamDrawer={openAddTeamDrawer}
						/>
					) : (
						<ManageOrganization
							{...props}
							tabSelected={tabSelected}
							handleMenuClick={handleMenuClick}
							openAddTeamDrawer={openAddTeamDrawer}
						/>
					)}
				</AdminTabPanel>
				<AdminTabPanel value={tabSelected} index={3} className='admin-role-tab'>
					<Settings
						{...props}
						settingDropdownSelected={settingDropdownSelected}
						handleAddPerformanceReviewBtnClick={handleAddPerformanceReviewBtnClick}
						setOpenAssessmentForm={setOpenAssessmentForm}
						openAssessmentForm={openAssessmentForm}
						setAssessmentTabSelected={setAssessmentTabSelected}
						assessmentTabSelected={assessmentTabSelected}
						setOpenRecognizeDrawer={setOpenRecognizeDrawer}
						openRecognizeDrawer={openRecognizeDrawer}
						performanceReviewFormType={performanceReviewFormType}
						setPerformanceReviewFormType={setPerformanceReviewFormType}
						setAssessmentSettingTabSelected={setAssessmentSettingTabSelected}
						assessmentSettingTabSelected={assessmentSettingTabSelected}
						settingAssessmentFormSelected={settingAssessmentFormSelected}
						handleAddAssessmentFormBtnClick={handleAddAssessmentFormBtnClick}
						assessmentFormType={assessmentFormType}
						setAssessmentFormType={setAssessmentFormType}
						setDefaultOkrOpen={setDefaultOkrOpen}
						defaultOkrOpen={defaultOkrOpen}
						handleClickDefaultOkr={handleClickDefaultOkr}
						openImportOkr={openImportOkr}
						setOpenImportOkr={setOpenImportOkr}
						goalFormOpen={goalFormOpen}
						setGoalFormOpen={setGoalFormOpen}
						handleGoalFormOpen={handleGoalFormOpen}
						goalCycleOpen={goalCycleOpen}
						setGoalCycleOpen={setGoalCycleOpen}
						handleGoalCycleOpen={handleGoalCycleOpen}
						workflowReversalOpen={workflowReversalOpen}
						setWorkflowReversalOpen={setWorkflowReversalOpen}
						handleWorkflowReversalOpen={handleWorkflowReversalOpen}
						goalReviewOpen={goalReviewOpen}
						setGoalReviewOpen={setGoalReviewOpen}
						handleGoalReviewOpen={handleGoalReviewOpen}
					/>
				</AdminTabPanel>

				{openRolePermissionDrawer && (tabSelected === 0 || tabSelected === 2) && (
					<SystemRolesPermissions
						{...props}
						openRolePermissionDrawer={openRolePermissionDrawer}
						handleMenuClick={handleMenuClick}
					/>
				)}

				{openCreateUserDrawer && (tabSelected === 1 || tabSelected === 2) && (
					<User
						{...props}
						tabSelected={tabSelected}
						addUserClick={handleMenuClick}
						openCreateUserDrawer={openCreateUserDrawer}
					/>
				)}

				{goalBulkFormOpen.open && (
					<AddBulkFormDrawer
						{...props}
						tabSelected={tabSelected}
						addUserClick={handleMenuClick}
						goalBulkFormOpen={goalBulkFormOpen}
						setGoalBulkFormOpen={setGoalBulkFormOpen}
					/>
				)}
				{/* {openAddTeamDrawer && (
					<Organizations
						{...props}
						tabSelected={tabSelected}
						handleMenuClick={handleMenuClick}
						openAddTeamDrawer={openAddTeamDrawer}
					/>
				)} */}
			</Box>
		</Fragment>
	);
};
