import React, { useEffect, useState } from 'react';
import { Avatar, Box, ClickAwayListener, FormLabel, List, ListItem, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
	AcceptIcon,
	BottomArrowIcon,
	FinalRatingIcon2,
	RecommendedIcon,
	SalaryIcon,
} from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import { RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import { Enums } from '../../config/enums';
import { NoUnitsIcon } from '../../config/svg/MyGoalSvg';
import { CommentIcon, ConversationIcon, DesignationIcon, ResponseLogIcon } from '../../config/svg/CommonSvg';
import CkEditor from '../Common/CkEditor/CkEditor';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getFinalRatingReview, getRatingDropdownData, saveFinalRatingReviews } from '../../action/adminSettings';
import { formatFeedbackDate, getFullName, getUserName } from '../../config/utils';
import { saveFinalRatingReview } from '../../services/adminSettingsService';
import { useSnackbar } from 'notistack';
import { ErrorIcon } from '../../config/svg/formElementIcons';

export const TeamReviewFinalRating: React.FC<any> = (props) => {
	const { drUserData } = props;
	console.log(drUserData);

	const { t } = useTranslation();
	const [openFinalRating, setOpenFinalRating] = useState<Boolean>(false);

	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 18);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);
	const [selectedKrDateRange, setSelectedKrDateRange] = useState<any>(null);
	const finalRatingDataContent = {
		performanceGoalRatingAssessmentId: drUserData.performanceGoalRatingAssessmentId,
		oneonOneDate: '',
		eligibleForSalaryIncrement: true,
		salaryIncrement: '',
		eligibleForPromotion: true,
		promotionDesignation: '',
		finalComment: '',
		changedFinalRatingId: 0,
		status: 2,
	};
	const finalRatingDataContentError = {
		oneonOneDate: false,
		eligibleForSalaryIncrement: false,
		salaryIncrement: false,
		eligibleForPromotion: false,
		promotionDesignation: false,
		finalComment: false,
		changedFinalRatingId: false,
	};
	const { enqueueSnackbar } = useSnackbar();

	const [finalRatingData, setFinalRatingData] = useState<any>({ ...finalRatingDataContent });
	const [finalRatingDataError, setFinalRatingDataError] = useState<any>({ ...finalRatingDataContentError });

	const [ratingDropdownData, setRatingDropdownData] = useState<any[]>([]);
	const handleRatingDropdown = () => {
		setOpenFinalRating((prev: Boolean) => !prev);
	};
	const handleRatingClickAway = () => {
		setOpenFinalRating(false);
	};

	const handleSave = async () => {
		let postData: any = { ...finalRatingData, oneonOneDate: formatFeedbackDate(finalRatingData.oneonOneDate) };
		const response: any = await dispatch(saveFinalRatingReviews(postData));
		console.log(postData, response);
		if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
			enqueueSnackbar(response?.data?.messageList.Ratingassessment, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			window.scroll({ top: 0, behavior: 'smooth' });
		} else {
			window.scroll({ top: 0, behavior: 'smooth' });
		}
	};

	const { chartData } = props;
	const columnWithGroupedChartData = {
		credits: {
			enabled: false,
		},
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: 0,
			plotShadow: false,
			margin: [0, 0, 0, 0], // top, right, bottom, left
			height: '90',
		},
		title: {
			text: '10%',
			align: 'center',
			verticalAlign: 'middle',
			y: 35,
			style: {
				fontWeight: 'bold',
				fontSize: '28px',
				fontFamily: 'Lato',
				color: '#292929',
			},
		},
		tooltip: {
			pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
		},
		accessibility: {
			point: {
				valueSuffix: '%',
			},
		},
		plotOptions: {
			pie: {
				// dataLabels: {
				// 	enabled: true,
				// 	distance: -50,
				// 	style: {
				// 		fontWeight: 'bold',
				// 		color: 'white',
				// 	},
				// },
				startAngle: -102,
				endAngle: 102,
				center: ['50%', '100%'],
				size: '230%',
			},
		},
		series: [
			{
				type: 'pie',
				name: 'Check-in Coverage',
				innerSize: '80%',
				enableMouseTracking: false,
				data: [
					{
						name: '',
						y: chartData?.checkInCompletionCount,
						color: '#0CBC74',
						dataLabels: {
							enabled: false,
						},
					},
					{
						name: 'Other',
						y: chartData?.totalWeekCount - chartData?.checkInCompletionCount,
						color: '#D5DAE1',
						dataLabels: {
							enabled: false,
						},
					},
				],
			},
		],
	};
	const formDisabled = false;
	const htmlContent = '<b>Approved</b> with <i>minor feedback</i>';

	useEffect(() => {
		loadRatingDropdownData('');
		loadFinalRatingData(1);
		console.log('drUserData', drUserData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const dispatch = useDispatch();
	const loadRatingDropdownData = async (requestPayload: any) => {
		try {
			const response: any = await dispatch(getRatingDropdownData(requestPayload));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setRatingDropdownData(response.data.entityList);
			} else {
			}
		} catch (error) {}
	};
	const loadFinalRatingData = async (PerformanceGoalRatingAssessmentId: any) => {
		try {
			const response: any = await dispatch(
				getFinalRatingReview(`PerformanceGoalRatingAssessmentId=${drUserData.performanceGoalRatingAssessmentId}`)
			);
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				//setRatingDropdownData(response.data.entityList);
			} else {
			}
		} catch (error) {}
	};
	return (
		<Box className='final-ratings-card full-rectangular-card'>
			<Box className='final-ratings-head'>
				<Box className='final-ratings-head-left'>
					<Typography variant='h4' className='font-weight-normal'>
						{t('finalRatings')}
					</Typography>
					<Typography variant='subtitle2' className='subtitle3'>
						{t('comprehensiveRatingText')}
					</Typography>
				</Box>
				<Box className='final-ratings-head-right'>
					<List disablePadding>
						<ListItem>
							<OKRButton icon={<ConversationIcon />} text={t('reviewConversation')} />
						</ListItem>
						<ListItem>
							<OKRButton icon={<ResponseLogIcon />} text={t('finalRatingLogLabel')} />
						</ListItem>
					</List>
				</Box>
			</Box>
			<Box className='user-final-review-info-box'>
				<Box className='user-info v-center'>
					<Box className='user-info v-center'>
						<Box className='user-img'>
							<Avatar className='avatar-default' src={drUserData?.imagePath || ''}>
								{getUserName({
									firstName: drUserData?.firstName || '',
									lastName: drUserData?.lastName || '',
									fullName: drUserData?.label || '',
								})}
							</Avatar>
						</Box>

						<Box className='user-info-details'>
							<Typography variant='h4'>{getFullName(drUserData)}</Typography>
							<Typography variant='subtitle2'>{drUserData.designation}</Typography>
						</Box>
					</Box>
				</Box>
				<Box className='user-final-review-info-row'>
					<Box className='user-final-review-info-col'>
						<Box className='pie-chart'>
							<HighchartsReact highcharts={Highcharts} options={columnWithGroupedChartData} />
						</Box>
					</Box>
					<Box className='user-final-review-info-col'>
						<Box className='select-rating-box'>
							<Box className='final-rating-label'>
								<FinalRatingIcon2 />
								<Typography>
									{t('ratingLabel')}
									<i style={{ color: 'red' }}>*</i>
								</Typography>
							</Box>
							<Box className='final-rating-select-box helperText-bottom'>
								<Box className={`ques-type-selection custom-type-dropdown-top`}>
									<OKRButton
										//disabled={formDisabled}
										className={`dropdown-button button-placeholder`}
										text={`${
											finalRatingData.changedFinalRatingId && finalRatingData.changedFinalRatingId !== 0
												? finalRatingData.ratingName
												: '-- Select --'
										}`}
										icon={<BottomArrowIcon />}
										handleClick={() => handleRatingDropdown()}
									/>
									<Typography variant='body2'>{t('calculatedScoreText')}</Typography>
									{openFinalRating && (
										<ClickAwayListener onClickAway={handleRatingClickAway}>
											<Box className='ques-type-dropdown'>
												<List component='div' disablePadding>
													{ratingDropdownData.map((item: any) => (
														<ListItem
															component='div'
															className={`${finalRatingData?.changedFinalRatingId === item.id ? 'active' : ''}`}
															onClick={(e) => {
																setFinalRatingData({
																	...finalRatingData,
																	changedFinalRatingId: item.id,
																	ratingName: item.name,
																});
																handleRatingClickAway();
															}}
														>
															<Typography>{item.name}</Typography>
														</ListItem>
													))}
												</List>
											</Box>
										</ClickAwayListener>
									)}
								</Box>
							</Box>
						</Box>
						<Box className='final-rating-eligible-box'>
							<Box className='final-rating-label'>
								<SalaryIcon />
								<Typography>
									{t('eligibleForSalaryIncrement')}
									<i style={{ color: 'red' }}>*</i>
								</Typography>
							</Box>
							<Box className='setting-box-list'>
								<OKRButton
									className={`setting-box ${finalRatingData.eligibleForSalaryIncrement === true ? 'active' : ''}`}
									icon={<AcceptIcon />}
									text={t('yesLabel')}
									//disabled={handleSOProgressCheckDisabled()}
									handleClick={(event) => {
										setFinalRatingData({ ...finalRatingData, eligibleForSalaryIncrement: true });
									}}
								/>
								<OKRButton
									className={`setting-box ${finalRatingData.eligibleForSalaryIncrement === false ? 'active' : ''}`}
									icon={<NoUnitsIcon />}
									text={t('noLabel')}
									//disabled={handleSOProgressCheckDisabled()}
									handleClick={(event) => {
										setFinalRatingData({ ...finalRatingData, eligibleForSalaryIncrement: false });
									}}
								/>
							</Box>
							<Box className='setting-box-field'>
								<Box className='final-rating-label'>
									<RecommendedIcon />
									<Typography>
										{t('incrementRecommend')}
										<i style={{ color: 'red' }}>*</i>
									</Typography>
								</Box>
								<TextField
									value={finalRatingData.salaryIncrement}
									name='Increment Recommend'
									placeholder={t('enterValue')}
									helperText={
										finalRatingDataError &&
										finalRatingDataError.salaryIncrement && (
											<React.Fragment>
												<ErrorIcon />
												{finalRatingDataError.salaryIncrement}
											</React.Fragment>
										)
									}
									onChange={(e) => {
										setFinalRatingData({ ...finalRatingData, salaryIncrement: e.target.value });
										setFinalRatingDataError({ ...finalRatingDataContentError, salaryIncrement: false });
									}}
									//error={errorMessage}
									// helperText={
									// 	errorMessage && (
									// 		<React.Fragment>
									// 			<ErrorIcon />
									// 			{errorMessage}
									// 		</React.Fragment>
									// 	)
									// }
									// onChange={(e) => {
									// 	setInputValue(e.target.value);
									// 	setErrorMessage('');
									// }}

									className='helperText-bottom'
								/>
							</Box>
						</Box>

						{/* <Box className={'final-rating-one-on-one-field helperText-bottom'}>
							<FormLabel id='one-on-one-duration' component='legend' className='form-label-required'>
								<RequestOneOnOneIcon2 />
								{t('Salary Increment')} <i style={{ color: 'red' }}>*</i>
							</FormLabel>
							<Box className='calendar-start-end-fields'>
								<TextField
									value={finalRatingData.salaryIncrement}
									name='deleteInput'
									helperText={
										finalRatingDataError &&
										finalRatingDataError.salaryIncrement && (
											<React.Fragment>
												<ErrorIcon />
												{finalRatingDataError.salaryIncrement}
											</React.Fragment>
										)
									}
									onChange={(e) => {
										setFinalRatingData({ ...finalRatingData, salaryIncrement: e.target.value });
										setFinalRatingDataError({ ...finalRatingDataContentError, salaryIncrement: false });
									}}
									className='helperText-bottom'
								/>
							</Box>
							<Typography variant='body2'></Typography>
						</Box> */}
					</Box>
					<Box className='user-final-review-info-col'>
						<Box className={'final-rating-one-on-one-field helperText-bottom'}>
							<FormLabel id='one-on-one-duration' component='legend' className='form-label-required'>
								<RequestOneOnOneIcon2 />
								{t('requestOnetoOne')} <i style={{ color: 'red' }}>*</i>
							</FormLabel>
							<Box className='calendar-start-end-fields'>
								{
									<ReactDateRangePicker
										type={'joiningDate'}
										startDateValue={null}
										minimumDate={minDateValue}
										maximumDate={maxDateValue}
										handleDateSelection={(range: any, start: any, end: any) => {
											setSelectedKrDateRange(range);
											setFinalRatingData({ ...finalRatingData, oneonOneDate: range });
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										placeholderText={t('selectDateLabel')}
										isDatePickerDisable={false}
										selectedDateRange={selectedKrDateRange}
										setSelectedDateRange={setSelectedKrDateRange}
									/>
								}
							</Box>
							<Typography variant='body2'>{t('reviewDiscussionText')}</Typography>
						</Box>
						<Box className='final-rating-eligible-box'>
							<Box className='final-rating-label'>
								<DesignationIcon />
								<Typography>
									{t('eligibleForPromotion')}
									<i style={{ color: 'red' }}>*</i>
								</Typography>
							</Box>
							<Box className='setting-box-list'>
								<OKRButton
									className={`setting-box ${finalRatingData.eligibleForPromotion === true ? 'active' : ''}`}
									icon={<AcceptIcon />}
									text={t('yesLabel')}
									//disabled={handleSOProgressCheckDisabled()}
									handleClick={(event) => {
										setFinalRatingData({ ...finalRatingData, eligibleForPromotion: true });
									}}
								/>
								<OKRButton
									className={`setting-box ${finalRatingData.eligibleForPromotion === false ? 'active' : ''}`}
									icon={<NoUnitsIcon />}
									text={t('noLabel')}
									//disabled={handleSOProgressCheckDisabled()}
									handleClick={(event) => {
										setFinalRatingData({ ...finalRatingData, eligibleForPromotion: false });
									}}
								/>
							</Box>
							<Box className='setting-box-field'>
								<Box className='final-rating-label'>
									<RecommendedIcon />
									<Typography>
										{t('recommendedPromotion')}
										<i style={{ color: 'red' }}>*</i>
									</Typography>
								</Box>
								<TextField
									value={finalRatingData.promotionDesignation}
									name='Recommended Promotion Position?'
									placeholder={t('enterDesignation')}
									helperText={
										finalRatingDataError &&
										finalRatingDataError.promotionDesignation && (
											<React.Fragment>
												<ErrorIcon />
												{finalRatingDataError.promotionDesignation}
											</React.Fragment>
										)
									}
									onChange={(e) => {
										setFinalRatingData({ ...finalRatingData, promotionDesignation: e.target.value });
										setFinalRatingDataError({ ...finalRatingDataContentError, promotionDesignation: false });
									}}
									className='helperText-bottom'
								/>
							</Box>
						</Box>
						{/* <Box className={'final-rating-one-on-one-field helperText-bottom'}>
							<FormLabel id='one-on-one-duration' component='legend' className='form-label-required'>
								<RequestOneOnOneIcon2 />
								{t('Promotion Designation')} <i style={{ color: 'red' }}>*</i>
							</FormLabel>
							<Box className='calendar-start-end-fields'>
								<TextField
									value={finalRatingData.promotionDesignation}
									name='deleteInput'
									helperText={
										finalRatingDataError &&
										finalRatingDataError.promotionDesignation && (
											<React.Fragment>
												<ErrorIcon />
												{finalRatingDataError.promotionDesignation}
											</React.Fragment>
										)
									}
									onChange={(e) => {
										setFinalRatingData({ ...finalRatingData, promotionDesignation: e.target.value });
										setFinalRatingDataError({ ...finalRatingDataContentError, promotionDesignation: false });
									}}
									className='helperText-bottom'
								/>
							</Box>
							<Typography variant='body2'></Typography>
						</Box> */}
					</Box>
					<Box className='user-final-review-info-col'></Box>
				</Box>
			</Box>
			<Box className='final-rating-comment-box'>
				<Box className='final-rating-label'>
					<CommentIcon />

					<Typography variant='h4' className='font-weight-normal'>
						{t('finalCommentsLabel')}
						<i style={{ color: 'red' }}>*</i>
					</Typography>
				</Box>
				<Box className={`final-rating-comment-field helperText-bottom editior-pane long-text preview-overlay`}>
					{formDisabled ? (
						<Box className='view-only-comment long-text'>
							<span dangerouslySetInnerHTML={{ __html: htmlContent || 'No comment' }}></span>
						</Box>
					) : (
						<>
							<CkEditor
								placeholder={t('Enter your final comments')}
								showEmoticons={true}
								value={finalRatingData.finalComment}
								handleEditorChange={(value: string) => {
									if (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>') {
									} else {
									}
									setFinalRatingData({ ...finalRatingData, finalComment: value });
									//setIsFormEdited(true);
								}}
							/>
						</>
					)}
				</Box>
			</Box>
			<Box className='performance-goals-action'>
				<OKRButton
					className={'btn-primary'}
					id='pip_create_submit_form'
					text={t('submit')}
					handleClick={() => {
						handleSave();
					}}
				/>
			</Box>
		</Box>
	);
};
