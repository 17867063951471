import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Switch, FormControlLabel, Avatar, Tooltip } from '@material-ui/core';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { checkUnSavePopUpVisible, getUserDetails, getUserName } from '../../../config/utils';
import { AlertInfoIcon } from '../../../config/svg/CommonSvg';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../config/enums';
import { saveMultipleAssessmentForm } from '../../../action/UserPerformance';
import AlertDialog from '../../Common/Dialog';
import { AssessmentPopup } from '../../Common/AssessmentPopup';
import { OKRButton } from '../../Common/OKRButton';
import { ShortAnswerRating } from '../../Common/PerformanceComponent/ShortAnswerRating';
import { SliderRating } from '../../Common/PerformanceComponent/SliderRating';
import { MultiChoiceRating } from '../../Common/PerformanceComponent/MultiChoiceRating';
import { StarRating } from '../../Common/PerformanceComponent/StarRating';
import { showLoader } from '../../../action/common';
import { TeamAssessmentHeader } from '../TeamAssessmentHeader';
import { getMultipleAssessment } from '../../../action/perform';
import { MultipleThreeSixtyAssessmentDetailsForm } from './MultipleThreeSixtyAssessmentDetailsForm';
import { TeamAssessmentDetailForm } from '../TeamAssessment/TeamAssessmentDetailForm';
import { FinalRatingAssessmentForm } from '../FinalRatingAssessment/FinalRatingAssessmentForm';

export const MultipleThreeSixtyAssessmentDetails = (props: any) => {
	const {
		performanceCycle,
		loader,
		setLoader,
		backClicked,
		employeeId,
		selectedUserAssessment,
		handleBack,
		userDetail,
		multipleAssessmentResponse,
		performanceCycleId,
		getTeamAssessList,
		performanceRoleId,
		performanceRatings,
		handleOneOnOneClick,
		handleSnapshot,
		getSelfAssessmentDetails,
		isViewMode = false,
		callingType = '',

		assessmentType = '',
		fromTeamAssessment,
		isFinalRating = false,
		getEmployeeAssessmentDetails = () => {},
		employeeAssessmentResponse,
		isBackHide = false,
		// dueDateDetails = null,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	const ref2 = useRef<any>(null);
	const refSelf = useRef<any>(null);
	const refManager = useRef<any>(null);
	const refThreeSixty = useRef<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [unSavedmodalProps, setUnSavedmodalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [showTeamAssessment, setShowTeamAssessment] = useState<any>(3);
	const { teamAssessmentResponse } = useSelector((state: any) => state.performReducer);
	const [selectedTile, setSelectedTile] = useState<number>(3);
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);

	const handleClickOutside = (event: any) => {
		if (
			ref.current &&
			!ref.current?.contains(event.target) &&
			(!ref1.current?.contains(event.target) ||
				refSelf.current?.contains(event.target) ||
				refThreeSixty.current?.contains(event.target) ||
				!ref2.current?.contains(event.target)) &&
			isFormEdited
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				event.preventDefault();
				event.stopPropagation();
				setUnSavedmodalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('unSavedItemAlert') });
			}
		} else {
			//
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (unSavedmodalProps?.event) {
				/*setAssessmentFormDetails(
					addQuestionNumber(multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections)
				);*/
				setIsFormEdited(false);
				const { target } = unSavedmodalProps.event;
				const clonedNativeEvent = document.createEvent('HTMLEvents');
				clonedNativeEvent.initEvent('click', true, false);
				target.dispatchEvent(clonedNativeEvent);
			}
		}
		setUnSavedmodalProps({ open: false, event: null, message: '' });
	};
	const getRatingData = (type: number) => {
		setSelectedTile(type);
		setShowTeamAssessment(type);
	};

	return (
		<>
			{!isBackHide ? (
				<Box className='page-sub-head no-pad-bottom multiple-assess-sub-head'>
					<Box className='sub-head-left'>
						<Box className='back-to-main'>
							<Typography variant='body2'>
								<Button id='back-btn' onClick={handleBack}>
									<BackArrow /> {t('back')}
								</Button>
							</Typography>
						</Box>
						{callingType === 'MyTeamAssessment' ? (
							<Box className='user-info'>
								<Box className='user-img'>
									<Avatar
										className='avatar-default'
										key={`user-icon-${selectedUserAssessment?.employeeId}`}
										src={selectedUserAssessment?.imagePath || ''}
									>
										{getUserName({
											firstName: selectedUserAssessment?.firstName || '',
											lastName: selectedUserAssessment?.lastName || '',
											fullName: selectedUserAssessment?.label || '',
										})}
									</Avatar>
								</Box>

								<Box className='user-info-details'>
									<Typography variant='h4'>
										{selectedUserAssessment?.firstName + ' ' + selectedUserAssessment?.lastName}
									</Typography>
								</Box>
							</Box>
						) : (
							<></>
						)}
						{callingType !== 'MyTeamAssessment' ? (
							<>
								<Typography variant='h6'>Your are taking 360 assessment</Typography>
								{/* {dueDateDetails && dueDateDetails?.daysRemaining && dueDateDetails?.dueDate ? (
									<Box className='days-remaining alert-with-due-text'>
										<Typography className='alert-text' variant='h6'>
											{dueDateDetails?.daysRemaining !== 1 ? (
												<>
													<AlertInfoIcon /> {`${dueDateDetails?.daysRemaining} days Remaining`}
												</>
											) : null}
										</Typography>
										<Typography className='alert-text' variant='h6'>
											{dueDateDetails?.daysRemaining === 1 ? (
												<>
													<AlertInfoIcon /> {'Last day to submit 360 assessment'}
												</>
											) : null}
										</Typography>
									</Box>
								) : (
									<></>
								)} */}
							</>
						) : (
							<></>
						)}
					</Box>
					{/* {callingType !== 'MyTeamAssessment' ? (
						<Box className='sub-head-right'>
							<FormControlLabel
								control={<Switch disabled checked={true} name='isActive' inputProps={{ 'aria-label': 'controlled' }} />}
								label={t('View all questions')}
								labelPlacement='end'
								className={`toggle-label`}
							/>
						</Box>
					) : (
						<></>
					)} */}
				</Box>
			) : (
				<></>
			)}
			{/* {console.log(callingType, assessmentType)} */}
			{callingType === 'MyTeamAssessment' ? (
				<TeamAssessmentHeader
					handleOneOnOneClick={handleOneOnOneClick}
					performanceCycle={performanceCycle}
					fromTeamAssessment={fromTeamAssessment || false}
					ref1={ref1}
					ref2={ref2}
					refSelf={refSelf}
					refManager={refManager}
					refThreeSixty={refThreeSixty}
					employeeId={employeeId || Enums.ZERO}
					userDetails={selectedUserAssessment}
					backClicked={backClicked}
					assessmentType={assessmentType}
					isFinalRating={isFinalRating}
					isThreeSixty={true}
					getRatingData={getRatingData}
					selectedUserAssessment={selectedUserAssessment}
				/>
			) : (
				<></>
			)}
			{showTeamAssessment === 3 ? (
				<MultipleThreeSixtyAssessmentDetailsForm
					{...props}
					callingType={callingType}
					performanceCycle={performanceCycle}
					loader={loader}
					setLoader={setLoader}
					backClicked={backClicked}
					employeeId={employeeId}
					selectedUserAssessment={selectedUserAssessment}
					handleBack={() => {
						backClicked(true);
						handleBack();
					}}
					userDetail={userDetail}
					multipleAssessmentResponse={multipleAssessmentResponse}
					performanceCycleId={performanceCycle?.performanceCycleId}
					getTeamAssessList={getTeamAssessList}
					performanceRoleId={performanceRoleId}
					performanceRatings={performanceRatings}
					handleOneOnOneClick={handleOneOnOneClick}
					handleSnapshot={handleSnapshot}
					getSelfAssessmentDetails={getSelfAssessmentDetails}
					isViewMode={isViewMode}
					assessmentType={isFinalRating ? 'FinalAssessment' : 'Manager'}
					fromTeamAssessment={true}
					isFinalRating={isFinalRating}
					getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
					isFormEdited={isFormEdited}
					setIsFormEdited={setIsFormEdited}
					refComp={ref}
				/>
			) : showTeamAssessment === 5 ? (
				<FinalRatingAssessmentForm
					{...props}
					refComp={ref}
					setIsFormEdited={setIsFormEdited}
					selectedUserAssessment={selectedUserAssessment}
					backClicked={backClicked}
				/>
			) : (
				<TeamAssessmentDetailForm
					{...props}
					assessmentType={'Manager'}
					performanceCycle={performanceCycle}
					employeeId={employeeId}
					fromTeamAssessment={true}
					backClicked={() => {}}
					isFinalRating={isFinalRating}
					handleOneOnOneClick={handleOneOnOneClick}
					selectedUserAssessment={selectedUserAssessment}
					managerRatingDateExceeded={
						performanceCycle?.isCycleCompleted ||
						!teamAssessmentResponse?.myTeamsAssessment?.isManagerAssessmentPeriodOpen
					}
					refComp={ref}
					isFormEdited={isFormEdited}
					setIsFormEdited={setIsFormEdited}
					selectedTile={selectedTile}
				/>
			)}
			{unSavedmodalProps.open && (
				<AlertDialog
					message={unSavedmodalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={unSavedmodalProps.open}
				/>
			)}
		</>
	);
};
