import {
	Avatar,
	Box,
	Button,
	ClickAwayListener,
	FormControlLabel,
	Link,
	List,
	ListItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { OKRButton } from '../../Common/OKRButton';
import { useDispatch, useSelector } from 'react-redux';
import { getFinalRatingMasterData, saveEmployeesFinalRating } from '../../../action/perform';
import { getUserName } from '../../../config/utils';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import { supportModuleAlignOkr } from '../../Admin/AdminConstant';

export const MultipleFinalAssessment = (props: any) => {
	const { handleBack, checkSupportPermission, performanceCycle, teamAssessmentResponse } = props;
	const { finalRatingMastreData, employeeFinalRatingData } = useSelector((state: any) => state.performReducer);
	const { t } = useTranslation();
	const [finalRatingList, setFinalRatingList] = React.useState<any>([]);
	const [isFormEdited, setIsFormEdited] = React.useState<any>(false);
	const dispatch = useDispatch();

	const handleSelectClick = (index: number) => {
		let updateData = [...finalRatingList];
		updateData[index].open = true;
		setFinalRatingList(updateData);
	};

	const handleClickAway = () => {
		let updateData = finalRatingList.map((item: any) => {
			return {
				...item,
				open: false,
			};
		});
		setFinalRatingList(updateData);
	};

	useEffect(() => {
		if (finalRatingMastreData && !finalRatingMastreData.length) {
			dispatch(getFinalRatingMasterData());
		}
	}, []);

	useEffect(() => {
		if (employeeFinalRatingData && employeeFinalRatingData.length) {
			setFinalRatingList(employeeFinalRatingData);
			dispatch(showLoader(false));
		}
	}, [employeeFinalRatingData]);

	const handleSelectFinalRating = (e: any, index: number, rating: any) => {
		e.preventDefault();
		let updateData = [...finalRatingList];
		updateData[index].finalRatingId = rating.finalRatingId;
		updateData[index].ratingName = rating.name;
		updateData[index].open = false;
		setFinalRatingList(updateData);
		setIsFormEdited(true);
	};

	const saveRating = async () => {
		dispatch(showLoader(true));
		let data = {
			finalRatingScoreRequest: finalRatingList.filter((item: any) => item.finalRatingId > 0),
		};
		setIsFormEdited(false);
		let response: any = await dispatch(saveEmployeesFinalRating(data));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			dispatch(showLoader(false));
			props.getTeamAssessList(props?.performanceRoleId);
			handleBack();
		}
	};

	return (
		<>
			<Box className='page-sub-head'>
				<Box className='back-to-main'>
					<Typography variant='body2'>
						<Link id='back-btn' onClick={handleBack}>
							<BackArrow /> {t('back')}
						</Link>
					</Typography>
				</Box>
				<Typography variant='h2'>{t('finalAssessment')}</Typography>
			</Box>
			{finalRatingList && finalRatingList.length > 0 && finalRatingMastreData && finalRatingMastreData.length > 0 && (
				<Box className='white-box'>
					<TableContainer className='team-assessment-listing assessment-listing-grey-bg'>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align='center' id={`team-assessment-cell-1`} className='user-col1 '>
										<Box className='cell-wrap'></Box>
									</TableCell>
									{finalRatingList.length &&
										finalRatingList.map((item: any, index: number) => (
											<TableCell key={index} align='center' id={`team-assessment-cell-3`} className='user-col3'>
												<Box className='cell-wrap'>
													<Box className='user-info'>
														<Box className='user-img'>
															{item.employeeDetails.imagePath ? (
																<>
																	<Avatar src={item.employeeDetails.imagePath} />
																</>
															) : (
																<Avatar>
																	{getUserName({
																		fullName: '',
																		firstName: item.employeeDetails.firstName,
																		lastName: item.employeeDetails.lastName,
																	})}
																</Avatar>
															)}
														</Box>
														<Box className='user-info-details'>
															<Typography>
																{`${item.employeeDetails.firstName} ${item.employeeDetails.lastName}`}
															</Typography>
														</Box>
													</Box>
												</Box>
											</TableCell>
										))}
								</TableRow>
							</TableHead>
							<TableBody>
								<Fragment>
									{teamAssessmentResponse?.myTeamsAssessment?.isSelfRatingPercentage && (
										<TableRow className='team-assessment-row'>
											<TableCell className='user-col1'>
												<Box className='cell-wrap'>
													<Typography>{t('selfAssessment')}</Typography>
													<Typography variant='h6' className='font-weight-normal'>
														{t('performsubText', { performsubText: finalRatingList[0].selfRatingPercentage })}
														{/* {finalRatingList[0].selfRatingPercentage}% to be considered for final rating */}
													</Typography>
												</Box>
											</TableCell>
											{finalRatingList.length &&
												finalRatingList.map((item: any, index: number) => (
													<TableCell key={index} className='user-col2'>
														<Box className='cell-wrap'>
															<Typography>{item.selfAssessmentScore}</Typography>
														</Box>
													</TableCell>
												))}
										</TableRow>
									)}
									<TableRow className='team-assessment-row'>
										<TableCell className='user-col1'>
											<Box className='cell-wrap'>
												<Typography>{t('managerAssessment')}</Typography>
												<Typography variant='h6' className='font-weight-normal'>
													{t('performsubText', { performsubText: finalRatingList[0].managerRatingPercentage })}
													{/* {finalRatingList[0].managerRatingPercentage}% to be considered for final rating */}
												</Typography>
											</Box>
										</TableCell>
										{finalRatingList.length &&
											finalRatingList.map((item: any, index: number) => (
												<TableCell key={index} className='user-col2'>
													<Box className='cell-wrap'>
														<Typography>{item.managerAssessmentScore}</Typography>
													</Box>
												</TableCell>
											))}
									</TableRow>
									{checkSupportPermission(supportModuleAlignOkr) &&
										teamAssessmentResponse?.myTeamsAssessment?.isOkrProgressPercentage && (
											<TableRow className='team-assessment-row'>
												<TableCell className='user-col1'>
													<Box className='cell-wrap'>
														<Typography>{t('committedOKRs')}</Typography>
														<Typography variant='h6' className='font-weight-normal'>
															{t('performsubText', { performsubText: finalRatingList[0].okrProgressPercentage })}
															{/* {finalRatingList[0].okrProgressPercentage}% to be considered for final rating */}
														</Typography>
													</Box>
												</TableCell>
												{finalRatingList.length &&
													finalRatingList.map((item: any, index: number) => (
														<TableCell key={index} className='user-col2'>
															<Box className='cell-wrap'>
																<Typography>{item.okrScore}</Typography>
															</Box>
														</TableCell>
													))}
											</TableRow>
										)}
									<TableRow className='team-assessment-row'>
										<TableCell className='user-col1'>
											<Box className='cell-wrap'>
												<Typography>{t('finalAssessment')}</Typography>
												<Typography variant='h6' className='font-weight-normal'>
													{t('overallLabel')}
												</Typography>
											</Box>
										</TableCell>

										{finalRatingList.length &&
											finalRatingList.map((item: any, index: number) => (
												<TableCell key={index} className='user-col2'>
													<Box className='cell-wrap'>
														<Typography>{item.finalScore}</Typography>
														<Box
															className={`ques-type-selection custom-type-dropdown-bottom ${item.open ? 'active' : ''}`}
														>
															<OKRButton
																className={
																	item.finalRatingId > 0 ? 'dropdown-button' : 'dropdown-button button-placeholder'
																}
																text={item.ratingName ? item.ratingName : '- Select -'}
																icon={<BottomArrowIcon />}
																handleClick={() => handleSelectClick(index)}
																disabled={performanceCycle.status === 3}
															></OKRButton>
															{item.open && (
																<ClickAwayListener onClickAway={handleClickAway}>
																	<Box className='ques-type-dropdown'>
																		<List component='div' disablePadding>
																			{finalRatingMastreData &&
																				finalRatingMastreData.length > 0 &&
																				finalRatingMastreData.map((rating: any) => (
																					<ListItem
																						component='div'
																						onClick={(e: any) => handleSelectFinalRating(e, index, rating)}
																						className={item.ratingName === rating.name ? 'active' : ''}
																					>
																						<Typography>{rating.name}</Typography>
																					</ListItem>
																				))}
																		</List>
																	</Box>
																</ClickAwayListener>
															)}
														</Box>
													</Box>
												</TableCell>
											))}
									</TableRow>
								</Fragment>
							</TableBody>
						</Table>
					</TableContainer>
					<Box className='assessment-table-footer'>
						<Box className='assessment-notes-txt'>
							<Typography variant='h6' className='font-weight-normal'>
								{t('finalAssessmentNote')}
							</Typography>
						</Box>
						<Box className='assessment-footer-action'>
							<OKRButton
								disabled={!isFormEdited}
								handleClick={saveRating}
								className='btn-primary'
								id={'btn-save'}
								text={'Submit'}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
