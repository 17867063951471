import * as actionTypes from './actionTypes';
import {
	getCycleDetailsApi,
	getPreviousCycleDetailsApi,
	getUsersByIdAPI,
	getGuidedTourDataApi,
	getGuidedTourActivityDataApi,
	isFederationUserApi,
	getVersionDetailsAPI,
	getUserVersionAPI,
	updateUserVersionAPI,
	getRecommendedCoursesApi,
	getDashboardWidgetDataApi,
	getLandingFeedbackRequest1On1CountApi,
	performanceAssessmentQuickStatusReportAPI,
	performanceAssessmentDetailedStatusReportAPI,
	userListReportAPI,
	updateUserForceLogoutAPI,
	oneOnOneReportAPI,
	recognitionReportAPI,
	checkInReportAPI,
	exportMyOkrReportAPI,
	getTenantDowntimeApi,
	getChatBotAPI,
	getChatHistoryApi,
	getEngagementAPI,
	getFeedbackDetailsAPI,
	getOkrProgressStatusAndPerformanceAPI,
	getDashboardAssessmentDataApi,
	getPerformanceGoalDashboardWidgetAPI,
} from '../services/CommonService';
import { setLocalStorageItem } from '../services/StorageService';

/**
 * renders loader on screen on update of props
 * @param {*} showLoaderStatus
 */
export const showLoader = (showLoaderStatus) => {
	return {
		type: actionTypes.SHOW_LOADER,
		payload: showLoaderStatus,
	};
};

/**
 * Update Route
 * @param {*} route
 */
export const updateRoute = (route) => {
	setLocalStorageItem('currentRoute', route);
	return {
		type: actionTypes.UPDATE_ROUTE,
		payload: route,
	};
};

/**
 * Update Route
 * @param {*} route
 */
export const updatePrevRoute = (route) => {
	return {
		type: actionTypes.UPDATE_PREV_ROUTE,
		payload: route,
	};
};

/**
 * Update Page
 * @param {*} route
 */
export const updatePage = (page) => {
	return {
		type: actionTypes.UPDATE_PAGE,
		payload: page,
	};
};

export const getCycleDetails = (id) => {
	return () => {
		return getCycleDetailsApi(id);
	};
};

export const getPreviousCycleDetails = (id) => {
	return () => {
		return getPreviousCycleDetailsApi(id);
	};
};

export const getUserById = (data) => {
	return () => {
		return getUsersByIdAPI(data);
	};
};

export const showHideSnackbar = (data) => {
	return { type: actionTypes.SHOW_HIDE_SNACKBAR, payload: data };
};

export const updateGoalType = (data) => {
	return {
		type: actionTypes.UPDATE_GOAT_TYPE,
		payload: data,
	};
};

/**current dashboard tab  */
export const currentDashboardTab = (currentTab) => {
	return {
		type: actionTypes.CURRENT_DASHBOARD_TAB,
		currentTab,
	};
};

/**current dashboard tab For SO  */
export const currentDashboardTabForSO = (currentTab) => {
	return {
		type: actionTypes.CURRENT_DASHBOARD_TAB_FOR_SO,
		currentTab,
	};
};
export const currentDashboardTabForSONotificationRedirection = (payload) => {
	return {
		type: actionTypes.CURRENT_DASHBOARD_TAB_FOR_SO_NOTI_REDIRECTION,
		payload,
	};
};

/**Guided Tour Data */

export const showGuidedTour = (guideTourVisible) => {
	return {
		type: actionTypes.SHOW_GUIDED_TOUR,
		guideTourVisible,
	};
};

export const userClosedGuidedTour = (isClosedByUser) => {
	return {
		type: actionTypes.USER_CLOSED_GUIDED_TOUR,
		isClosedByUser,
	};
};

const fetchGuidedTourDataPending = () => ({
	type: actionTypes.FETCH_GUIDED_DATA_PENDING,
});
const fetchGuidedTourDataCompleted = (payload) => ({
	type: actionTypes.FETCH_GUIDED_DATA_SUCCESS,
	payload,
});
const fetchGuidedTourDataFailed = (error) => ({
	type: actionTypes.FETCH_GUIDED_DATA_FAILED,
	error,
});

export const getGuidedTourData = () => {
	return (dispatch) => {
		dispatch(fetchGuidedTourDataPending());
		return getGuidedTourDataApi()
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchGuidedTourDataCompleted(response.data));
				} else {
					dispatch(fetchGuidedTourDataFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchGuidedTourDataFailed(error));
			});
	};
};

export const getGuidedTourActivity = (data) => {
	return () => {
		return getGuidedTourActivityDataApi(data);
	};
};

//change cycle based on notification

export const changeCycleId = (data) => {
	return {
		type: actionTypes.CHANGE_CYCLE,
		data,
	};
};

const isFederationUserPending = () => ({
	type: actionTypes.IS_FEDERATION_USER_PENDING,
});
const isFederationUserCompleted = (payload) => ({
	type: actionTypes.IS_FEDERATION_USER_SUCCESS,
	payload,
});
const isFederationUserFailed = (error) => ({
	type: actionTypes.IS_FEDERATION_USER_FAILED,
	error,
});
export const isFederationUser = (emailId) => {
	return (dispatch) => {
		dispatch(isFederationUserPending());
		return isFederationUserApi(emailId)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isFederationUserCompleted(response.data));
				} else {
					dispatch(isFederationUserFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(isFederationUserFailed(error));
			});
	};
};

export const signalRConnectionStatus = (payload) => ({
	type: actionTypes.SIGNAL_R_CONNECTION_STATUS,
	payload,
});

export const updateCurrentCycleId = (payload) => ({
	type: actionTypes.CURRENT_ORG_CYCLE_ID,
	payload,
});

export const currentCycleIdObject = (payload) => ({
	type: actionTypes.CYCLE_ID_DETAILS,
	payload,
});

export const getUserSelectedCycle = (payload) => ({
	type: actionTypes.USER_SELECTED_CYCLE,
	payload,
});

export const teamCycleDetails = (payload) => ({
	type: actionTypes.TEAM_CYCLE_DETAILS,
	payload,
});

export const showIdleStatePopup = (payload) => ({
	type: actionTypes.IDLE_STATE_SESSION_EXPIRE,
	payload,
});

export const isImpersonated = (payload) => ({
	type: actionTypes.IS_PERSONATED_USER,
	payload,
});

const isVersionPending = () => ({
	type: actionTypes.IS_VERSION_PENDING,
});
const isVersionCompleted = (payload) => ({
	type: actionTypes.IS_VERSION_SUCCESS,
	payload,
});
const isVersionFailed = (error) => ({
	type: actionTypes.IS_VERSION_FAILED,
	error,
});
export const getVersionDetails = () => {
	return (dispatch) => {
		dispatch(isVersionPending());
		return getVersionDetailsAPI()
			.then((response) => {
				if (response.status === 200) {
					dispatch(isVersionCompleted(response.data));
				} else {
					dispatch(isVersionFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(isVersionFailed(error));
			});
	};
};

export const cycleDetailsUpdated = (data) => ({
	type: actionTypes.IS_CYCLE_CHANGED,
	payload: data,
});

const isUserVersionPending = () => ({
	type: actionTypes.USER_VERSION_STATUS_PENDING,
});
const isUserVersionCompleted = (payload) => ({
	type: actionTypes.USER_VERSION_STATUS_SUCCESS,
	payload,
});
const isUserVersionFailed = (error) => ({
	type: actionTypes.USER_VERSION_STATUS_FAILED,
	error,
});

export const getUserVersion = () => {
	return (dispatch) => {
		dispatch(isUserVersionPending());
		return getUserVersionAPI()
			.then((response) => {
				if (response.status === 200) {
					dispatch(isUserVersionCompleted(response.data));
				} else {
					dispatch(isUserVersionFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(isUserVersionFailed(error));
			});
	};
};

const userVersionUpdatePending = () => ({
	type: actionTypes.UPDATE_USER_VERSION_STATUS_PENDING,
});
const userVersionUpdateCompleted = (payload) => ({
	type: actionTypes.UPDATE_USER_VERSION_STATUS_SUCCESS,
	payload,
});
const userVersionUpdateFailed = (error) => ({
	type: actionTypes.UPDATE_USER_VERSION_STATUS_FAILED,
	error,
});

export const updateGetUserVersion = (data, showTooltip) => {
	return (dispatch) => {
		dispatch(userVersionUpdatePending());
		return updateUserVersionAPI(data)
			.then((response) => {
				if (response.status === 200) {
					let customData = response.data;
					customData.showTooltip = showTooltip;
					customData.isRead = showTooltip;
					dispatch(userVersionUpdateCompleted(customData));
				} else {
					dispatch(userVersionUpdateFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(userVersionUpdateFailed(error));
			});
	};
};

export const updateUserVersion = () => {
	return {
		type: actionTypes.UPDATE_USER_VERSION,
	};
};

export const currentEngageTabs = (payload) => {
	return {
		type: actionTypes.CURRENT_ENGAGE_TAB,
		payload,
	};
};

export const getRecommendedCourses = (data) => {
	return () => {
		return getRecommendedCoursesApi(data);
	};
};

export const getDashboardWidgetsData = (data) => {
	return () => {
		return getDashboardWidgetDataApi(data);
	};
};
export const getDashboardAssessmentData = (data) => {
	return () => {
		return getDashboardAssessmentDataApi(data);
	};
};

export const getTenantDowntime = (data) => {
	return () => {
		return getTenantDowntimeApi(data);
	};
};

export const getLandingFeedbackRequest1On1Count = (data) => {
	return () => {
		return getLandingFeedbackRequest1On1CountApi(data);
	};
};
/**
 * API Call for Performance Assessment Reports
 */
export const performanceAssessmentQuickStatusReport = (data) => {
	return () => {
		return performanceAssessmentQuickStatusReportAPI(data);
	};
};
export const performanceAssessmentDetailedStatusReport = (data) => {
	return () => {
		return performanceAssessmentDetailedStatusReportAPI(data);
	};
};

export const userListReport = (data) => {
	return () => {
		return userListReportAPI(data);
	};
};

export const oneOnOneReport = (data) => {
	return () => {
		return oneOnOneReportAPI(data);
	};
};

export const recognitionReport = (data) => {
	return () => {
		return recognitionReportAPI(data);
	};
};

export const checkInReport = (data) => {
	return () => {
		return checkInReportAPI(data);
	};
};
export const exportMyOkrReport = (data) => {
	return () => {
		return exportMyOkrReportAPI(data);
	};
};
/**current dashboard tab  */
export const globalTaskDrawer = (globalTaskAutoFocus) => {
	return {
		type: actionTypes.GLOBAL_TASK_AUTO_FOCUS,
		globalTaskAutoFocus,
	};
};

/** User Force Logout */
export const updateUserForceLogout = (data) => {
	return () => {
		return updateUserForceLogoutAPI(data);
	};
};

export const setPerformanceGoalForRedirection = (payload) => {
	return {
		type: actionTypes.SET_PERFORMANCE_GOAL_FOR_REDIRECTION,
		payload,
	};
};
export const setNomineeDetailsForRedirection = (payload) => {
	return {
		type: actionTypes.SET_NOMINEE_DETAIL_FOR_REDIRECTION,
		payload,
	};
};
export const setEmployeeDetailsForRedirection = (payload) => {
	return {
		type: actionTypes.SET_EMPLOYEE_DETAIL_FOR_REDIRECTION,
		payload,
	};
};
export const currentOrgTabs = (payload) => {
	return {
		type: actionTypes.CURRENT_ORG_TAB,
		payload,
	};
};
export const currentSettingTabs = (payload) => {
	return {
		type: actionTypes.CURRENT_SETTING_TAB,
		payload,
	};
};
export const currentRolePermissionsTabs = (payload) => {
	return {
		type: actionTypes.CURRENT_ROLES_PERMISSIONS_TAB,
		payload,
	};
};

export const setOkrCreationInProgress = (payload) => ({
	type: actionTypes.OKR_CREATION_INPROGRESS,
	payload,
});

export const showOnCreatePopup = (payload) => ({
	type: actionTypes.OKR_CREATION_POPUP,
	payload,
});

export const setDefaultOkrName = (payload) => ({
	type: actionTypes.SET_DEFAULT_OKR_NAME,
	payload,
});

/**current dashboard tab  */
export const notificationOkrCycle = (cycleId) => {
	return {
		type: actionTypes.NOTIFICATION_OKR_CYCLE,
		cycleId,
	};
};
export const getChatBot = (data) => {
	return () => {
		return getChatBotAPI(data);
	};
};
export const getHistoryChatBot = (data) => {
	return () => {
		return getChatHistoryApi(data);
	};
};

/**
 * User Performance Snapshot Actions
 */

export const getOkrProgressStatusAndPerformance = (data) => {
	return () => {
		return getOkrProgressStatusAndPerformanceAPI(data);
	};
};

export const getFeedbackDetails = (data) => {
	return () => {
		return getFeedbackDetailsAPI(data);
	};
};

export const getEngagement = (data) => {
	return () => {
		return getEngagementAPI(data);
	};
};

export const hidePerformanceCycle = (payload) => ({
	type: actionTypes.HIDE_PERFORMANCE_CYCLE,
	payload,
});

export const getPerformanceGoalDashboardWidget = (data) => {
	return () => {
		return getPerformanceGoalDashboardWidgetAPI(data);
	};
};
