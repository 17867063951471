import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Logo } from '../../../config/svg/GlobalSvg';
import { MenuIcon } from '../../../config/svg/HeaderActionSvg';
import { LeftArrow } from '../../../config/svg/ArrowSvg';
import DrawerList from './DrawerList';
import AdminNavigation from './AdminNavigation';
import MyAccount from './MyAccount';
import LinearLoader from '../../Common/Loader';
import {
	AppBar,
	Toolbar,
	IconButton,
	Drawer,
	Box,
	Typography,
	Tooltip,
	Dialog,
	DialogContent,
	DialogContentText,
	Slide,
} from '@material-ui/core';
import {
	clearLocalStorage,
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from '../../../services/StorageService';
import { LOGIN_INITIATOR_URL, ADMIN } from '../../../config/app-url';
import { ClientLogo } from '../../Common/ClientLogo';
import { getCurrentUserDetails, getUserDetails } from '../../../config/utils';
import '../../../styles/pages/admin/main.scss';
import { OKRButton } from '../../Common/OKRButton';
import { CancelIcon } from '../../../config/svg/Action';
import { HayHandIcon } from '../../../config/svg/CommonSvg';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserVersion } from '../../../action/common';

export const Header: React.FC<any> = (props) => {
	const { loaderStatus, listOkrSuccess } = props;
	const { userVersion } = useSelector((state: any) => state?.commonReducer);
	const dispatch = useDispatch();
	const history = useHistory();
	const [profileOpen, setProfileOpen] = useState(false);
	const userDetail = getUserDetails();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const [alreadyShown, setAlreadyShown] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const currentUser: any = getCurrentUserDetails();
	const shownImpersonateToast = getLocalStorageItem('shownImpersonateToast');
	useEffect(() => {
		if (props.getPermissionStatus !== 'success') {
			//==== Get employee profile related permission
			props.getEmployeePermission(undefined, history);
		}
		if (props.listRolesSuccess !== 'success') {
			//==== Fetch roles listing
			props.getRoles();
		}
	}, [history.location]);

	useEffect(() => {
		if (userVersion && userVersion?.showTooltip) {
			setTimeout(() => {
				dispatch(updateUserVersion());
			}, 3000);
		}
	}, [userVersion]);

	if (!userDetail) {
		//==== if clearing storage then
		//==== save redirect Url before clearing storage

		let redirectUrl = getLocalStorageItem('redirectUrl');
		let otherCycleObjective = getLocalStorageItem('otherCycleObjective');
		let empId = getLocalStorageItem('empId');
		clearLocalStorage();
		if (redirectUrl) {
			setLocalStorageItem('redirectUrl', redirectUrl);
			setLocalStorageItem('otherCycleObjective', otherCycleObjective);
		}
		if (empId) {
			setLocalStorageItem('empId', empId);
		}
		window.location.href = LOGIN_INITIATOR_URL;
	}

	const handleToggle = (flag: any) => {
		if (flag === 1) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			setOpen(false);
		}
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const navigateToHomePage = () => {
		removeLocalStorageItem('currentUser');
		props.resetOkr();

		history.push(ADMIN);
		props.updateRoute(ADMIN);
	};

	const checkDrawer = () => {
		if (open) {
			setOpen(false);
		}
	};

	// useEffect(() => {
	// 	window.onfocus = function () {
	// 		const url = getLocalStorageItem('currentRoute');
	// 		if (url && url !== props.currentRoute) {
	// 			props.updateRoute(url);
	// 			window.location.href = url;
	// 		}
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props]);

	const handleDialogClose = () => {
		setAlreadyShown(true);
		setOpenDialog(false);
	};

	useEffect(() => {
		if (userDetail?.isImpersonatedUser && !alreadyShown && props.isImpersonateToastShowed === false) {
			setOpenDialog(true);
			setAlreadyShown(true);
			props.updateImpersonateToastStatus(true);
			setTimeout(() => {
				setOpenDialog(false);
			}, 3000);
		}
		return () => clearTimeout(undefined);
	}, [userDetail]);

	return (
		<Fragment>
			{userDetail && (
				<Fragment>
					<AppBar className='app-header' position='fixed' onClick={checkDrawer}>
						<Toolbar>
							<Box className='header-left'>
								{userVersion?.showTooltip || userVersion?.isRead ? (
									<Tooltip
										open={true}
										title={
											<Box className='tooltip-text'>
												<HayHandIcon />
												<Typography dangerouslySetInnerHTML={{ __html: t('whatsNewTooltip') }}></Typography>
											</Box>
										}
										placement='bottom-start'
										arrow
										classes={{ popper: 'custom-tooltip-icon' }}
									>
										<IconButton
											color='inherit'
											aria-label={t('HeaderMainAriaLabel')}
											onClick={() => handleToggle(1)}
											edge='start'
											className='drawer-open'
											id='side-nav-bar'
										>
											{open ? <LeftArrow /> : <MenuIcon />}
										</IconButton>
									</Tooltip>
								) : (
									<IconButton
										color='inherit'
										aria-label={t('HeaderMainAriaLabel')}
										onClick={() => handleToggle(1)}
										edge='start'
										className='drawer-open'
										id='side-nav-bar'
									>
										{open ? <LeftArrow /> : <MenuIcon />}
									</IconButton>
								)}
								<Box className='app-logo'>
									<Typography variant='h1'>
										{/* <Tooltip title={`${t('dashboardLabel')}`} arrow> */}
										<IconButton color='inherit' onClick={() => navigateToHomePage()} id='logo'>
											<Logo />
										</IconButton>
										{/* </Tooltip> */}
									</Typography>
								</Box>
							</Box>
							<nav className='main-nav' aria-label='Main'>
								<AdminNavigation {...props} userDetail={userDetail} />
							</nav>
							<Box className='header-right'>
								<ClientLogo className='client-logo' />
								<MyAccount
									{...props}
									profileOpen={profileOpen}
									setProfileOpen={setProfileOpen}
									loginAs={props.loginAs}
								/>
							</Box>
						</Toolbar>
						{loaderStatus && <LinearLoader data={listOkrSuccess === 'success' ? false : true} />}
					</AppBar>
					<Drawer
						className='drawer-nav'
						anchor='left'
						open={open}
						onEscapeKeyDown={handleDrawerClose}
						onBackdropClick={handleDrawerClose}
					>
						<DrawerList {...props} handleToggle={handleToggle} />
					</Drawer>
				</Fragment>
			)}

			{userDetail?.isImpersonatedUser && (
				<Dialog
					className='search-dialog'
					open={openDialog}
					onClose={handleDialogClose}
					aria-labelledby='responsive-dialog-title'
					TransitionComponent={Slide}
					transitionDuration={{ enter: 500, exit: 800 }}
					BackdropProps={{ invisible: true }}
				>
					<DialogContent>
						<Typography className='signalR'>
							{t('impersonatingUser', { name: `${userDetail?.firstName} ${userDetail?.lastName}` })}
						</Typography>
						<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleDialogClose} />
					</DialogContent>
				</Dialog>
			)}
		</Fragment>
	);
};
